import React, { useEffect, useState } from 'react'

export function useLocalStorage(key: string, inicial: string) {
  const [state, setState] = useState(() => {
    const local = localStorage.getItem(key)
    return local ? local : inicial
  })
  useEffect(() => {
    localStorage.setItem(key, state)
  }, [state, key])

  return [state, setState] as const
}