import { parseCookies, setCookie } from 'nookies';
import { useState } from 'react';

export function useLocalStorage(keyStorage: string, initValue: any) {
    const { [keyStorage]: token } = parseCookies();

    const [storedValue, setStoredValue] = useState(() => {
        if (typeof window === undefined) return initValue;

        try {
            return token ? JSON.parse(token) : initValue;
        } catch (error) {
            return initValue;
        }
    });

    const getValue = (key: string) => {
        try {
            const { [key]: token } = parseCookies();
            return token;
        } catch (error) {
            return initValue;
        }
    };

    const setValue = (key: string, value: any) => {
        try {
            setStoredValue(value);

            if (typeof window !== undefined) {
                setCookie(null, key, value, {
                    maxAge: 3600, // 1 DIA
                    path: '/',
                    sameSite: 'lax',
                    // HttpOnly: 'true',
                    secure: true,
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    return [storedValue, setValue, getValue];
}
