import { Flex, HStack } from '@chakra-ui/react';
import { ISidebarItemProps } from '../../../interfaces/Common';
import { WithoutSubmenu } from './SidebarItem/WithoutSubmenu';
import { WithSubmenu } from './SidebarItem/WithSubmenu';

export type SidebarProps = Record<'data', Array<ISidebarItemProps>>;

export const Sidebar: React.FC<SidebarProps> = ({ data }) => (
  <HStack
    as="nav"
    w="100%"
    h="calc(100% - 64px)"
    display="flex"
    direction="column"
    align="flex-start"
    pt={20}
  >
    <Flex w="100%" direction="column" gap={1} px={2}>
      {data.map(props =>
        props.items?.length ? (
          <WithSubmenu key={props.title} {...props} />
        ) : (
          <WithoutSubmenu key={props.title} {...props} />
        ),
      )}
    </Flex>
  </HStack>
);
