import { Box } from '@chakra-ui/react';
import { useTheme } from 'styled-components';

const BoxSection: React.FC<any> = props => {
    const theme = useTheme();

    return (
        <Box
            w={'80%'}
            m="auto"
            backgroundColor={theme.tables.background}
            padding={6}
            borderRadius={10}
            mt={8}
            {...props}
        />
    );
};

export default BoxSection;
