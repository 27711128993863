export const statementStatus = ['error', 'done'] as const;

export const ticketStatus = [
    'done',
    'pending',
    'in_progress',
    'refused',
] as const;

export type TicketStatusInterface = typeof ticketStatus[number];

export type StatementStatusInterface = typeof statementStatus[number];

export type Status = TicketStatusInterface & StatementStatusInterface;
