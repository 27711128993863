import * as Yup from 'yup';
import { COMPANY_ADMIN_DATA } from '../constants';
import { ICompany } from '../interfaces/Company';

export const editAdministratorValidationSchema = Yup.object({
    email: Yup.string().email().required(),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    isActive: Yup.boolean().default(false),
    isKleverAdmin: Yup.boolean().default(false),
    companyId: Yup.number()
        .required()
        .when('isKleverAdmin', {
            is: false,
            then: schema => schema.moreThan(1),
        }),
});

export const requiredSchemaWhenIsKleverAdminFalse = (
    isKleverAdmin: boolean,
) => {
    return Yup.string().when('isKleverAdmin', {
        is: false,
        then: schema =>
            isKleverAdmin ? schema.required() : schema.default(''),
    });
};

export const createAdministratorValidationSchema = (company: ICompany) =>
    Yup.object({
        email: Yup.string().email().required(),
        firstName: Yup.string().required(),
        lastName: Yup.string().required(),
        isKleverAdmin: Yup.boolean().default(false),
        companyId: Yup.string().concat(
            requiredSchemaWhenIsKleverAdminFalse(
                company.name === COMPANY_ADMIN_DATA.name,
            ),
        ),
    });
