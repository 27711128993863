import { DownloadSimple } from 'phosphor-react';
import { CSVLink } from 'react-csv';
import { LabelKeyObject } from 'react-csv/components/CommonPropTypes';
import { Tooltip } from '@chakra-ui/react';
import Button from '../../../components/Button';

interface ICSVDownloadProps {
  title: string;
  data: any;
  headers: LabelKeyObject[];
  filename: string;
}

export function CSVDownload({
  title,
  data,
  headers,
  filename,
}: ICSVDownloadProps) {

  return (
    <CSVLink data={data} headers={headers} filename={filename}>
      <Tooltip hasArrow label={title}>
        <div>
          <Button
            size={'sm'}
            leftIcon={<DownloadSimple size={22} />}
          >
            CSV
          </Button>
        </div>
      </Tooltip>
    </CSVLink>
  );
}
