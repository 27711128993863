import { useTheme } from 'styled-components';
import { Title } from './Title';
import { ISidebarItemProps } from '../../../../interfaces/Common';
import { verifyActiveMenu } from '../../../../utils/verifyActiveMenu';
import { Separator, SidebarLink } from './styles';

export function WithoutSubmenu({
  title,
  to,
  onClick,
  icon: Icon,
}: Omit<ISidebarItemProps, 'items'>) {
  const isActive = verifyActiveMenu(to);
  return (
    <SidebarLink to={to} isActive={isActive} onClick={onClick}>
      <Title icon={Icon} title={title} />
    </SidebarLink>
  );
}
