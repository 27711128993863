import { IBarsProps } from '../../../interfaces/Common';
import { StyledBars } from './styles';

export function OpenNavbarButton({ isOpen, onClick }: IBarsProps) {
  return (
    <StyledBars isOpen={isOpen} onClick={onClick}>
      <span></span>
      <span></span>
      <span></span>
    </StyledBars>
  );
}
