import { useMemo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { TOKEN_COOKIE_NAME } from '../../constants';
import { useLocalStorage } from '../../hooks/LocalStorage';

export const RequireAuth: React.FC = () => {
    const [, , getLocalStorage] = useLocalStorage('', '');

    const isLoggedIn = useMemo(() => {
        return !!getLocalStorage(TOKEN_COOKIE_NAME);
    }, [getLocalStorage]);

    if (!isLoggedIn) {
        return <Navigate to="login" />;
    } else {
        return <Outlet />;
    }
};
