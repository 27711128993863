import { useMemo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { TOKEN_COOKIE_NAME, USER_COOKIE_NAME } from '../../constants';
import { useLocalStorage } from '../../hooks/LocalStorage';
import { IUser } from '../../interfaces/User';

export const RequireAuthAdmin: React.FC = () => {
    const [, , getLocalStorage] = useLocalStorage('', '');

    const isLoggedIn = useMemo(() => {
        return !!getLocalStorage(TOKEN_COOKIE_NAME);
    }, [getLocalStorage]);

    const isAdmin = useMemo(() => {
        const session = getLocalStorage(USER_COOKIE_NAME) || '';

        if (session) {
            const user = JSON.parse(session) as IUser;

            return user.isKleverAdmin;
        }

        return false;
    }, []);

    if (!isLoggedIn || !isAdmin) {
        return <Navigate to="login" />;
    } else {
        return <Outlet />;
    }
};
