import { ISidebarItemProps } from '../../../../interfaces/Common';
import { Text } from './styles';
import { CaretDown } from 'phosphor-react';
import { Flex } from '@chakra-ui/react';

type TitleProps = Omit<ISidebarItemProps, 'items' | 'to'> & {
  hasSubmenu?: boolean;
  isOpen?: boolean;
};

export function Title({ ...props }: TitleProps) {
  const { title, icon: Icon = '', hasSubmenu = false, isOpen = false } = props;

  return (
    <Flex alignItems={'center'} gap={2} width={'100%'} color={'white'}>
      <Text isMenuTitle={hasSubmenu}>
        {typeof Icon === 'object' ? (
          <Icon size={20} weight="light" className="icon-sidebar-item" />
        ) : null}
        <span className="overflow">{title}</span>
      </Text>

      {hasSubmenu ? (
        <CaretDown
          size={16}
          weight="bold"
          style={{
            transform: `rotate(${isOpen ? 180 : 0}deg)`,
            marginLeft: 'auto',
            transition: 'transform 0.2s',
          }}
          id="caret"
        />
      ) : null}
    </Flex>
  );
}
