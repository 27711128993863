import * as Yup from 'yup';

export const createAndEditCompanyValidationSchema = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string().email().required(),
  address: Yup.string(),
  apiUrl: Yup.string().required(),
  companyToken: Yup.string().required(),
  kleverToken: Yup.string().required(),
});

export type CreateAndEditCompanySchema = Yup.InferType<
  typeof createAndEditCompanyValidationSchema
>;
