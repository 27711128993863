import React, { BaseSyntheticEvent, useCallback, useEffect, useState } from 'react';
import { FormControl, FormErrorMessage, FormLabel, HStack, Text, theme, Tooltip } from "@chakra-ui/react";
import { Info } from "phosphor-react";
import { useTranslation } from "react-i18next";
import Input from "../../../Input";
import { useTheme } from 'styled-components';

interface IInput {
  props:{
    labelName: string;
    nameInput: string;
    typeInput?: string;
    hasTooltip?: boolean;
    tooltipText?: string;
    default?: number;
    msnError?: string;
  },
  value?: number,
  setValue?: Function,
  isError?: boolean,
}

const InputForm: React.FC<any> = ({props, value, setValue, isError}: IInput) => {
  const { t } = useTranslation('createNetworkToken');
  const theme = useTheme();
  return (
    <FormControl isInvalid={isError}>
      <FormLabel color={theme.common.lightText}>
        <HStack>
          <Text>{props.labelName}</Text>
          {props.hasTooltip &&
            <Tooltip 
              label={t(`token.tooltip.${props.tooltipText}`)}
              placement='top-start'
            >
              {<Info size={20}  weight="fill"/>}  
            </Tooltip>
          }
        </HStack>
      </FormLabel>
      <Input
        min='0'
        name={props.nameInput} 
        type={props.typeInput}
        defaultValue={props.default}
        value={value}
        onChange={(e:any) => setValue?.(e.target.value)}
      />
      {isError && (
        <FormErrorMessage>{t(`errorMsn.${props.msnError}`)}</FormErrorMessage>
      )}
    </FormControl>
  )
}

export default InputForm;