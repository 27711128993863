import { Button, Th, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Check, Trash } from 'phosphor-react';
import { useTheme } from 'styled-components';

interface IViewAction {
  deleteAction: Function;
  log: any;
  gotoPage: Function;
}

export default function DeleteAction({
  deleteAction,
  log,
  gotoPage,
}: IViewAction) {
  const { t } = useTranslation('tables');
  const theme = useTheme();

  const Icon = log.isActive ? Trash : Check;

  return (
    <Th
      w={'100px'}
      textAlign={'start'}
      onClick={async () => {
        await deleteAction({
          ...log,
          isActive: !log.isActive,
        });
        gotoPage(1);
      }}
    >
      <Tooltip
        label={log.isActive ? t(`common.deactivate`) : t(`common.activate`)}
      >
        <Button
          color={theme.form.color}
          fontSize={14}
          variant={'ghost'}
          _hover={{ bg: 'none' }}
          p={'0'}
        >
          <Icon size={21} />
        </Button>
      </Tooltip>
    </Th>
  );
}
