const dark = {
  name: 'dark',
  background: 'rgb(22, 21, 26)',
  form: {
    background: 'rgb(34, 32, 40)',
    color: 'white',
    icon: {
      color: 'rgb(22, 21, 26)',
      secondaryColor: 'white',
    },
  },
  button: {
    background: '#AA33B5',
    color: '#FFFFFF',
  },
  outlinedButton: {
    background: 'rgb(34, 32, 40)',
    borderColor: 'white',
    color: 'white',
    hover: {
      background: 'white',
      color: 'rgb(34, 32, 40)',
    },
  },
  copyButton: {
    color: '#C4C4C4',
    hover: {
      color: '#323238',
    },
  },
  switch: {
    colorOn: '#AA33B5',
    colorOff: '#718096',
  },
  input: {
    background: 'white',
    color: 'rgb(22, 21, 26)',
    focus: {
      text: '#AA33B5',
      date: '#95a5a6',
    },
  },
  navbar: {
    background: 'rgb(16 15 18)',
    backgroundLinks: 'rgb(28 26 32)',
    links: {
      primaryColor: 'white',
      subTitle: '#AA33B5',
    },
  },
  tabs: {
    selected: '#AA33B5',
  },
  tables: {
    background: 'rgb(34, 32, 40)',
    color: 'white',
    button: {
      color: 'black',
    },
    select: {
      color: 'black',
      background: 'white',
    },
  },
  common: {
    lightText: 'white',
    colors: {
      light: 'white',
    },
  },
  drawer: {
    background: 'rgb(34, 32, 40)',
    color: 'white',
  },
  colors: {
    kleverPink: {
      light: '#AA33B5',
    },
  },
  boxShadow: {
    color1: 'rgba(16, 22, 26, 0.15)',
  },
  legend: { color: '#4A5568' },
  select: {
    focus: {
      color: '#AA33B5',
    },
  },
  status: {
    pendingColor: '#FFA500',
    inProgressColor: '#4299E1',
    refusedColor: '#E53E3E',
    errorColor: '#E53E3E',
    doneColor: '#38A169',
  },
  scrollbar: {
    background: 'rgb(22, 21, 26)',
    thumb: {
      background: 'rgb(8, 7, 9)',
      hover: {
        background: 'rgb(0, 0, 0)',
      },
    },
  },
  chart: {
    background: 'rgb(34, 32, 40)',
    backgroundAlpha: 'rgba(34, 32, 40, 0.4)',
    text: '#fff',
  },
};

export default dark;
