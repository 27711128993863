import { AccordionButton, AccordionIcon, Box, Heading } from '@chakra-ui/react';
import { useTheme } from 'styled-components';
import { IFilterTitleProps } from '../../../interfaces/Filter';

export function Title({ value }: IFilterTitleProps) {
    const theme = useTheme();

    return (
        <h2>
            <AccordionButton>
                <Box flex="1" textAlign="left">
                    <Heading
                        textAlign={'center'}
                        fontSize={15}
                        color={theme.common.lightText}
                    >
                        {value}
                    </Heading>
                </Box>
                <AccordionIcon color={theme.common.lightText} />
            </AccordionButton>
        </h2>
    );
}
