import { FormLabel, FormControl, Switch } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useTheme } from 'styled-components';
import { IFieldProps } from '../../../interfaces/Filter';
import Input from '../../Input';
import Select from '../../Select';

export function Field({ data }: IFieldProps) {
  const theme = useTheme();

  const renderComponent = useCallback(() => {
    switch (data.type) {
      case 'checkbox':
        return (
          <FormLabel color={theme.common.lightText} mb="3px">
            {data.title}
            <Switch
              name={data.key}
              type={data.type}
              marginLeft="10px"
              sx={{
                'span.chakra-switch__track': {
                  backgroundColor: theme.switch.colorOn,
                },
                'span.chakra-switch__track:not([data-checked])': {
                  backgroundColor: theme.switch.colorOff,
                },
              }}
            />
          </FormLabel>
        );

      case 'select':
        return (
          <Select
            color={theme.tables.select.color}
            backgroundColor={theme.tables.select.background}
            name={data.key}
            iconSize={'32px'}
          >
            { data.defaultValue ? 
             <option value={data.defaultValue.key} key={data.defaultValue.key}>{data.defaultValue.title}</option>
            :
             <option value={''}>Please select a value</option>
            }
            {data.selectValues?.map(st => (
              <option value={st.key} key={st.key}>
                {st.title}
              </option>
            ))}
          </Select>
        );
      default:
        return <Input name={data.key} type={data.type} />;
    }
  }, [data]);

  return (
    <FormControl
      sx={{
        '.chakra-select__wrapper .chakra-select__icon-wrapper': {
          top: '1rem',
        },
      }}
    >
      {data.type !== 'checkbox' && (
        <FormLabel color={theme.common.lightText} mb="3px">
          {data.title}
        </FormLabel>
      )}

      {renderComponent()}
    </FormControl>
  );
}
