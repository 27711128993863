import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';

import CreateTicketModal from './CreateTicketModal';
import { useTheme } from 'styled-components';

import { IPropsReloadTable } from '../../interfaces/Support';
import DepositInfoModal from './DepositInfoModal';

function selectTypeModal(props: IPropsReloadTable) {
  switch (props.typeModal) {
    case 'createTicket':
      return <CreateTicketModal handleReload={props.handleReload} />;
    case 'depositInfo':
      return (
        <DepositInfoModal
          data={props.data}
          loading={props.loading}
          onClose={props.onClose}
          handleReload={props.handleReload}
        />
      );
    default:
      return;
  }
}

export default function ModalComponent(props: IPropsReloadTable) {
  const { t } = useTranslation('manageTicket');
  const theme = useTheme();
  return (
    <>
      <Modal
        blockScrollOnMount={false}
        isOpen={props.isOpen}
        onClose={props.onClose}
        size="3xl"
      >
        <ModalOverlay />
        <ModalContent backgroundColor={theme.tables.background}>
          <ModalCloseButton color={theme.common.lightText} outline={'none'} />
          <ModalBody>{selectTypeModal(props)}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
