import { Heading, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import ContentContainer from '../ContentContainer';

const NoResults = () => {
    const { t } = useTranslation('tables');

    return (
        <ContentContainer>
            <Heading mb={4}>{t('noFields')}</Heading>
            <Text>{t('noFieldsDesc')}</Text>
        </ContentContainer>
    );
};

export { NoResults };
