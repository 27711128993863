import { Resource } from 'i18next';
import { languages } from './languageConfig';

const defaultNS = 'common';

const importResources = (lng: string = 'en') => {
  const resources: Resource = {};

  languages.map(({ value }) => {
    resources[value] = {};

    resources[value].common = require(`./${value}/common.json`);
    resources[value].login = require(`./${value}/login.json`);
    resources[value].navbar = require(`./${value}/navbar.json`);
    resources[value].companyAccount = require(`./${value}/companyAccount.json`);
    resources[value].adminAccount = require(`./${value}/adminAccount.json`);
    resources[value].tables = require(`./${value}/tables.json`);
    resources[value].adminCompanies = require(`./${value}/adminCompanies.json`);
    resources[value].drawer = require(`./${value}/drawer.json`);
    resources[value].application = require(`./${value}/application.json`);
    resources[value].logs = require(`./${value}/logs.json`);
    resources[value].autoComplete = require(`./${value}/autoComplete.json`);
    resources[value].statement = require(`./${value}/statement.json`);
    resources[value].manageTicket = require(`./${value}/manageTicket.json`);
    resources[value].support = require(`./${value}/support.json`);
    resources[value].filter = require(`./${value}/filter.json`);
    resources[value].billing = require(`./${value}/billing.json`);
    resources[value].moreInfoTicket = require(`./${value}/moreInfoTicket.json`);
    resources[value].charts = require(`./${value}/charts.json`);
    resources[
      value
    ].createNetworkToken = require(`./${value}/createNetworkToken`);
    resources[value].networks = require(`./${value}/networks.json`);
    resources[
      value
    ].blockchainBalance = require(`./${value}/blockchainBalance.json`);
    resources[
      value
    ].adminAdministrators = require(`./${value}/adminAdministrators.json`);
    resources[value].tokens = require(`./${value}/tokens.json`);
    resources[value].tokensNetworks = require(`./${value}/tokensNetworks.json`);
  });

  return resources;
};

export { importResources, defaultNS };
