import { useDisclosure, useToast } from '@chakra-ui/react';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Drawer, { IType } from '../../../components/Drawer';
import ModalComponent from '../../../components/Modal';
import PaginatedTable from '../../../components/PaginatedTable';
import { useChangeCompany } from '../../../hooks/ChangeCompany';
import { IDepositData } from '../../../interfaces/Deposit';
import { ITicketProps } from '../../../interfaces/Ticket';
import { editValidationSchema } from '../../../schemas/TicketSchema';
import api, { Service } from '../../../services/api';
import ErrorHandler from '../../../utils/errorHandler';
import { ticketFields } from '../fields';

const Ticket: React.FC = () => {
  const [requestLoad, setRequestLoad] = useState(true);
  const { userIsKlever } = useChangeCompany();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation('manageTicket');
  const { id } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  let navigate = useNavigate();
  const disclosure = useDisclosure();
  const toast = useToast();

  const [responseData, setResponseData] = useState<IDepositData>(
    {} as IDepositData,
  );
  const [drawerTitle, setDrawerTitle] = useState('');

  const [drawerType, setDrawerType] = useState({
    fields: [],
    action: () => {},
    validationSchema: undefined,
  } as IType);

  const [drawerData, setDrawerData] = useState({});

  if (!id) {
    navigate('/manage', { replace: true });
  }

  const updateData = async (e: any) => {
    const { status } = e;

    const response = await api.patch({
      route: `support-tickets/${id}`,
      service: Service.KRYPTO_BANKING,
      apiVersion: 'v1',
      body: { status },
    });

    if (response?.message) {
      return toast({
        title: t('common:toasts.titles.error'),
        description: ErrorHandler('adminAdministrators', response.message),
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }

    return toast({
      title: t('common:toasts.titles.success'),
      description: t('common:toasts.body.successSent'),
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
  };

  const handleReload = () => setRequestLoad(prev => !prev);

  const checkAction = async (ticket: ITicketProps) => {
    const { transactionId, chain } = ticket;
    onOpen();
    try {
      const response = await api.post({
        route: `deposit-events`,
        service: Service.KRYPTO_BANKING,
        apiVersion: 'v1',
        body: {
          txId: transactionId,
          chainId: chain,
        },
      });
      setLoading(false);
      if (response.length > 0) {
        setResponseData(response[0]);
      }
    } catch (err) {
      console.log('Error');
    }
  };

  const editAction = (ticket: ITicketProps) => {
    setDrawerData(ticket);
    setDrawerTitle(t('drawer.edit'));
    const selectedFields: any = ticketFields.edit;
    const action: any = updateData;
    const validationSchema: any = editValidationSchema;

    setDrawerType({
      fields: selectedFields,
      action: action,
      validationSchema,
    });

    return disclosure.onOpen();
  };

  const headers = [
    'companyId',
    'chain',
    'transactionId',
    'type',
    'status',
    'createdAt',
    'updatedAt',
  ];

  const props = {
    endpoint: `support-tickets/${id}`,
    ns: 'manageTicketTable',
    headers,
    requestLoad,
    ...(userIsKlever && { editAction }),
    ...(userIsKlever && { checkAction }),
  };

  return (
    <>
      <Drawer
        title={drawerTitle}
        useDisclosureProps={disclosure}
        type={drawerType}
        data={drawerData}
      />
      <ModalComponent
        isOpen={isOpen}
        onClose={onClose}
        typeModal="depositInfo"
        data={responseData}
        loading={loading}
        handleReload={handleReload}
      />
      <PaginatedTable {...props} />
      {setTimeout(() => setRequestLoad(false), 100)}
    </>
  );
};

export default Ticket;
