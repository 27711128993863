import React, { useEffect } from 'react';
import Container from '../../components/Container';
import Nav from '../../components/Navbar';

import StatsChart from '../../components/Chart/StatsChart';
import TotalValueInUSS from '../../components/Chart/TotalValueInUSS';
import TotalByTokenAndDate from '../../components/Chart/TotalByTokenAndDate';
import TotalQuantityByDateTransactions from '../../components/Chart/TotalQuantityByDateTransactions';

import { SubContainer, Content } from './styles';
import ThirtyDaysTransactions from '../../components/Chart/ThirtyDaysTransactions';
import Distribution from '../../components/Chart/Distribuition';

import { useChangeCompany } from '../../hooks/ChangeCompany';

const Dashboard: React.FC<any> = () => {
  const { userIsKlever } = useChangeCompany();

  useEffect(() => {
    if (userIsKlever) {
      window.location.href = '/account';
    }
  }, [userIsKlever]);

  return (
    <Container>
      <Nav />
      <SubContainer>
        <Content>
          <StatsChart />
          <Distribution />
          <TotalValueInUSS />
          <TotalByTokenAndDate />
          <TotalQuantityByDateTransactions />
          <ThirtyDaysTransactions />
        </Content>
      </SubContainer>
    </Container>
  );
};

export default Dashboard;
