import { Line } from 'react-chartjs-2';
import api, { Service } from '../../../services/api';
import React, { useEffect, useState } from 'react';
import { Stack, Text, Tooltip as ChakraTooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { TIMESTAMP_30DAYS } from '../../../constants/index'

import {
  ArrowUp,
  ArrowDown,
  ArrowsDownUp,
  Megaphone,
  Trophy,
  TrendUp,
  TrendDown,
  Info,
} from 'phosphor-react';

import {
  ColumnStats,
  ContainerSection,
  ContainerStatsColumns,
  SectionTitle,
} from '../styles';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ScriptableContext,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
);

function sumArrayTransactions(transactions: number[]) {
  return transactions.reduce((a, b) => a + b, 0);
}

const StatsChart: React.FC<any> = () => {
  const theme = useTheme();
  const { t } = useTranslation('charts');
  const [last30daysDeposits, setLast30daysDeposits] = useState<number[]>([]);
  const [last30daysWithdrawals, setLast30daysWithdrawals] = useState<number[]>(
    [],
  );
  const [last30daysInternalTransfers, setLast30daysInternalTransfers] =
    useState<number[]>([]);
  const [topCryptoCurrency, setTopCryptoCurrency] = useState('');
  const [openedTickets, setOpenedTickets] = useState(0);
  const [depositsUSD, setDepositsUSD] = useState(0);
  const [withdrawalsUSD, setWithdrawalsUSD] = useState(0);

  const labels = Array.from(Array(30).keys());

  interface SinceSameDayAYearAgo {
    deposits: number[];
    withdrawals: number[];
    internalTransfers: number[];
  }

  useEffect(() => {
    (async function () {
      const response = await api.get({
        route: 'reports/instance-info',
        service: Service.KRYPTO_BANKING,
        apiVersion: 'v1',
      });
      
      
      const { 
        deposits,
        depositsUSD, 
        withdrawals, 
        withdrawalsUSD, 
        internalTransfers, 
        openedTickets } =
        response.LastThirtyDays;

      setLast30daysDeposits(deposits);
      setLast30daysWithdrawals(withdrawals);
      setLast30daysInternalTransfers(internalTransfers);
      setTopCryptoCurrency(response.BestCoinLastYear);
      setOpenedTickets(openedTickets);
      setDepositsUSD(depositsUSD);
      setWithdrawalsUSD(withdrawalsUSD);
    })();
  }, []);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      line: {
        tension: 0.25,
        borderWidth: 0.7,
      },
      point: {
        radius: 0,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
  };

  const data1 = {
    labels,
    datasets: [
      {
        fill: true,
        data: last30daysDeposits,
        borderColor: 'rgb(123, 151, 120)',
        backgroundColor: (context: ScriptableContext<'line'>) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 50);
          gradient.addColorStop(0, 'rgba(193, 240, 187, 1)');
          gradient.addColorStop(1, theme.chart.backgroundAlpha);
          return gradient;
        },
      },
    ],
  };

  const data2 = {
    labels,
    datasets: [
      {
        fill: true,
        data: last30daysWithdrawals,
        borderColor: ' rgb(240, 140, 174)',
        backgroundColor: (context: ScriptableContext<'line'>) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 50);
          gradient.addColorStop(0, 'rgb(247, 180, 202)');
          gradient.addColorStop(1, theme.chart.backgroundAlpha);
          return gradient;
        },
      },
    ],
  };

  const data3 = {
    labels,
    datasets: [
      {
        fill: true,
        data: last30daysInternalTransfers,
        borderColor: 'rgb(140, 150, 240)',
        backgroundColor: (context: ScriptableContext<'line'>) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 50);
          gradient.addColorStop(0, 'rgb(180, 181, 247)');
          gradient.addColorStop(1, theme.chart.backgroundAlpha);
          return gradient;
        },
      },
    ],
  };

  const date30daysAgo = new Date().getTime() - TIMESTAMP_30DAYS;
  const formatDate = new Intl.DateTimeFormat('en-US').format(date30daysAgo);
  const currentDate = new Intl.DateTimeFormat('en-US').format(Date.now());

  function tooltipFunc(textTooltip: string) {
    return (
      <Stack spacing="1px">
        <Info size={22} weight="fill" />
        <Text>{t(`tooltip.chart2.${textTooltip}`)}</Text>
        <Text>{`${formatDate} - ${currentDate}`}</Text>
      </Stack>
    );
  }

  return (
    <ContainerSection gridName="stats">
      <SectionTitle>{t('titleChart.chart2')}</SectionTitle>
      <ContainerStatsColumns>
        <ColumnStats gridName="miniChartDeposit">
          <ChakraTooltip label={tooltipFunc('depositMiniChart')}>
            <div className="column__mini-chart">
              <Line options={options} data={data1} />
            </div>
          </ChakraTooltip>
        </ColumnStats>
        <ColumnStats gridName="infoDeposit">
          <ChakraTooltip label={t(`tooltip.chart2.numberDeposits`)}>
            <div className="column__content">
              <div className="column__circle deposits">
                <ArrowDown />
              </div>
              <div className="column__info">
                <span>{sumArrayTransactions(last30daysDeposits)}</span>
                <span>{t('action.Deposits')}</span>
              </div>
            </div>
          </ChakraTooltip>
        </ColumnStats>
        <ColumnStats gridName="miniChartWithdrawals">
          <ChakraTooltip label={tooltipFunc('withdrawMiniChart')}>
            <div className="column__mini-chart">
              <Line options={options} data={data2} />
            </div>
          </ChakraTooltip>
        </ColumnStats>
        <ColumnStats gridName="infoWithdrawals">
          <ChakraTooltip label={t(`tooltip.chart2.numberWithdrawals`)}>
            <div className="column__content">
              <div className="column__circle withdrawals">
                <ArrowUp />
              </div>
              <div className="column__info">
                <span>{sumArrayTransactions(last30daysWithdrawals)}</span>
                <span>{t('action.Withdrawals')}</span>
              </div>
            </div>
          </ChakraTooltip>
        </ColumnStats>
        <ColumnStats gridName="miniChartInterTransfer">
          <ChakraTooltip label={tooltipFunc('internalTransferMiniChart')}>
            <div className="column__mini-chart">
              <Line options={options} data={data3} />
            </div>
          </ChakraTooltip>
        </ColumnStats>
        <ColumnStats gridName="infoInterTransfer">
          <ChakraTooltip label={t(`tooltip.chart2.numberInternalTransfers`)}>
            <div className="column__content">
              <div className="column__circle internalTransfers">
                <ArrowsDownUp />
              </div>
              <div className="column__info">
                <span>{sumArrayTransactions(last30daysInternalTransfers)}</span>
                <span>{t('action.InternalTransfers')}</span>
              </div>
            </div>
          </ChakraTooltip>
        </ColumnStats>
        <ColumnStats gridName="depositUSS">
          <ChakraTooltip label={t(`tooltip.chart2.totalUSS`)}>
            <div className="column__content">
              <div className="column__circle others">
                <TrendUp />
              </div>
              <div className="column__info">
                <span>${depositsUSD.toFixed(0)}</span>
                <span>{`${t('action.Deposits')} US$`}</span>
              </div>
            </div>
          </ChakraTooltip>
        </ColumnStats>
        <ColumnStats gridName="withdrawalsUSS">
          <ChakraTooltip label={t(`tooltip.chart2.totalUSS`)}>
            <div className="column__content">
              <div className=" column__circle others">
                <TrendDown />
              </div>
              <div className="column__info">
                <span>${withdrawalsUSD.toFixed(0)}</span>
                <span>{`${t('action.Withdrawals')} US$`}</span>
              </div>
            </div>
          </ChakraTooltip>
        </ColumnStats>
        <ColumnStats gridName="openedTickets">
          <div className="column__content">
            <div className="column__circle others">
              <Megaphone />
            </div>
            <div className="column__info">
              <span>{openedTickets}</span>
              <span>{t('action.OpenedTickets')}</span>
            </div>
          </div>
        </ColumnStats>
        <ColumnStats gridName="topCrypto">
          <ChakraTooltip label={t(`tooltip.chart2.topCrypto`)}>
            <div className="column__content">
              <div className=" column__circle others">
                <Trophy />
              </div>
              <div className="column__info">
                <span>{topCryptoCurrency}</span>
                <span>{t('action.topCrypto')}</span>
              </div>
            </div>
          </ChakraTooltip>
        </ColumnStats>
      </ContainerStatsColumns>
    </ContainerSection>
  );
};

export default StatsChart;
