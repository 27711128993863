import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Button as CButton,
  Flex,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Spinner,
  Switch,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import { CheckCircle, PencilSimple, Trash, XCircle } from 'phosphor-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import BoxSection from '../../components/BoxSection';
import BoxStyled from '../../components/BoxStyled';
import Button from '../../components/Button';
import Container from '../../components/Container';
import Nav from '../../components/Navbar';
import api, { Service } from '../../services/api';

type Token = {
  id: number;
  name: string;
  abbr: string;
  precision?: number;
  isNFT: boolean;
  creator: string;
  createdAt: number;
  updatedAt: number;
};

type TokenData = {
  data: Array<Token>;
  page: number;
  totalPages: number;
  limit: number;
};

type Page = {
  current: number;
  total: number;
  limit: number;
};

const PAGE_INIT_STATE: Page = {
  current: 1,
  total: 1,
  limit: 10,
};

const Tokens: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreatingToken, setIsCreatingToken] = useState(false);
  const [tokens, setTokens] = useState([] as Array<Token>);
  const [page, setPage] = useState(PAGE_INIT_STATE);
  const [selectedTokenId, setSelectedTokenId] = useState(-1);
  const [enteredTokenName, setEnteredTokenName] = useState('');
  const [enteredTokenAbbr, setEnteredTokenAbbr] = useState('');
  const [enteredTokenCreator, setEnteredTokenCreator] = useState('');
  const [selectedTokenIsNFT, setSelectedTokenIsNFT] = useState(false);
  const [selectedTokenPrecision, setSelectedTokenPrecision] = useState(0);

  const theme = useTheme();

  const toast = useToast();

  const { t } = useTranslation('tokens');

  const getTokens = useCallback(async () => {
    try {
      setIsLoading(true);

      const { data, ...rest }: TokenData = await api.get({
        route: 'tokens',
        service: Service.KRYPTO_BANKING,
        apiVersion: 'v1',
        query: {
          page: page.current,
          limit: page.limit,
        },
      });

      if (!data?.length) return;

      setPage({
        current: rest.page,
        total: rest.totalPages,
        limit: rest.limit,
      });
      setTokens(data);
    } finally {
      setIsLoading(false);
    }
  }, [page.current, page.limit]);

  const createToken = useCallback(async () => {
    try {
      setIsLoading(true);

      const payload = {
        name: enteredTokenName,
        abbr: enteredTokenAbbr,
        creator: enteredTokenCreator,
        precision: !selectedTokenIsNFT ? selectedTokenPrecision : 0,
        isNft: selectedTokenIsNFT,
      };

      const { message }: Record<'message', 'string'> = await api.post({
        route: 'tokens',
        service: Service.KRYPTO_BANKING,
        apiVersion: 'v1',
        body: payload,
      });

      if (message?.length) throw 'Error creating token';

      toast({
        status: 'success',
        title: 'Token created',
        description: 'Token created successfully',
      });

      await getTokens();
      toggleModal();
    } catch (e) {
      toast({
        status: 'error',
        title: 'Error',
        description: e as string,
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    enteredTokenName,
    enteredTokenAbbr,
    enteredTokenCreator,
    selectedTokenPrecision,
    selectedTokenIsNFT,
    getTokens,
  ]);

  const editToken = useCallback(async () => {
    try {
      setIsLoading(true);

      const payload = {
        name: enteredTokenName,
        abbr: enteredTokenAbbr,
        creator: enteredTokenCreator,
        precision: !selectedTokenIsNFT ? selectedTokenPrecision : 0,
        isNft: selectedTokenIsNFT,
      };

      const { message }: Record<'message', 'string'> = await api.patch({
        route: `tokens/${selectedTokenId}`,
        service: Service.KRYPTO_BANKING,
        apiVersion: 'v1',
        body: payload,
      });

      if (message?.length) throw 'Error editing token';

      toast({
        status: 'success',
        title: 'Token edited',
        description: 'Token edited successfully',
      });

      await getTokens();
      toggleModal();
    } finally {
      setIsLoading(false);
    }
  }, [
    selectedTokenId,
    enteredTokenName,
    enteredTokenAbbr,
    enteredTokenCreator,
    selectedTokenPrecision,
    selectedTokenIsNFT,
    getTokens,
  ]);

  const deleteToken = useCallback(async (id: number) => {
    try {
      setIsLoading(true);

      const { message }: Record<'message', 'string'> = await api.delete({
        route: `tokens/${id}`,
        service: Service.KRYPTO_BANKING,
        apiVersion: 'v1',
      });

      if (message?.length) throw 'Error deleting token';

      toast({
        status: 'success',
        title: 'Token deleted',
        description: 'Token deleted successfully',
      });

      await getTokens();
    } catch (e) {
      toast({
        status: 'error',
        title: 'Error',
        description: e as string,
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const toggleModal = (filledWithTokenData = false, tokenId = -1) => {
    setIsModalOpen(prev => !prev);

    if (filledWithTokenData) {
      const token = tokens.find(token => token.id === tokenId);
      if (!token) return;

      setEnteredTokenName(token.name);
      setEnteredTokenAbbr(token.abbr);
      setEnteredTokenCreator(token.creator);
      setSelectedTokenPrecision(token?.precision ?? 0);
      setSelectedTokenIsNFT(token.isNFT);

      return;
    }

    setEnteredTokenName('');
    setEnteredTokenAbbr('');
    setEnteredTokenCreator('');
    setSelectedTokenPrecision(0);
    setSelectedTokenIsNFT(false);
  };

  const handleChangeEnteredTokenName = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => setEnteredTokenName(e.target.value);

  const handleChangeEnteredTokenAbbr = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => setEnteredTokenAbbr(e.target.value);

  const handleChangeEnteredTokenCreator = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => setEnteredTokenCreator(e.target.value);

  const handleChangeSelectedTokenIsNFT = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => setSelectedTokenIsNFT(e.target.checked);

  const handleChangeSelectedTokenPrecision = (value: string) =>
    setSelectedTokenPrecision(+value);

  const handleCreateToken = () => {
    setIsCreatingToken(true);
    toggleModal();
  };

  const handleEditToken = (id: number) => {
    setIsCreatingToken(false);
    setSelectedTokenId(id);
    toggleModal(true, id);
  };

  const handleDeleteToken = async (id: number) => await deleteToken(id);

  const handleChangeToPreviousPage = () => {
    if (page.current <= 1) return;

    setPage(prev => ({
      ...prev,
      current: prev.current - 1,
    }));
  };

  const handleChangeToNextPage = () => {
    if (page.current >= page.total) return;

    setPage(prev => ({
      ...prev,
      current: prev.current + 1,
    }));
  };

  const handleChangePageLimit = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { target } = e;

    setPage(prev => ({
      ...prev,
      limit: +target.value,
    }));
  };

  useEffect(() => void getTokens(), [getTokens]);

  return (
    <>
      <Modal
        isCentered
        closeOnOverlayClick={false}
        isOpen={isModalOpen}
        onClose={toggleModal}
      >
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>
            {isCreatingToken
              ? t('tokenModal.header.createTitle')
              : t('tokenModal.header.editTitle')}
          </ModalHeader>

          <ModalCloseButton color="red.500" />

          <ModalBody>
            <Flex direction="column" gap="1.25rem">
              <Flex direction="column" gap="0.25rem">
                <Text>{t('tokenModal.body.name.label')}</Text>

                <Input
                  placeholder={t('tokenModal.body.name.placeholder')}
                  value={enteredTokenName}
                  onChange={handleChangeEnteredTokenName}
                />
              </Flex>

              <Flex direction="column" gap="0.25rem">
                <Text>{t('tokenModal.body.symbol.label')}</Text>

                <Input
                  placeholder={t('tokenModal.body.name.placeholder')}
                  isInvalid={enteredTokenAbbr?.length > 6}
                  value={enteredTokenAbbr}
                  onChange={handleChangeEnteredTokenAbbr}
                />
              </Flex>

              <Flex direction="column" gap="0.25rem">
                <Text>{t('tokenModal.body.creator.label')}</Text>

                <Input
                  placeholder={t('tokenModal.body.creator.placeholder')}
                  value={enteredTokenCreator}
                  onChange={handleChangeEnteredTokenCreator}
                />
              </Flex>

              <Flex direction="column" gap="0.25rem">
                <Text>{t('tokenModal.body.precision.label')}</Text>

                <NumberInput
                  min={0}
                  defaultValue={selectedTokenPrecision}
                  value={selectedTokenPrecision}
                  onChange={handleChangeSelectedTokenPrecision}
                  disabled={selectedTokenIsNFT}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </Flex>

              <Flex align="center" gap="1rem" mt="0.25rem">
                <Text>NFT</Text>

                <Switch
                  colorScheme="purple"
                  defaultChecked={selectedTokenIsNFT}
                  onChange={handleChangeSelectedTokenIsNFT}
                />
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex w="100%" justify="space-between" mt="1.25rem">
              <CButton
                variant="ghost"
                disabled={isLoading}
                onClick={() => toggleModal()}
              >
                {t('tokenModal.footer.cancelBtn')}
              </CButton>

              <Button
                disabled={
                  isLoading ||
                  !enteredTokenName?.length ||
                  !enteredTokenCreator?.length ||
                  enteredTokenAbbr?.length > 6
                }
                leftIcon={isLoading && <Spinner w={18} h={18} />}
                onClick={() => (isCreatingToken ? createToken() : editToken())}
              >
                {t('tokenModal.footer.confirmBtn')}
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Container>
        <Nav />

        <BoxStyled>
          <BoxSection>
            <Flex justify="center" align="center" position="relative">
              <Flex position="absolute">
                <Heading color={theme.common.lightText}>
                  {t('header.title')}
                </Heading>
              </Flex>

              <Flex justify="flex-end" w="100%">
                <Button onClick={handleCreateToken}>
                  <PencilSimple size={20} />
                  <Text ml="0.5rem">{t('header.createTokenBtn')}</Text>
                </Button>
              </Flex>
            </Flex>
          </BoxSection>

          <BoxSection>
            <Table variant="simple">
              <Thead>
                {!isLoading && (
                  <Tr>
                    <Th>{t('table.header.name')}</Th>

                    <Th>{t('table.header.symbol')}</Th>

                    <Th>{t('table.header.precision')}</Th>

                    <Th>{t('table.header.creator')}</Th>

                    <Th>NFT</Th>

                    <Th />
                  </Tr>
                )}
              </Thead>

              <Tbody>
                {isLoading && (
                  <Tr>
                    <Td colSpan={6}>
                      <Flex justify="center">
                        <Spinner />
                      </Flex>
                    </Td>
                  </Tr>
                )}

                {!isLoading && !tokens?.length && (
                  <Tr>
                    <Td colSpan={4}>
                      <Flex justify="center">
                        <Text>{t('table.body.noTokens')}</Text>
                      </Flex>
                    </Td>
                  </Tr>
                )}

                {!isLoading &&
                  tokens?.length > 0 &&
                  tokens.map(token => (
                    <Tr key={token.id}>
                      <Td>{token.name}</Td>

                      <Td>{token.abbr}</Td>

                      <Td>
                        <Text>
                          <b>{token.precision ?? '0'}</b> decimal(s)
                        </Text>
                      </Td>

                      <Td>{token.creator}</Td>

                      <Td>
                        <Tooltip
                          label={`${token.name} is ${
                            token.isNFT ? 'a NFT' : 'not a NFT'
                          }`}
                        >
                          {token.isNFT ? (
                            <CheckCircle size={20} color="green" />
                          ) : (
                            <XCircle size={20} color="red" />
                          )}
                        </Tooltip>
                      </Td>

                      <Td>
                        <Flex justify="flex-end">
                          <CButton
                            variant="ghost"
                            color="purple"
                            leftIcon={<PencilSimple size={20} />}
                            onClick={() => handleEditToken(token.id)}
                          >
                            {t('table.body.editTokenBtn')}
                          </CButton>

                          <CButton
                            variant="ghost"
                            color="red"
                            leftIcon={<Trash size={20} />}
                            onClick={() => handleDeleteToken(token.id)}
                          >
                            {t('table.body.deleteTokenBtn')}
                          </CButton>
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>

            {!isLoading && (
              <Flex justify="center" align="center" gap={10} mt={4}>
                <IconButton
                  aria-label="back-button"
                  disabled={page.current <= 1}
                  onClick={handleChangeToPreviousPage}
                  icon={<ChevronLeftIcon w={6} h={6} />}
                />

                <Text>
                  <b>{page.current}</b>/{page.total}
                </Text>

                <IconButton
                  aria-label="next-button"
                  disabled={page.current >= page.total}
                  onClick={handleChangeToNextPage}
                  icon={<ChevronRightIcon w={6} h={6} />}
                />

                <Flex align="center" ml={2}>
                  <Select value={page.limit} onChange={handleChangePageLimit}>
                    {[10, 20, 50, 100].map(v => (
                      <option key={v} value={v}>
                        Show {v}
                      </option>
                    ))}
                  </Select>
                </Flex>
              </Flex>
            )}
          </BoxSection>
        </BoxStyled>
      </Container>
    </>
  );
};

export default Tokens;
