import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import api, { Service } from '../../../services/api';
import { subDays, format } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {LOCAL_STORAGE_KEYS } from '../../../constants/index'

import {
  ChartContainer,
  ContainerSection,
  SelectGroup,
  TitleModifiedCenter,
} from '../styles';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

interface IData {
  deposits: number;
  withdrawals: number;
  internalTransfers: number;
}

const startData = {
  deposits: 0,
  withdrawals: 0,
  internalTransfers: 0,
}

function TotalQuantityByDateTransactions() {
  const theme = useTheme();
  const { t } = useTranslation('charts');
  const [data, setData] = useState<IData>(startData)
  const {totalQuantityBydate} = LOCAL_STORAGE_KEYS

  const datesFromLocalStorage = localStorage.getItem(totalQuantityBydate)
  const parsedDate = datesFromLocalStorage && JSON.parse(datesFromLocalStorage)
  const startDateLocalStorare = parsedDate?.fromDate 
    ? new Date(parseInt(parsedDate.fromDate) * 1000) 
    : subDays(new Date(), 7)
  const endDateLocalStorare = parsedDate?.toDate 
    ? new Date(parseInt(parsedDate.toDate) * 1000) 
    : null

  const [startDate, setStartDate] = useState<Date>(startDateLocalStorare)
  const [endDate, setEndDate] = useState<Date | null>(endDateLocalStorare);
  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    (async function () {
      const query = {
        fromDate: format(startDate, 't'),
        ...(endDate && {toDate:  format(endDate, 't')})
      }
      const response = await api.get({
        route: 'reports/txn-by-date-range',
        service: Service.KRYPTO_BANKING,
        apiVersion: 'v1',
        query
      });
      if ("error" in response ) {
        setData(startData)
      } else {
        setData(response)
        localStorage.setItem(totalQuantityBydate, JSON.stringify(query))
      }
    })();
  }, [startDate, endDate]);

  let labels = ['']
  if (!endDate) {
    labels = [`${t('chartAxe.x.day')} ${format(startDate, 'MM/dd/yyyy')}`];
  } else {
    labels = [`
${t('chartAxe.x.pediodFrom')}
${format(startDate, 'MM/dd/yyyy')} 
${t('chartAxe.x.to')} 
${format(endDate, 'MM/dd/yyyy')}`];
  }

  
  const dataBar = {
    labels,
    datasets: [
      {
        label: t('chartLegend.chart4.QtyDeposits'),
        data: [data.deposits],
        backgroundColor: 'rgba(123, 151, 120, 0.75)',
      },
      {
        label: t('chartLegend.chart4.QtyWithdrawals'),
        data: [data.withdrawals],
        backgroundColor: 'rgba(240, 140, 174, 0.75)',
      },
      {
        label: t('chartLegend.chart4.QtyInternalTranfers'),
        data: [data.internalTransfers],
        backgroundColor: 'rgba(140, 150, 240, 0.75)',
      },
    ],
  };

  const optionsBar = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const,
        align: 'center' as const,
        labels: {
          font: {
            size: 12,
          },
          color: 'gray',
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: 'rgb(74,85,104)',
        },
        grid: {
          color: 'rgb(74,85,104)',
          borderColor: 'rgb(74,85,104)',
        },
      },
      y: {
        ticks: {
          color: 'rgb(74,85,104)',
        },
        grid: {
          color: 'rgb(74,85,104, .4)',
          borderColor: 'rgb(74,85,104)',
        },
        title: {
          display: true,
          text: t(`chartAxe.y.chart1`),
          color: 'rgb(74,85,104)',
        },
      },
    },
  };
    return (
      <ContainerSection gridName="TotalQuantityByDateTransactions">
      <TitleModifiedCenter>{t(`titleChart.chart4`)}</TitleModifiedCenter>
      <SelectGroup>
        <DatePicker
          wrapperClassName="datePicker"
          selected={startDate}
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          showWeekNumbers
          showDisabledMonthNavigation
        />
      </SelectGroup>
      <ChartContainer>
        <Bar data={dataBar} options={optionsBar} />
      </ChartContainer>
    </ContainerSection>
  );
}
export default TotalQuantityByDateTransactions;