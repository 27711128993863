import { Box, Flex, Select, Spinner } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useChangeCompany } from '../../../hooks/ChangeCompany';
import { IBarsProps } from '../../../interfaces/Common';
import { ICompany } from '../../../interfaces/Company';
import api, { Service } from '../../../services/api';
import { Logo } from '../../Logo';
import { ChangeLanguageSelect } from '../../Select/ChangeLanguageSelect';
import { OpenNavbarButton } from '../OpenNavbarButton';
import { StyledBox } from './styles';

const NavBarHorizontal: React.FC<IBarsProps> = ({ isOpen, onClick }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [companies, setCompanies] = useState([] as Array<ICompany>);

  const { company, setCompany } = useChangeCompany();

  const navigate = useNavigate();

  const getCompanies = useCallback(async () => {
    try {
      setIsLoading(true);

      const { data } = await api.get({
        route: 'companies',
        service: Service.KRYPTO_BANKING,
        apiVersion: 'v1',
        query: {
          limit: 100,
        },
      });

      if (!data?.length) return;

      setCompanies(data);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleSelectCompany = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { value: companyId } = e.target;

      const companyExists = companies.find(c => c.id === +companyId);
      if (!companyExists) return;

      setCompany(companyExists);
    },
    [companies, setCompany],
  );

  const handleNavigateToHome = () => navigate('/');

  useEffect(() => void getCompanies(), [getCompanies]);

  return (
    <StyledBox position="sticky" px={2} top={0} zIndex={999}>
      <Flex h={16} justify="space-between" align="center">
        <Flex>
          <OpenNavbarButton isOpen={isOpen} onClick={onClick} />

          <Box
            display={['none', 'block']}
            cursor="pointer"
            onClick={handleNavigateToHome}
          >
            <Logo />
          </Box>
        </Flex>

        <Flex justify="space-between" alignItems="center" gap={9} mr={4}>
          {isLoading && <Spinner color="white" />}

          {!isLoading && !!companies?.length && (
            <Select
              color="white"
              value={company.id}
              onChange={handleSelectCompany}
              variant="outline"
            >
              {companies.map(company => (
                <option
                  key={company.id}
                  value={company.id}
                  style={{ color: 'black' }}
                >
                  {company.name}
                </option>
              ))}
            </Select>
          )}

          <ChangeLanguageSelect />
        </Flex>
      </Flex>
    </StyledBox>
  );
};

export default NavBarHorizontal;
