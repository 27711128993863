import { Button, Th, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { NotePencil } from 'phosphor-react';
import { useTheme } from 'styled-components';

interface IViewAction {
  editAction: Function;
  log: any;
  endpoint: string;
}

export default function EditAction({ editAction, log, endpoint }: IViewAction) {
  const { t } = useTranslation('tables');
  const theme = useTheme();

  return (
    <Th w={'100px'} textAlign={'start'}>
      <Tooltip label={t(`common.edit`)}>
        <Button
          color={theme.form.color}
          isDisabled={!log.isActive && endpoint === 'users'}
          onClick={() => {
            editAction(log);
          }}
          fontSize={14}
          variant={'ghost'}
          _hover={{ bg: 'none' }}
          p={'0'}
        >
          <NotePencil size={21} />
        </Button>
      </Tooltip>
    </Th>
  );
}
