import styled from 'styled-components';

export const Fieldset = styled.fieldset`
  border: 1px solid ${props => props.theme.tables.color};
  border-radius: 4px;
  margin-bottom: 1.5rem;
`;
export const Legend = styled.legend`
  margin-left: 20px;
  padding: 0px 20px;
  color: ${props => props.theme.legend.color};
  font-weight: bold;
  font-size: 0.8rem;
`;
