import { Line } from 'react-chartjs-2';
import subDays from 'date-fns/subDays';
import api, { Service } from '../../../services/api';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Stack, Text, Tooltip as ChakraTooltip } from '@chakra-ui/react';

import {
  Info,
} from 'phosphor-react';

import {
  TitleModifiedCenter,
  ChartContainer,
  ContainerSection,
} from '../styles';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

function sumTransactionLast30days(
  deposits: number[],
  withdrawals: number[],
  internalTransfers: number[],
) {
  let sumPerDayTransactions = deposits.map((_, i: number) => {
    return deposits[i] + withdrawals[i] + internalTransfers[i]})
  return sumPerDayTransactions;
}

function ThirtyDaysTransactions() {
  const { t } = useTranslation('charts');
  const [transactions, setTransactions] = useState([]);

  const labels = Array.from(Array(30).keys())
    .map(e => format(subDays(Date.now(), e), 'dd/MM'))
    .reverse();

  useEffect(() => {
    (async function () {
      const response = await api.get({
        route: 'reports/instance-info',
        service: Service.KRYPTO_BANKING,
        apiVersion: 'v1',
      });
    
      const { deposits, withdrawals, internalTransfers } =
        response.LastThirtyDays;

      const resultSumTransactions = sumTransactionLast30days(
        deposits,
        withdrawals,
        internalTransfers,
      );
      setTransactions(resultSumTransactions as []);
    })();
  }, []);

  const dataLine = {
    labels,
    datasets: [
      {
        label: t('tooltip.chart7.AmountTransactions'),
        borderColor: 'rgb(170,173,196)',
        cubicInterpolationMode: 'monotone',
        data: transactions,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      line: {
        borderWidth: 1,
      },
      point: {
        radius: 3,
        borderColor: 'red',
      },
    },
    scales: {
      x: {
        ticks: {
          color: 'rgb(74,85,104)',
        },
        grid: {
          color: 'rgb(74,85,104, .4)',
          borderColor: 'rgb(74,85,104)',
        },
      },
      y: {
        ticks: {
          color: 'rgb(74,85,104)',
        },
        grid: {
          color: 'rgb(74,85,104, .4)',
          borderColor: 'rgb(74,85,104)',
        },
      },
    },
  };

  function tooltipFunc(textTooltip: string) {
    return (
      <Stack spacing="1px">
        <Info size={22} weight="fill" />
        <Text>{t(`tooltip.chart7.${textTooltip}`)}</Text>
      </Stack>
    );
  }

  return (
    <ContainerSection gridName="thirtyDaysTransactions">
      <ChakraTooltip label={tooltipFunc('AmountTransactions30Days')}>
        <TitleModifiedCenter>
          {t('titleChart.chart7')}
        </TitleModifiedCenter>
      </ChakraTooltip>
      <ChartContainer>
        <Line data={dataLine as any} options={options} />
      </ChartContainer>
    </ContainerSection>
  );
}

export default ThirtyDaysTransactions;
