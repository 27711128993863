import { useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import api, { Service } from '../../../services/api';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { subDays, format } from 'date-fns';
import { LOCAL_STORAGE_KEYS } from '../../../constants/index';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {
  ChartContainer,
  ContainerSection,
  SelectGroup,
  TitleModifiedCenter,
} from '../styles';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ReactSelect, { StylesConfig } from 'react-select';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);


interface IData {
  deposits: number;
  withdrawals: number;
}

const startData = {
  deposits: 0,
  withdrawals: 0,
};

function TotalByTokenAndDate() {
  const theme = useTheme();
  const { t } = useTranslation('charts');
  const { totalByTokenAndDate, customerTokens } = LOCAL_STORAGE_KEYS;
  const [tokenList, setTokenList] = useState<string[]>();
  const [data, setData] = useState<IData>(startData);
  const [selectedTokenAbbr, setSelectedTokenAbbr] = useLocalStorage(
    customerTokens,
    '',
  );

  const datesFromLocalStorage = localStorage.getItem(totalByTokenAndDate);
  const parsedDate = datesFromLocalStorage && JSON.parse(datesFromLocalStorage);
  const startDateLocalStorare = parsedDate?.fromDate
    ? new Date(parseInt(parsedDate.fromDate) * 1000)
    : subDays(new Date(), 7);
  const endDateLocalStorare = parsedDate?.toDate
    ? new Date(parseInt(parsedDate.toDate) * 1000)
    : null;

  const [startDate, setStartDate] = useState<Date>(startDateLocalStorare);
  const [endDate, setEndDate] = useState<Date | null>(endDateLocalStorare);
  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const colourStyles = useMemo(() => {
    const styles: StylesConfig = {
      control: styles => ({
        ...styles,
        cursor: 'pointer',
        width: '150px',
        height: '30px',
        flexWrap: 'nowrap',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        border: 'none',
        color: theme.form.color,
        borderBottom: '2px solid #656775',
        borderRadius: '0',
        ':focus': {
          outline: 'none',
        },
        ':active': {
          boxShadow: 'none',
        },
      }),
      indicatorSeparator: styles => ({ ...styles, width: '0' }),

      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          position: 'relative',
          width: '100%',
          cursor: 'pointer',
        };
      },
      input: styles => ({
        ...styles,
        display: 'block',
        paddingTop: '25px',
        color: theme.form.color,
      }),
      container: styles => ({ ...styles, width: '100%' }),
      singleValue: styles => ({ ...styles, color: theme.form.color }),
    };

    return styles;
  }, [theme]);

  useEffect(() => {
    (async function () {
      const response = await api.get({
        route: 'tokens?limit=1000',
        service: Service.KRYPTO_BANKING,
        apiVersion: 'v1',
      });


      if (response?.data) {
        const formattedData = response.data.map((token: any) => ({
          value: token.abbr,
          label: token.abbr,
        }));

        setTokenList(formattedData);
      }
    })();
  }, []);

  useEffect(() => {
    (async function () {
      if (selectedTokenAbbr === '') {
        setData(startData);
        return;
      }
      const query = {
        fromDate: format(startDate, 't'),
        ...(endDate && { toDate: format(endDate, 't') }),
        token: selectedTokenAbbr,
      };

      const response = await api.get({
        route: '/reports/txn-by-date-range-and-token',
        service: Service.KRYPTO_BANKING,
        apiVersion: 'v1',
        query,
      });

  
      if ('error' in response) {
        setData(startData);
      } else {
        setData(response);
        localStorage.setItem(totalByTokenAndDate, JSON.stringify(query));
      }
    })();
  }, [startDate, endDate, selectedTokenAbbr]);

  let labels = [''];

  if (!endDate) {
    labels = [`${t('chartAxe.x.day')} ${format(startDate, 'MM/dd/yyyy')}`];
  } else {
    labels = [
      `
${t('chartAxe.x.pediodFrom')}
${format(startDate, 'MM/dd/yyyy')} 
${t('chartAxe.x.to')} 
${format(endDate, 'MM/dd/yyyy')}`,
    ];
  }

  const dataBar = {
    labels,
    datasets: [
      {
        label: t('chartLegend.chart5.Deposits'),
        data: [data.deposits],
        backgroundColor: 'rgba(123, 151, 120, 0.75)',
      },
      {
        label: t('chartLegend.chart5.Withdrawals'),
        data: [data.withdrawals],
        backgroundColor: 'rgba(240, 140, 174, 0.75)',
      },
    ],
  };

  const optionsBar = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const,
        align: 'center' as const,
        labels: {
          font: {
            size: 12,
          },
          color: 'gray',
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: 'rgb(74,85,104)',
        },
        grid: {
          color: 'rgb(74,85,104)',
          borderColor: 'rgb(74,85,104)',
        },
      },
      y: {
        ticks: {
          color: 'rgb(74,85,104)',
        },
        grid: {
          color: 'rgb(74,85,104, .4)',
          borderColor: 'rgb(74,85,104)',
        },
        title: {
          display: true,
          text: t('chartAxe.y.chart5'),
          color: 'rgb(74,85,104)',
        },
      },
    },
  };

  return (
    <ContainerSection gridName="totalByTokenAndDate">
      <TitleModifiedCenter>{t('titleChart.chart5')}</TitleModifiedCenter>
      <SelectGroup>
        <ReactSelect
          className="language"
          options={tokenList}
          defaultValue={selectedTokenAbbr}
          onChange={(e: any) => setSelectedTokenAbbr(e.value)}
          styles={colourStyles}
          isSearchable
        ></ReactSelect>

        <DatePicker
          wrapperClassName="datePicker"
          selected={startDate}
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          showWeekNumbers
          showDisabledMonthNavigation
        />
      </SelectGroup>
      <ChartContainer>
        <Bar data={dataBar as any} options={optionsBar} />
      </ChartContainer>
    </ContainerSection>
  );
}

export default TotalByTokenAndDate;
