import React, {
  createContext,
  Dispatch,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  REFRESH_TOKEN_COOKIE_NAME,
  TOKEN_COOKIE_NAME,
  USER_COMPANY_COOKIE_NAME,
  USER_COOKIE_NAME,
} from '../constants';
import { useLocalStorage } from '../hooks/LocalStorage';
import { IUser } from '../interfaces/User';
import { useKeycloak } from '@react-keycloak/web';
import { destroyCookie } from 'nookies';
import api, { Service } from '../services/api';
import { useToast } from '@chakra-ui/react';

interface AuthProps {
  setUser: Dispatch<IUser>;
  logoutUser: () => void;
  loginUser: () => void;
  user: IUser;
}

export const AuthContext = createContext({} as AuthProps);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<IUser>({} as IUser);
  const [, setLocalStorage, getLocalStorage] = useLocalStorage('', '');

  const { keycloak } = useKeycloak();
  const toast = useToast();

  useEffect(() => {
    if (window) {
      const userInfo = getLocalStorage(USER_COOKIE_NAME);

      if (!userInfo) return;

      try {
        const parsedUserInfo = JSON.parse(userInfo) as IUser;

        if (!parsedUserInfo.email) {
          logoutUser();
          window.location.href = './index';
          return;
        }

        setUser(parsedUserInfo);
      } catch {
        logoutUser();
      }
    }
  }, []);

  const logoutUser = useCallback(async () => {
    destroyCookie(null, TOKEN_COOKIE_NAME);
    destroyCookie(null, REFRESH_TOKEN_COOKIE_NAME);
    destroyCookie(null, USER_COOKIE_NAME);
    destroyCookie(null, USER_COMPANY_COOKIE_NAME);

    await keycloak.logout();
    window.location.href = './login';
  }, [keycloak]);

  const loginUser = useCallback(async () => {
    setLocalStorage(TOKEN_COOKIE_NAME, keycloak.token || '');
    setLocalStorage(REFRESH_TOKEN_COOKIE_NAME, keycloak.refreshToken || '');

    const meResponse = await api.get({
      route: 'auth/user-auth',
      service: Service.KRYPTO_BANKING,
      apiVersion: 'v1',
    });

    if (meResponse?.message || meResponse?.error) {
      await logoutUser();

      return toast({
        title: 'Error',
        description: meResponse.message || meResponse.error,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }

    const redirectURL = meResponse.isKleverAdmin ? '/account' : '/dashboard';
    setLocalStorage(USER_COOKIE_NAME, JSON.stringify(meResponse));
    window.location.href = redirectURL;
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        logoutUser,
        loginUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
