interface IResponse {
  deposits: number;
  withdrawals: number;
  internalTransfers: number;
  preCalculation: number;
}

export function calculationResults({
  deposits,
  withdrawals,
  internalTransfers,
  preCalculation,
}: IResponse) {
  const depositCost = deposits * preCalculation;
  const withdrawCost = withdrawals * preCalculation;
  const internalTransferCost = internalTransfers * preCalculation;
  const totalCost = depositCost + withdrawCost + internalTransferCost;
  return { depositCost, withdrawCost, internalTransferCost, totalCost };
}
