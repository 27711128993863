import { Box } from '@chakra-ui/react';
import styled from 'styled-components';

export const ContentContainer = styled(Box)`
  margin: 1rem;
  display: flex;
  flex: 1;
  color: ${props => props.theme.form.color} !important;
  overflow: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;
