import { useKeycloak } from '@react-keycloak/web';
import { useMemo } from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes as Switch,
} from 'react-router-dom';
import { TOKEN_COOKIE_NAME } from '../constants';
import { useLocalStorage } from '../hooks/LocalStorage';
import { Login } from '../pages';
import { KleverSpinner, LoginContainer } from '../pages/Login/styles';
import {
  Account,
  Administrators,
  Balances,
  Companies,
  Logs,
  ManageTicket,
  Networks,
  Statement,
  Tokens,
  TokensNetworks,
} from './lazyRoutes';
import { NoKleverAdmin, RequireAuth, RequireAuthAdmin } from './middlewares';

const LoggedOut: React.FC = () => {
  const [, , getLocalStorage] = useLocalStorage('', '');

  const isLoggedIn = useMemo(() => {
    return getLocalStorage(TOKEN_COOKIE_NAME);
  }, []);

  return isLoggedIn ? <Navigate to="/account" /> : <Login />;
};

const Routes: React.FC = () => {
  const { initialized } = useKeycloak();

  if (!initialized) {
    return (
      <LoginContainer>
        <KleverSpinner />
      </LoginContainer>
    );
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" element={<Navigate to="/login" />} />

        <Route path="/login" element={<LoggedOut />} />

        <Route element={<RequireAuth />}>
          <Route path="/account" element={<Account />} />

          <Route path="/logs" element={<Logs />} />

          <Route path="/user" element={<Administrators />} />

          {/* <Route path="/billing" element={<Billing />} /> */}

          <Route path="/manage" element={<ManageTicket />}>
            <Route path=":id" element={<ManageTicket />} />
          </Route>

          {/* <Route
            path="/create-network-token"
            element={<CreateNetworkToken />}
          /> */}

          {/* <Route path="/dashboard" element={<Dashboard />} /> */}

          <Route element={<NoKleverAdmin />}>
            <Route path="/statement" element={<Statement />} />

            <Route path="/balance" element={<Balances />} />
          </Route>

          <Route path="/networks" element={<Networks />} />

          <Route path="/tokens" element={<Tokens />} />

          <Route path="/tn" element={<TokensNetworks />} />
        </Route>

        <Route element={<RequireAuthAdmin />}>
          <Route path="/company" element={<Companies />} />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
