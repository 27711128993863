import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { importResources, defaultNS } from './i18n/index';
import { lng, fallbackLng } from './i18n/getLanguage';

const resources = importResources(lng);

i18n.use(initReactI18next).init({
  lng,
  fallbackLng,
  ns: [
    'common',
    'login',
    'navbar',
    'companyAccount',
    'adminAccount',
    'tables',
    'adminCompanies',
    'adminAdministrators',
    'drawer',
    'application',
    'logs',
    'autoComplete',
    'statement',
    'manageTicket',
    'support',
    'filter',
    'billing',
  ],
  defaultNS,
  resources,

  supportedLngs: ['en', 'pt-BR', 'en-US', 'es', 'it', 'fr'],

  debug: false,
});

export default i18n;
