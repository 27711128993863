import React, { useEffect } from 'react';
import Container from '../../components/Container';
import Nav from '../../components/Navbar';

import { SubContainer } from './styles';
import { useChangeCompany } from '../../hooks/ChangeCompany';
import CreateNetwork from '../../components/CreateNetworkToken/CreateNetwork';
import CreateToken from '../../components/CreateNetworkToken/CreateToken';

const CreateNetworkToken: React.FC<any> = () => {
  const { userIsKlever } = useChangeCompany();

  useEffect(() => {
    if (userIsKlever) {
      window.location.href = '/account';
    }
  }, [userIsKlever]);

  return (
    <Container>
      <Nav />
      <SubContainer>
        <CreateNetwork />
        <CreateToken />
      </SubContainer>
    </Container>
  );
};

export default CreateNetworkToken;