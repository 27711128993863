import * as Yup from 'yup';

export const createTokenSchema = Yup.object().shape({
  networkId: Yup.number().required(),
  assetId: Yup.string().required(),
  name: Yup.string().required(),
  abbr: Yup.string().required(),
  precision: Yup.number().required(),
  creator: Yup.string().required(),
  withdrawFeeAmount: Yup.string().required(),
  withdrawFeeTokenId: Yup.number().required(),
  BurnFeeAmount: Yup.string().required(),
  minWithdraw: Yup.string().required(),
  minDeposit: Yup.string().required(),
  minCheckBalanceAmountUsd: Yup.number().required(),
  minSendColdAccountAmountUsd: Yup.number().required(),
  sendCheckBalanceUsd: Yup.number().required(),
  maxColdAccountsTransfer: Yup.number().required(),
  isNft: Yup.boolean().required(),
  isMaincoin: Yup.boolean().required(),
  activeDeposit: Yup.boolean().required(),
  activeWithdraw: Yup.boolean().required(),
});
