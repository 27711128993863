import { SortType } from '../interfaces/Common';

export function sortByNumber(
    dataToSort: any[],
    type: SortType,
    header: string,
    backToNormal: boolean = false,
) {
    if (typeof dataToSort !== 'object') {
        return dataToSort;
    }

    const newArray = [...dataToSort];

    newArray.sort((a, b) => {
        // <= -1 then a will come before b.
        // 0 then keep a and b in the same positions
        // >= 1 then b will come before a

        if (backToNormal) {
            return a['createdAt'] - b['createdAt'];
        }

        if (!a[header] || !b[header]) {
            return a['createdAt'] - b['createdAt'];
        }

        if (type === 'DESC') {
            return b[header] - a[header];
        }

        if (type === 'ASC') {
            return a[header] - b[header];
        }

        return 0;
    });

    return newArray;
}
