import { Box, Heading, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import Container from '../../components/Container';
import Nav from '../../components/Navbar';
import PaginatedTable from '../../components/PaginatedTable';

import BoxSection from '../../components/BoxSection';
import Button from '../../components/Button';

import {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../hooks/Auth';
import { useChangeCompany } from '../../hooks/ChangeCompany';
import Deposit from './Ticket';

import { TicketStatusInterface } from './Status';

import ModalComponent from '../../components/Modal';
import { promisify } from '../../utils/promisify';
import {
  manageTicketAdminFilterFields,
  manageTicketFilterFields,
} from './fields';

import { ArrowLeft, PencilSimple } from 'phosphor-react';
import BoxStyled from '../../components/BoxStyled';

interface FiltersProps {
  companyName: string;
  chain: number;
  transactionId: string;
  description: string;
  status: TicketStatusInterface;
  toDate: Date;
  fromDate: Date;
}

const LazyFilter = lazy(() => import('../../components/Filter/index'));

const ManageTicket: React.FC = () => {
  const { t } = useTranslation('manageTicket');
  const { user } = useAuth();
  const { company, userIsKlever } = useChangeCompany();
  const { id } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const theme = useTheme();
  const navigate = useNavigate();

  const [requestLoad, setRequestLoad] = useState(true);

  const [filters, setFilters] = useState<Partial<FiltersProps>>();

  const handleReload = () => setRequestLoad(prev => !prev);

  useEffect(() => {
    (async () => {
      if (user.isKleverAdmin) {
        setRequestLoad(true);
        await promisify(() => setRequestLoad(false));
        return;
      }
    })();
  }, [company, userIsKlever, id]);

  const filterFields = useMemo(() => {
    if (userIsKlever) {
      return manageTicketAdminFilterFields;
    }

    return manageTicketFilterFields;
  }, [
    t,
    userIsKlever,
    manageTicketAdminFilterFields,
    manageTicketFilterFields,
  ]);

  const handleFilters = useCallback((e: object) => {
    setFilters(e);
  }, []);

  return (
    <Container>
      <Nav />
      <BoxStyled>
        <BoxSection>
          <Box display={'flex'} flexDirection={'column'}>
            <Heading
              textAlign={'center'}
              color={theme.common.lightText}
              position="relative"
              mb="1rem"
            >
              {t('title')} {id}
            </Heading>
            {id && (
              <>
                <Button
                  alignSelf={['center', 'center', 'center', 'start', 'start']}
                  mt={['0rem', '0rem', '0rem', '0rem', '-4rem']}
                  mb="1.5rem"
                  gap="0.5rem"
                  onClick={() => navigate('/manage')}
                >
                  <ArrowLeft size={20} />
                  {t('buttons.backBtn')}
                </Button>
              </>
            )}
            {!userIsKlever && (
              <Button
                alignSelf={['center', 'center', 'center', 'end', 'end']}
                mt={['0rem', '0rem', '0rem', '-4rem', '-4rem']}
                mb="1.5rem"
                gap="0.5rem"
                onClick={onOpen}
              >
                <PencilSimple size={20} />
                {t('buttons.createBtn')}
                <ModalComponent
                  handleReload={handleReload}
                  typeModal="createTicket"
                  onClose={onClose}
                  isOpen={isOpen}
                />
              </Button>
            )}
          </Box>

          {!id && (
            <Suspense fallback={'Loading..'}>
              <LazyFilter
                translation="manageTicket"
                onSubmit={handleFilters}
                fields={filterFields}
              />
            </Suspense>
          )}
        </BoxSection>
        {id ? (
          <Deposit />
        ) : (
          <PaginatedTable
            endpoint="support-tickets"
            headers={[
              'id',
              'companyName',
              'chain',
              'transactionId',
              'type',
              'status',
              'createdAt',
            ]}
            ns="manageTicketTable"
            viewAction={(data: any) => {
              navigate(`/manage/${data.id}`, { replace: true });
            }}
            filters={filters}
            requestLoad={requestLoad}
          />
        )}
      </BoxStyled>
    </Container>
  );
};

export default ManageTicket;
