import Routes from './routes';

import { ReactKeycloakProvider } from '@react-keycloak/web';
import React, { ReactNode, Suspense } from 'react';

import { AuthProvider } from './contexts/Auth';
import { ChangeCompanyProvider } from './contexts/ChangeCompany';
import keycloak from './keycloak';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { ChakraProvider, Spinner } from '@chakra-ui/react';
import { AppContextProvider } from './contexts/AppContext';
import { ThemeProvider } from './contexts/Theme';

const bugsnagApiKey = process.env.REACT_APP_BUGSNAG_API_KEY;

const ErrorComponent = ({ children }: Record<'children', ReactNode>) => {
  let ErrorBoundary: any;

  if (bugsnagApiKey) {
    Bugsnag.start({
      apiKey: bugsnagApiKey,
      plugins: [new BugsnagPluginReact()],
    });

    ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React);
  }

  return ErrorBoundary ? (
    <ErrorBoundary>{children}</ErrorBoundary>
  ) : (
    <>{children}</>
  );
};

const App = () => (
  <ChakraProvider>
    <ThemeProvider>
      <Suspense fallback={<Spinner />}>
        <ErrorComponent>
          <ReactKeycloakProvider
            authClient={keycloak}
            initOptions={{
              onLoad: 'login-required',
            }}
          >
            <AuthProvider>
              <ChangeCompanyProvider>
                <AppContextProvider>
                  <Routes />
                </AppContextProvider>
              </ChangeCompanyProvider>
            </AuthProvider>
          </ReactKeycloakProvider>
        </ErrorComponent>
      </Suspense>
    </ThemeProvider>
  </ChakraProvider>
);

export default App;
