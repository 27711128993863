import styled, { css } from 'styled-components';
import { Box as ChakraBox } from '@chakra-ui/react';

type IBoxProps = {
  isOpen: boolean;
};

const BoxModifiers = {
  isOpen: () => css`
    width: 15rem;

    nav span {
      display: inline;
    }
  `,
  default: () => css`
    width: 4.5rem;

    #caret {
      display: none;
    }

    .overflow {
      display: none;
    }

    @media screen and (max-width: 615px) {
      width: 0;
      opacity: 0;
    }
  `,
};

export const StyledBox = styled(ChakraBox)<IBoxProps>`
  ${({ theme, isOpen }) => css`
    background-color: ${theme.navbar.background};
    color: ${theme.input.color};
    transition: all 0.4s;

    ${isOpen ? BoxModifiers['isOpen']() : BoxModifiers['default']()}
  `}
`;
