import { useKeycloak } from '@react-keycloak/web';
import { useEffect } from 'react';

import { TOKEN_COOKIE_NAME } from '../../constants';
import { useAuth } from '../../hooks/Auth';
import { useLocalStorage } from '../../hooks/LocalStorage';
import { KleverSpinner, LoginContainer } from './styles';

const Login: React.FC = () => {
  const [, , getLocalStorage] = useLocalStorage(TOKEN_COOKIE_NAME, {});
  const { loginUser } = useAuth();

  const { keycloak, initialized } = useKeycloak();

  useEffect(() => {
    if (initialized) {
      if (!keycloak.authenticated) {
        keycloak.login();
        return;
      }

      if (!getLocalStorage(TOKEN_COOKIE_NAME)) {
        loginUser();
        return;
      }
    }
  }, [initialized]);

  return (
    <LoginContainer>
      <KleverSpinner />
    </LoginContainer>
  );
};

export default Login;
