import { PrimaryButton, OutlinedButton, CopyButton } from './styles';

const Button: React.FC<any> = ({ buttonType, ...props }) => {
    switch (buttonType) {
        case 'primary':
            return <PrimaryButton {...props} />;
        case 'outlined':
            return <OutlinedButton {...props} />;
        case 'copy':
            return <CopyButton {...props} />;
        default:
            return <PrimaryButton {...props} />;
    }
};

export default Button;
