import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  max-width: 30rem;
  padding: 2rem;
  border-radius: 0.5rem;
  background-color: ${props => props.theme.form.background};
  box-shadow: 0px 0px 0px rgba(16, 22, 26, 0.15);
  p {
    color: gray;
    text-align: justify;
    text-justify: inter-word;
    margin: 1rem 0;
  }
`;
