import styled from 'styled-components';

export const SubContainer = styled.div`
  margin-left: 64px;
  max-width: 100%;

  @media only screen and (max-width: 615px)  {
    margin-left: 0;
  }
`
