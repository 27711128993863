import { Flex, Th } from '@chakra-ui/react';

interface IButtonSort {
  sortable: boolean;
  icon: any;
  header: string;
  setOrder: () => void;
}

export function ButtonSort({
  header,
  sortable,
  icon: Icon,
  setOrder,
}: IButtonSort) {
  return (
    <Th
      p={'3'}
      cursor={sortable ? 'pointer' : 'auto'}
      onClick={() => {
        setOrder?.();
      }}
    >
      <Flex alignItems={'center'} gap="5px">
        {header}
        {sortable && <Icon size={17} />}
      </Flex>
    </Th>
  );
}
