import { Spinner } from '@chakra-ui/react';
import { useTheme } from 'styled-components';
import ContentContainer from '../ContentContainer';

interface ILoading {
  margin?: string;
  color?: string;
}

const Loading = ({ margin, color }: ILoading) => {
  const theme = useTheme();

  return (
    <ContentContainer margin={margin}>
      <Spinner color={color ?? theme.tables.color} />
    </ContentContainer>
  );
};

export { Loading };
