import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import {
  CaretDown,
  CheckCircle,
  Clock,
  PauseCircle,
  XCircle,
} from 'phosphor-react';
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { LabelKeyObject } from 'react-csv/components/CommonPropTypes';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { BLOCKED_ATTRIBUTES } from '../../constants';
import { AppContext } from '../../contexts/AppContext';
import { useChangeCompany } from '../../hooks/ChangeCompany';
import {
  IconObjectProps,
  ILog,
  IPaginatedTableProps,
  ISortProps,
  PaginationStateProps,
  showDifferenceProps,
} from '../../interfaces/Table';
import api, { Service } from '../../services/api';
import {
  formatCurrency,
  formatDate,
  truncateString,
} from '../../utils/formatters';
import { Loading } from '../Loading';
import MoreInfoTicket from '../MoreInfoTicket';
import { NoResults } from '../NoResults';
import CheckAction from './CheckAction';
import { CSVDownload } from './CSVDownload';
import DeleteAction from './DeleteAction';
import EditAction from './EditAction';
import ShowDifference from './showDifference';
import { SortButton } from './Sort';
import './styles.css';
import ViewAction from './ViewAction';

const PaginatedTable = ({
  shouldReload,
  endpoint,
  headers,
  ns,
  editAction,
  deleteAction,
  checkAction,
  viewAction,
  filters,
  requestLoad = true,
  withCryptoIcon = false,
  hiddenDetails = [],
}: IPaginatedTableProps) => {
  const [headersSort, setHeadersSort] = useState<ISortProps>({} as ISortProps);
  const [isFetching, setIsFetching] = useState(true);
  const [loading, setLoading] = useState(true);
  const flag = useRef<boolean | undefined>(false);
  const { t } = useTranslation('tables');
  const { userIsKlever } = useChangeCompany();
  const theme = useTheme();
  const toast = useToast();
  const { id: idTicket } = useParams();
  const { reloadPageDrawerClose } = useContext(AppContext);

  const [pagination, setPagination] = useState<PaginationStateProps>({
    data: [],
    page: 1,
    limit: 10,
    total: 0,
    totalPages: 0,
    next: 0,
    prev: 0,
  });

  const gotoPage = async (page: number, withLimit?: number) => {
    setLoading(true);

    if (headersSort.value) {
      setHeadersSort({} as ISortProps);
    }

    const query = {
      page: withLimit ? 1 : page,
      limit: withLimit ? withLimit : pagination.limit,
      ...filters,
    };

    const response = await api.get({
      route: endpoint,
      service: Service.KRYPTO_BANKING,
      apiVersion: 'v1',
      query,
    });

    if (response?.message) {
      return toast({
        title: 'Error',
        description: response.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } else {
      setPagination({ ...response });

      setLoading(false);
    }
  };

  const initialFetch = useCallback(async () => {
    setLoading(true);
    setIsFetching(true);

    if (headersSort.value) {
      setHeadersSort({} as ISortProps);
    }

    const query = {
      page: pagination.page,
      limit: pagination.limit,
      ...filters,
    };

    const response = await api.get({
      route: endpoint,
      service: Service.KRYPTO_BANKING,
      apiVersion: 'v1',
      query,
    });

    if (response?.message || response.error) {
      toast({
        title: 'Error',
        description: response.message || response.error,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } else {
      if (response.data) {
        setPagination({ ...response });
      } else {
        const data = [response] as any;

        setPagination({
          data,
          limit: 0,
          next: 0,
          page: 1,
          total: 1,
          totalPages: 1,
          prev: 0,
        });
      }
    }

    setLoading(false);
    setIsFetching(false);
  }, [
    endpoint,
    filters,
    pagination.limit,
    pagination.page,
    toast,
    loading,
    headersSort,
  ]);

  useEffect(() => {
    if (!shouldReload) return;

    initialFetch();
  }, [shouldReload]);

  useEffect(() => {
    if (!isFetching && pagination.page === 1) {
      initialFetch();
      return;
    }
  }, [filters]);

  useEffect(() => {
    if (!isFetching && reloadPageDrawerClose && pagination.page === 1) {
      initialFetch();
      return;
    }
  }, [reloadPageDrawerClose]);

  useEffect(() => {
    if (requestLoad) initialFetch();
  }, [requestLoad]);

  const getRowData = (log: ILog, attribute: keyof ILog, index: number) => {
    if (BLOCKED_ATTRIBUTES.includes(attribute) && !userIsKlever) {
      return;
    }

    if (attribute === 'fromAddress' || attribute === 'toAddress') {
      return (
        <Td key={index} p={'3'}>
          {truncateString(log[attribute] as string)}
        </Td>
      );
    }

    if (attribute === 'chain' && log['chain'] && log['chain'] < 0) {
      return <Td key={index} p={'3'}></Td>;
    }

    if (attribute === 'amount') {
      const formatAmount = formatCurrency(+log[attribute]!);
      const currencyName = log.abbr;

      return (
        <Td key={index} p={'3'}>
          {withCryptoIcon ? (
            <Tooltip label={currencyName ?? 'Amount'}>
              <Flex gap="10px">
                <img
                  className="currencyIcon"
                  src={`/icons/${currencyName?.toLocaleLowerCase()}.png`}
                  alt={`${currencyName} icon`}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = `/icons/klv-no-image.png`;
                  }}
                />
                {formatAmount.substring(1)}
              </Flex>
            </Tooltip>
          ) : (
            formatAmount
          )}
        </Td>
      );
    }

    if (attribute === 'Company') {
      return (
        <Td key={index} p={'3'}>
          {log[attribute]?.name ?? 'Klever'}
        </Td>
      );
    }

    if (log[attribute] === undefined) {
      return (
        <Td key={index} p={'3'}>
          N/A
        </Td>
      );
    }

    if (attribute === 'createdAt' || attribute === 'updatedAt') {
      return (
        <Td key={index} p={'3'}>
          {formatDate(log[attribute])}
        </Td>
      );
    }

    if (attribute === 'status') {
      const status = log['status'] ?? 'done';

      const iconStatusType: IconObjectProps = {
        pending: {
          color: theme.status.pendingColor,
          icon: <PauseCircle />,
        },
        in_progress: {
          color: theme.status.inProgressColor,
          icon: <Clock />,
        },
        refused: {
          color: theme.status.refusedColor,
          icon: <XCircle />,
        },
        error: {
          color: theme.status.errorColor,
          icon: <XCircle />,
        },
        done: {
          color: theme.status.doneColor,
          icon: <CheckCircle />,
        },
      };

      return (
        <Td key={index} p={'3'} position={'relative'}>
          <Tooltip
            bg={iconStatusType[status].color}
            label={t(`logsTable.${log['status']}`)}
          >
            <Box
              style={{
                width: '20px',
              }}
            >
              {React.cloneElement(iconStatusType[status].icon, {
                color: iconStatusType[status].color,
                style: {
                  fontSize: '20px',
                  cursor: 'pointer',
                },
              })}
            </Box>
          </Tooltip>
        </Td>
      );
    }

    if (attribute === 'type') {
      return (
        <Td key={index} p={'3'}>
          {t(`logsTable.${log['type']}`)}
        </Td>
      );
    }

    if (
      attribute === 'info' &&
      (log['type'] === 'edit_user' || log['type'] === 'edit_company')
    ) {
      const values = JSON.parse(log[attribute]);
      return (
        <Td key={index} p={'3'}>
          {values.map(
            (
              { field, oldValue, newValue }: showDifferenceProps,
              index: number,
            ) => (
              <Box key={index}>
                <ShowDifference
                  field={field}
                  oldValue={oldValue}
                  newValue={newValue}
                />
              </Box>
            ),
          )}
        </Td>
      );
    }

    return (
      <Td key={index} p={'3'}>
        {typeof log[attribute] !== 'boolean'
          ? log[attribute]
          : log[attribute]
          ? t('common.yes')
          : t('common.no')}
      </Td>
    );
  };

  const getDetailsData = (log: ILog, attribute: keyof ILog, index: number) => {
    let attr = log[attribute];

    if (attribute === 'createdAt' || attribute === 'updatedAt')
      attr = formatDate(log[attribute]);

    return (
      <Flex
        key={index}
        flexDirection={'column'}
        gap={'2rem'}
        as={'ul'}
        listStyleType={'none'}
        whiteSpace={'nowrap'}
      >
        <li style={{ marginBlock: '5px' }}>
          <Text as="strong">{t(`${ns}.${attribute}`)}: </Text>
          <Text>{attr ? attr : 'N/A'}</Text>
        </li>
      </Flex>
    );
  };

  const openHiddenDetails = useCallback((id: number, createdAt?: string) => {
    const detailsRow = document.querySelector(
      `#details-${id}${createdAt ? '-' + createdAt : ''}`,
    );

    if (detailsRow) {
      detailsRow.classList.toggle('active');
      detailsRow.classList.toggle('notActive');
    }
  }, []);

  const dataIsDeposit = (data: ILog) => {
    const isDeposit =
      userIsKlever &&
      data.type === 'deposit' &&
      data.status?.toString().includes('in_progress');
    flag.current = isDeposit;
    return isDeposit;
  };

  const csvHeaders = useMemo(() => {
    return headers
      .map(k => {
        if (k === 'Company') return;

        const translation = t(`${ns}.${k}`);

        return {
          label: translation,
          key: k,
        };
      })
      .filter(Boolean) as LabelKeyObject[];
  }, [pagination.data]);

  const csvData = useMemo(() => {
    const data = pagination.data.map(p => {
      let translatedCSV = {};

      const keys = Object.keys(p);

      keys.forEach(header => {
        if (header === 'Company') return;
        if (!headers.includes(header)) return;

        const translationValue =
          header === 'type' ? t(`logsTable.${p[header]}`) : p[header];

        translatedCSV = {
          ...translatedCSV,
          [header]: translationValue,
        };
      });

      return translatedCSV;
    });

    return data;
  }, [pagination.data]);

  return (
    <Box
      maxW={'80%'}
      p={'1px'}
      minW={'20%'}
      m="auto"
      backgroundColor={theme.tables.background}
      color={theme.common.lightText}
      borderRadius={10}
      mt={8}
      overflow="auto"
    >
      {loading && <Loading />}
      {(!pagination.data ||
        pagination.data.length <= 0 ||
        (pagination.data.length > 0 && pagination.data[0].data === null)) &&
        !loading && <NoResults />}

      {!loading &&
        pagination.data?.length >= 1 &&
        pagination.data[0].data !== null && (
          <>
            <Box w={'100%'} p={'6'} overflow="hidden">
              <Table
                backgroundColor={theme.tables.background}
                color={theme.tables.color}
                mt={10}
              >
                <Thead>
                  <Tr data-cy="header-table">
                    {headers
                      .filter(header =>
                        !userIsKlever
                          ? !BLOCKED_ATTRIBUTES.includes(header)
                          : true,
                      )
                      .map((header: string, index) => (
                        <SortButton
                          setPagination={setPagination}
                          data={pagination.data}
                          translation={ns}
                          header={header}
                          key={index}
                        />
                      ))}

                    {viewAction && <Th>{t('common.view')}</Th>}
                    {editAction && <Th>{t('common.edit')}</Th>}
                    {deleteAction && <Th>{t('common.action')}</Th>}
                    {checkAction && flag.current && (
                      <Th>{t('common.checkDeposit')}</Th>
                    )}
                    {hiddenDetails.length > 0 && (
                      <Th>{t('common.moreDetails')}</Th>
                    )}
                  </Tr>
                </Thead>
                <Tbody>
                  {pagination.data.map((log: ILog, index: number) => {
                    return (
                      <Fragment key={index}>
                        <Tr w="100%">
                          {headers.map((attribute: any, indx) => {
                            return getRowData(log, attribute, indx);
                          })}

                          {viewAction && (
                            <ViewAction
                              viewAction={viewAction}
                              log={log}
                              endpoint={endpoint}
                            />
                          )}

                          {editAction && (
                            <EditAction
                              editAction={editAction}
                              log={log}
                              endpoint={endpoint}
                            />
                          )}

                          {deleteAction && (
                            <DeleteAction
                              deleteAction={deleteAction}
                              log={log}
                              gotoPage={gotoPage}
                            />
                          )}

                          {dataIsDeposit(log) && checkAction && (
                            <CheckAction checkAction={checkAction} log={log} />
                          )}

                          {hiddenDetails.length > 0 && (
                            <Th>
                              <Tooltip label={t(`common.moreDetails`)}>
                                <Button
                                  size={'sm'}
                                  onClick={async () => {
                                    openHiddenDetails(log.id, log.createdAt);
                                  }}
                                >
                                  <CaretDown size={14} />
                                </Button>
                              </Tooltip>
                            </Th>
                          )}
                        </Tr>

                        {hiddenDetails.length > 0 && (
                          <Tr
                            className="notActive"
                            id={`details-${log.id}-${log.createdAt}`}
                            opacity={0}
                            display={'block'}
                            width={'200px'}
                          >
                            <Td colSpan={7} border={'none'}>
                              {hiddenDetails.map((column: any, index) => {
                                return getDetailsData(log, column, index);
                              })}
                            </Td>
                          </Tr>
                        )}
                      </Fragment>
                    );
                  })}
                </Tbody>
              </Table>

              <Box w={'100%'} display={'flex'} mt={'5'} justifyContent={'end'}>
                <CSVDownload
                  title={t('common.downloadText')}
                  data={csvData}
                  headers={csvHeaders}
                  filename={endpoint}
                />
              </Box>
            </Box>

            {pagination.limit >= 10 && (
              <Flex
                data-cy="pagination-container"
                justifyContent="space-between"
                m={4}
                alignItems="center"
                color={theme.tables.color}
                gap={1}
              >
                <Flex>
                  <Tooltip label={t('common.firstPage')}>
                    <IconButton
                      onClick={() => gotoPage(1)}
                      icon={<ArrowLeftIcon h={3} w={3} />}
                      mr={4}
                      aria-label={''}
                      color={theme.tables.button.color}
                      isDisabled={pagination.page <= 1}
                    />
                  </Tooltip>
                  <Tooltip label={t('common.prevPage')}>
                    <IconButton
                      onClick={() => {
                        gotoPage(pagination.page - 1);
                      }}
                      isDisabled={pagination.page <= 1}
                      icon={<ChevronLeftIcon h={6} w={6} />}
                      aria-label={''}
                      color={theme.tables.button.color}
                    />
                  </Tooltip>
                </Flex>

                <Flex alignItems="center">
                  <Text flexShrink={1} mr={8}>
                    {t('common.page')}{' '}
                    <Text fontWeight="bold" as="span">
                      {pagination.page}
                    </Text>{' '}
                    {t('common.of')}{' '}
                    <Text fontWeight="bold" as="span">
                      {pagination.totalPages}
                    </Text>
                  </Text>
                  <Text flexShrink={1} mr={8}>
                    {t('common.total')}
                    {': '}
                    <Text fontWeight="bold" as="span">
                      {pagination.total}
                    </Text>
                  </Text>
                  <Text flexShrink={1}>{t('common.goToPage')}:</Text>{' '}
                  <NumberInput
                    ml={2}
                    mr={8}
                    w={28}
                    min={1}
                    isInvalid={false}
                    max={pagination.totalPages || 1}
                    onChange={(value: any) => {
                      const page = value ? value : 0;
                      gotoPage(page);
                    }}
                    value={pagination.page || 1}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <Select
                    w={32}
                    value={pagination.limit}
                    onChange={e => {
                      gotoPage(pagination.page, parseInt(e.target.value));
                    }}
                    color={theme.tables.select.color}
                    backgroundColor={theme.tables.select.background}
                  >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        {t('common.show')} {pageSize}
                      </option>
                    ))}
                  </Select>
                </Flex>

                <Flex>
                  <Tooltip label={t('common.nextPage')}>
                    <IconButton
                      onClick={() => {
                        gotoPage(pagination.page + 1);
                      }}
                      isDisabled={pagination.page >= pagination.totalPages}
                      icon={<ChevronRightIcon h={6} w={6} />}
                      aria-label={''}
                      color={theme.tables.button.color}
                    />
                  </Tooltip>
                  <Tooltip label={t('common.lastPage')}>
                    <IconButton
                      onClick={() => gotoPage(pagination.totalPages)}
                      icon={<ArrowRightIcon h={3} w={3} />}
                      ml={4}
                      aria-label={''}
                      color={theme.tables.button.color}
                      isDisabled={pagination.page >= pagination.totalPages}
                    />
                  </Tooltip>
                </Flex>
              </Flex>
            )}
          </>
        )}
      {idTicket && !loading && (
        <MoreInfoTicket
          description={pagination.data[0].description}
          kleverResponse={pagination.data[0].kleverResponse}
          downloadUrl={pagination.data[0].fileBytes}
        />
      )}
    </Box>
  );
};

export default PaginatedTable;
