export const companyFields = {
    view: [
        { key: 'name', type: 'text', disabled: true },
        { key: 'email', type: 'email', disabled: true },
        { key: 'address', type: 'text', disabled: true },
        { key: 'apiUrl', type: 'text', disabled: true },
        {
            key: 'createdAt',
            type: 'text',
            disabled: true,
            isDatetime: true,
        },
        {
            key: 'updatedAt',
            type: 'text',
            disabled: true,
            isDatetime: true,
        },
    ],
    create: [
        { key: 'name', type: 'text', disabled: false, required: true },
        { key: 'email', type: 'email', disabled: false, required: true },
        { key: 'address', type: 'text', disabled: false, required: false },
        { key: 'apiUrl', type: 'text', disabled: false, required: true },
        {
            key: 'companyToken',
            type: 'text',
            disabled: false,
            required: true,
        },
        {
            key: 'kleverToken',
            type: 'text',
            disabled: false,
            required: true,
        },
    ],
    edit: [
        { key: 'name', type: 'text', disabled: false, required: true },
        { key: 'email', type: 'email', disabled: false, required: true },
        { key: 'address', type: 'text', disabled: false, required: false },
        { key: 'apiUrl', type: 'text', disabled: false, required: true },
    ],
};
