import {
  Checkbox,
  Drawer as ChakraDrawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  Select,
  Text,
  useDisclosure,
  UseDisclosureProps,
} from '@chakra-ui/react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { AppContext } from '../../contexts/AppContext';
import { useChangeCompany } from '../../hooks/ChangeCompany';
import { validationInputValues } from '../../utils/validationInputValues';
import Button from '../Button';
import { Loading } from '../Loading';
import { StyledDrawerContent as DrawerContent } from './styles';

interface IFields {
  key: string;
  type: string;
  disabled?: boolean;
  isDatetime?: boolean;
  selectOptions?: any;
  default?: boolean | string;
  enableOnCheck?: string;
  required?: boolean;
}

export interface IType {
  fields: IFields[];
  action: Function;
  validationSchema: any;
}

interface IDrawerProps {
  title: string;
  useDisclosureProps: UseDisclosureProps;
  data: any;
  type: IType;
}

const Drawer: React.FC<IDrawerProps> = ({
  title,
  useDisclosureProps,
  data,
  type,
}) => {
  const { isOpen, onClose } = useDisclosure(useDisclosureProps);
  const { t } = useTranslation('drawer');
  const theme = useTheme();
  const drawerBodyRef = useRef<HTMLDivElement>(null);
  const { userIsKlever } = useChangeCompany();

  const [isFetching, setIsFetching] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [reload, setReload] = useState(false);
  const [messageErroMap, setMessageErroMap] = useState<any>(false);

  const { setReloadPageDrawerClose } = useContext(AppContext);

  useEffect(() => {
    const initialValidation = async () => {
      const isValid = await type.validationSchema?.isValid(data);
      setIsValid(isValid);
    };
    initialValidation();
  }, [data]);

  const flushInputsValue = useCallback(() => {
    const inputs = drawerBodyRef.current?.querySelectorAll('input[type=text]');

    inputs?.forEach(inp => {
      inp.setAttribute('value', '');
    });
  }, []);

  const handleAction = async () => {
    setIsFetching(true);
    await type.action(data);
    flushInputsValue();
    setReloadPageDrawerClose(true);
    onClose();
    setReloadPageDrawerClose(false);
    setIsFetching(false);
  };

  const handleValue = (field: any, data: any) => {
    if (field.isDatetime) {
      return new Date(data[field.key]).toLocaleString();
    } else if (typeof data[field.key] === 'boolean') {
      return data[field.key] ? t('yes') : t('no');
    } else {
      return data[field.key];
    }
  };

  function showLabelKleverCompany({ type, key, required }: IFields) {
    const showLabel = userIsKlever || type !== 'select';
    if (showLabel && required) {
      return (
        <Text>
          {t(key)}
          <Text as="span" color="red">
            {' '}
            *
          </Text>
        </Text>
      );
    } else if (showLabel) {
      return <Text>{t(key)}</Text>;
    }
  }

  function handleCloseAndCleanErroMessage() {
    onClose();
    setMessageErroMap(false);
  }

  function noMessageError() {
    let arrayMessages = [];
    if (!!messageErroMap) {
      for (let key in messageErroMap) {
        arrayMessages.push(messageErroMap[key]);
      }
    }
    return arrayMessages.some(e => typeof e === 'string');
  }

  return (
    <>
      <ChakraDrawer
        isOpen={isOpen}
        placement="right"
        onClose={() => handleCloseAndCleanErroMessage()}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />

          <DrawerHeader>{title}</DrawerHeader>

          <DrawerHeader>{title}</DrawerHeader>
          <DrawerBody ref={drawerBodyRef}>
            {type.fields.map((field, i) => (
              <span key={String(i)}>
                {field.type !== 'checkbox' && (
                  <FormLabel
                    display={'flex'}
                    gap={'1'}
                    flexDirection={'row'}
                    mt="1.25rem"
                  >
                    {showLabelKleverCompany(field)}
                  </FormLabel>
                )}

                <InputGroup gap="3rem">
                  {userIsKlever && field.type === 'select' && (
                    <Select
                      mb={2}
                      key={String(i)}
                      defaultValue={String(field.default || data[field.key])}
                      isDisabled={data.isKleverAdmin}
                      name={field.key}
                      color={theme.tables.select.color}
                      backgroundColor={theme.tables.select.background}
                      onChange={async e => {
                        switch (field.key) {
                          case 'companyId':
                            data[field.key] = Number(e.target.value);
                            setReload(!reload);
                            break;
                          case 'status':
                            data[field.key] = e.target.value;
                            setReload(!reload);
                            break;
                        }

                        const allFieldsRequired =
                          await type.validationSchema.isValid(data);
                        setIsValid(allFieldsRequired);
                      }}
                    >
                      {field.selectOptions.map((option: any, i: any) => (
                        <option key={i} value={String(option.value)}>
                          {option.name}
                        </option>
                      ))}
                    </Select>
                  )}

                  {['text', 'email'].includes(field.type) && (
                    <>
                      <FormControl
                        isInvalid={
                          typeof messageErroMap[field.key] === 'string'
                        }
                      >
                        <Input
                          name={field.key}
                          type={field.type}
                          disabled={field.disabled}
                          defaultValue={handleValue(field, data)}
                          onChange={async e => {
                            data[field.key] = e.target.value;

                            const allFieldsRequired =
                              await type.validationSchema.isValid(data);
                            setIsValid(allFieldsRequired);

                            const messageError = await validationInputValues(
                              field.key,
                              data[field.key],
                            );

                            data[field.key] !== '' &&
                              setMessageErroMap((prev: any) => ({
                                ...prev,
                                [field.key]: messageError,
                              }));
                            setReload(!reload);
                          }}
                        />
                        <FormErrorMessage>
                          {t(`messageError.${messageErroMap[field.key]}`)}
                        </FormErrorMessage>
                      </FormControl>
                    </>
                  )}

                  {userIsKlever && field.type === 'checkbox' && (
                    <Checkbox
                      mb="1rem"
                      mt="2.5rem"
                      name={field.key}
                      defaultChecked={
                        field.default !== undefined ? !!data[field.key] : false
                      }
                      onChange={async e => {
                        if (e.target.checked) {
                          data[field.key] = true;
                          data.companyId = 1;
                        } else {
                          data[field.key] = false;
                          delete data.companyId;
                        }
                        setReload(!reload);
                        const isValid = await type.validationSchema.isValid(
                          data,
                        );
                        setIsValid(isValid);
                      }}
                    >
                      {t(field.key)}
                    </Checkbox>
                  )}
                </InputGroup>
              </span>
            ))}

            <Text fontSize={'sm'} color={'grey'} mt="2.5rem">
              {t('mandatoryAlert')}
            </Text>
          </DrawerBody>

          {type.action.name !== 'view' && (
            <DrawerFooter>
              <Button
                data-cy="drawer-button__cancel"
                buttonType="outlined"
                mr={3}
                onClick={() => handleCloseAndCleanErroMessage()}
              >
                {t('buttons.cancel')}
              </Button>

              <Button
                data-cy="drawer-button__save"
                buttonType="primary"
                onClick={() => handleAction()}
                disabled={!isValid || isFetching || noMessageError()}
              >
                {isFetching ? (
                  <Loading margin="0" color="white" />
                ) : (
                  t('buttons.save')
                )}
              </Button>
            </DrawerFooter>
          )}
        </DrawerContent>
      </ChakraDrawer>
    </>
  );
};

export default Drawer;
