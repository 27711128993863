import {
  BoundingBox,
  Buildings,
  CurrencyDollar,
  Info,
  SignOut,
  Ticket,
} from 'phosphor-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SIDEBAR_CHANGE_STATUS_EVENT,
  SIDEBAR_CLOSE_EVENT,
  SIDEBAR_OPEN_EVENT,
} from '../../constants/events';
import { useAuth } from '../../hooks/Auth';
import { useChangeCompany } from '../../hooks/ChangeCompany';
import { ISidebarItemProps } from '../../interfaces/Common';
import NavBarHorizontal from './NavbarHorizontal';
import { Sidebar } from './Sidebar';
import { StyledBox } from './styles';

const Nav: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { userIsKlever } = useChangeCompany();
  const { logoutUser } = useAuth();

  const { t, i18n } = useTranslation('navbar');

  const GLOBAL_LINKS: ISidebarItemProps[] = [
    {
      title: t('menu_items.manageDeposit.title'),
      to: '/manage',
      icon: Ticket,
    },
    {
      title: 'Info',
      to: '/',
      icon: Info,
      items: [
        {
          title: t('menu_items.auditLogs.title'),
          to: '/logs',
        },
        {
          title: t('menu_items.admins.title'),
          to: '/user',
        },
      ],
    },
  ];

  const ADMIN_LINKS: ISidebarItemProps[] = [
    ...GLOBAL_LINKS,
    {
      title: t('menu_items.companies.title'),
      to: '/company',
      icon: Buildings,
    },
    {
      title: t('menu_items.logout.title'),
      icon: SignOut,
      to: '#',
      onClick: logoutUser,
    },
  ];

  const COMPANY_LINKS: ISidebarItemProps[] = [
    // {
    //   title: 'Dashboard',
    //   to: '/dashboard',
    //   icon: SquaresFour,
    // },
    {
      title: t('menu_items.statement.title'),
      to: '/statement',
      icon: CurrencyDollar,
    },
    // {
    //   title: t('menu_items.billing.title'),
    //   to: '/billing',
    //   icon: Money,
    // },
    ...GLOBAL_LINKS,

    {
      title: t('menu_items.blockchain.title'),
      to: '/balance',
      icon: BoundingBox,
      items: [
        {
          title: t('menu_items.blockchain.items.balance.title'),
          to: '/balance',
        },
        {
          title: t('menu_items.blockchain.items.networks.title'),
          to: '/networks',
        },
        {
          title: t('menu_items.blockchain.items.tokens.title'),
          to: '/tokens',
        },
        {
          title: 'Tokens-Networks',
          to: '/tn',
        },
        // {
        //   title: t('menu_items.networkToken.title'),
        //   to: '/create-network-token',
        // },
      ],
    },
    {
      title: t('menu_items.logout.title'),
      icon: SignOut,
      to: '#',
      onClick: logoutUser,
    },
  ];

  const menuItems = useMemo(
    () => (userIsKlever ? ADMIN_LINKS : COMPANY_LINKS),
    [userIsKlever, i18n.language],
  );

  const handleSetIsOpen = useCallback(() => {
    setIsOpen(prev => !prev);

    document.dispatchEvent(new Event(SIDEBAR_CLOSE_EVENT));
    document.dispatchEvent(
      new CustomEvent(SIDEBAR_CHANGE_STATUS_EVENT, {
        detail: {
          isOpen,
        },
      }),
    );
  }, [isOpen]);

  useEffect(() => {
    const setNavbarOpen = () => {
      setIsOpen(true);
    };

    document.addEventListener(SIDEBAR_OPEN_EVENT, setNavbarOpen);

    return () => {
      document.removeEventListener(SIDEBAR_OPEN_EVENT, setNavbarOpen);
    };
  }, []);

  return (
    <>
      <NavBarHorizontal isOpen={isOpen} onClick={handleSetIsOpen} />
      <StyledBox h={'100vh'} position={'fixed'} zIndex={10} isOpen={isOpen}>
        <Sidebar data={menuItems} />
      </StyledBox>
    </>
  );
};

export default Nav;
