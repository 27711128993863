import { StyledInput, StyledInputModal, StyledInputMyAccount } from './styles';

const Input: React.FC<any> = ({ inputType, ...props }) => {
    switch (inputType) {
        case 'modal':
            return <StyledInputModal {...props} />;
        case 'account':
            return <StyledInputMyAccount {...props} />;
        default:
            return <StyledInput {...props} />;
    }
};

export default Input;
