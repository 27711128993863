import {
  BaseSyntheticEvent,
  ChangeEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Select,
  Textarea,
  useToast,
  Text,
  Icon,
  FormErrorMessage,
  Flex,
} from '@chakra-ui/react';
import { MdSend } from 'react-icons/md';
import { CgAsterisk } from 'react-icons/cg';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import BoxSection from '../../BoxSection';
import Button from '../../Button';
import Input from '../../Input';
import api, { Service } from '../../../services/api';
import ErrorHandler from '../../../utils/errorHandler';

import { LIMIT_CHARACTERS, TYPE_CHAIN, TYPE_OPTIONS } from '../../../constants';

import { IPropsCreateTickets } from '../../../interfaces/Support';

export default function CreateTicketModal(props: IPropsCreateTickets) {
  const { t } = useTranslation('support');
  const theme = useTheme();
  const toast = useToast();
  const [textAreaValues, setTextAreaValue] = useState('');
  const [countCharacters, setCountCharacters] = useState<number>(0);
  const [chainInput, setChainInput] = useState<number | string>('');
  const [selectedValue, setSelectedValue] = useState('');
  const [transactionID, setTransactionID] = useState('');
  const [uploadFile, setUploadFile] = useState<any>([]);
  const [fileBase64String, setFileBase64String] = useState<any>('');

  const isDisableField = !['deposit', 'withdraw'].includes(selectedValue);

  const mb = 1024 ** 2;
  const maxSizeFile = 3;
  const fileExceedSize =
    uploadFile?.size !== undefined && uploadFile.size > mb * maxSizeFile;

  const isNotDepositOrWithdraw = useMemo(
    () => !['deposit', 'withdraw'].includes(selectedValue),
    [selectedValue],
  );

  useEffect(() => {
    setCountCharacters(Number(textAreaValues.length));
  }, [textAreaValues]);

  function disabledButton(): boolean {
    if (isNotDepositOrWithdraw) {
      return selectedValue === '' || textAreaValues === '' || fileExceedSize;
    } else {
      return (
        selectedValue === '' ||
        chainInput === '' ||
        transactionID === '' ||
        fileExceedSize
      );
    }
  }

  function cleaningFiedls(e: BaseSyntheticEvent) {
    setTextAreaValue('');
    setTransactionID('');
    setChainInput('');
    setSelectedValue('');
    setUploadFile([]);
    setFileBase64String('');
    e.target.reset();
  }

  useEffect(() => {
    (function (file: any) {
      let reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64 = reader.result;
          setFileBase64String(base64);
        };
        reader.onerror = error => {
          console.log('error', error);
        };
      }
    })(uploadFile[0]);
  }, [uploadFile]);

  async function handleSubmit(e: BaseSyntheticEvent) {
    e.preventDefault();

    const body = {
      type: selectedValue,
      chain: chainInput === '' ? -1 : Number(chainInput),
      transactionId: transactionID,
      description: textAreaValues,
      ...(uploadFile.length !== 0 && { file: await fileBase64String }),
    };

    const response = await api.post({
      route: 'support-tickets',
      service: Service.KRYPTO_BANKING,
      apiVersion: 'v1',
      body,
    });

    cleaningFiedls(e);

    if (response?.message) {
      return toast({
        title: t('common:toasts.titles.error'),
        description: ErrorHandler('adminAdministrators', response.message),
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } else {
      props.handleReload?.();

      return toast({
        title: t('common:toasts.titles.success'),
        description: t('common:toasts.body.successSent'),
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    }
  }

  return (
    <BoxSection m="auto" width="100%" mt={1}>
      <Heading
        as="h3"
        size="lg"
        textAlign={'center'}
        color={theme.common.lightText}
        mb={5}
      >
        {t('create_ticket')}
      </Heading>
      <Flex as="form" flexDirection={'column'} onSubmit={handleSubmit}>
        <FormControl isRequired>
          <FormLabel htmlFor="type" color={theme.common.lightText}>
            {t('form.fields.type')}
          </FormLabel>
          <Select
            value={selectedValue}
            id="type"
            data-testid="typeModalTest"
            placeholder={t('form.fields.selectDefaultTypeOption')}
            bg={theme.input.background}
            onChange={e => setSelectedValue(e.target.value)}
            isRequired
          >
            {TYPE_OPTIONS.map(type => (
              <option value={type} key={type} data-testid={type}>
                {t(`options.types.${type}`)}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl isRequired={!isDisableField}>
          <FormLabel htmlFor="chain" color={theme.common.lightText} mt={4}>
            {t('form.fields.chain')}
          </FormLabel>

          <Select
            value={chainInput}
            id="chain"
            data-testid="typeChainTest"
            placeholder={t('form.fields.selectDefaultOption')}
            bg={theme.input.background}
            onChange={e => setChainInput(e.target.value)}
            isDisabled={isDisableField}
            isRequired
          >
            {TYPE_CHAIN.map(chain => (
              <option
                value={chain.chain_value}
                key={chain.type}
                data-testid={chain.type}
              >
                {chain.type}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl isRequired={!isDisableField}>
          <FormLabel
            htmlFor="transaction_id"
            color={theme.common.lightText}
            mt={4}
          >
            {t('form.fields.transactionId')}
          </FormLabel>
          <Input
            inputType="modal"
            id="transaction_id"
            data-testid="transactionIdTest"
            type="text"
            bg={theme.input.background}
            mb={0}
            isDisabled={isDisableField}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setTransactionID(e.target.value)
            }
            value={transactionID}
          />
        </FormControl>

        <FormControl isInvalid={fileExceedSize}>
          <FormLabel htmlFor="uploadFile" color={theme.common.lightText} mt={4}>
            {t('form.fields.uploadFile')}
          </FormLabel>
          <Input
            inputType="modal"
            id="uploadFile"
            data-testid="uploadFile"
            type="file"
            mb={0}
            bg={theme.common.lightText}
            onChange={(e: BaseSyntheticEvent) => setUploadFile(e.target.files)}
          />
          {!fileExceedSize ? (
            <FormHelperText fontStyle={'italic'} fontSize={12} mt={2}>
              {t('form.fields.maxSizeLimit')}
            </FormHelperText>
          ) : (
            <FormErrorMessage mt={2}>{`File size ${(
              uploadFile.size / mb
            ).toFixed(
              2,
            )} MB exceeds the maximum size of ${maxSizeFile} MB`}</FormErrorMessage>
          )}
        </FormControl>

        <FormControl isRequired={isNotDepositOrWithdraw}>
          <FormLabel
            htmlFor="description"
            color={theme.common.lightText}
            mt={4}
          >
            {t('form.fields.description')}
          </FormLabel>
          <Textarea
            data-testid="description"
            value={textAreaValues}
            bg={theme.input.background}
            maxLength={LIMIT_CHARACTERS}
            onChange={e => setTextAreaValue(e.target.value)}
          />
          <FormHelperText textAlign="right">
            {countCharacters}/{LIMIT_CHARACTERS}
          </FormHelperText>
        </FormControl>

        <Text color="red.500">
          <Icon as={CgAsterisk} />
          {t('form.required')}
        </Text>

        <Button
          mt={5}
          rightIcon={<MdSend />}
          type="submit"
          isDisabled={disabledButton()}
        >
          {t('form.sendButton')}
        </Button>
      </Flex>
    </BoxSection>
  );
}
