import { Heading } from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import BoxSection from '../../components/BoxSection';
import BoxStyled from '../../components/BoxStyled';
import Container from '../../components/Container';
import Filter from '../../components/Filter';
import Nav from '../../components/Navbar';
import PaginatedTable from '../../components/PaginatedTable';

interface FiltersProps {
  fromAddress: string;
  toAddress: string;
  operation: string;
  txId: string;
  tokenId: string;
  nftId: string;
  amount: string;
  fromDate: Date;
  toDate: Date;
}

const Statement: React.FC = () => {
  const { t } = useTranslation('statement');
  const theme = useTheme();
  const [filters, setFilters] = useState<Partial<FiltersProps>>(
    {} as FiltersProps,
  );

  const filterFields = useMemo(
    () => [
      {
        title: t('filters.fromAddress'),
        key: 'fromAddress',
        type: 'text',
      },
      {
        title: t('filters.toAddress'),
        key: 'toAddress',
        type: 'text',
      },
      {
        title: t('filters.operation'),
        key: 'operation',
        type: 'select',
        defaultValue: {
          key: '',
          title: 'All',
        },
        selectValues: [
          {
            key: 'DEPOSIT',
            title: 'Deposit',
          },
          {
            key: 'WITHDRAW',
            title: 'Withdraw',
          },
          {
            key: 'INTERNAL_TRANSFER',
            title: 'Internal Transfer',
          },
        ],
      },
      {
        title: t('filters.txId'),
        key: 'txId',
        type: 'text',
      },
      {
        title: t('filters.nftId'),
        key: 'nftId',
        type: 'text',
      },
      {
        title: t('filters.tokenId'),
        key: 'tokenId',
        type: 'text',
      },
      {
        title: t('filters.amount'),
        key: 'amount',
        type: 'text',
      },
      {
        title: t('filters.fromDate'),
        key: 'fromDate',
        type: 'date',
      },
      {
        title: t('filters.toDate'),
        key: 'toDate',
        type: 'date',
      },
    ],
    [t],
  );

  const handleFilters = useCallback((e: object) => {
    setFilters(e);
  }, []);

  return (
    <Container>
      <Nav />
      <BoxStyled>
        <BoxSection>
          <Heading
            textAlign={'center'}
            color={theme.common.lightText}
            mb="2rem"
          >
            {t('title')}
          </Heading>

          <Filter
            onSubmit={handleFilters}
            translation={'statement'}
            fields={filterFields}
          />
        </BoxSection>
        <PaginatedTable
          endpoint="statements"
          headers={[
            'fromAddress',
            'toAddress',
            'operation',
            'status',
            'amount',
            'createdAt',
          ]}
          ns="statementsTable"
          filters={filters}
          hiddenDetails={[
            'id',
            'toAccountId',
            'txId',
            'tokenId',
            'nftId',
            'error',
            'updatedAt',
          ]}
          withCryptoIcon
        />
      </BoxStyled>
    </Container>
  );
};

export default Statement;
