import React from 'react';
import { FormControl, FormLabel, HStack, Text, Tooltip } from "@chakra-ui/react";
import { Info } from "phosphor-react";
import { useTranslation } from "react-i18next";
import Select from '../../../Select';
import { useTheme } from 'styled-components';

interface IData {
  id: number
  name: string
}

interface ISelect {
  props:{
    labelName: string;
    nameSelect: string;
    defaultValue?: boolean;
    hasTooltip?: boolean;
    tooltipText?: string;
  },
  list: IData[];
}

const SelectForm: React.FC<any> = ({props, list}: ISelect) => {
  const { t } = useTranslation('createNetworkToken');
  const theme = useTheme();
  return (
    <FormControl>
      <FormLabel color={theme.common.lightText}>
        <HStack>
          <Text>{props.labelName}</Text>
          {props.hasTooltip &&
          <Tooltip label={t(`token.tooltip.${props.tooltipText}`)}>
            {<Info size={20}  weight="fill"/>}  
          </Tooltip>}
        </HStack>
      </FormLabel>
      <Select name={props.nameSelect} required>
        {props.defaultValue && 
          <option key={0} value={0}>
            Current token
          </option>}
        {list?.map((element) => (
          <option key={element.id} value={element.id}>
            {element.name}
          </option>
        ))}
      </Select>
    </FormControl>
  )
}

export default SelectForm;