import styled from 'styled-components';
import { Input as ChakraInput } from '@chakra-ui/react';

export const StyledInput = styled(ChakraInput)`
    background-color: white !important;
    color: ${props => props.theme.input.color};
    margin-bottom: 15px;
    height: 32px !important;

    &:focus {
        border: 1px solid ${props => props.theme.input.focus.text} !important;
        box-shadow: 0 0 5px ${props => props.theme.input.focus.text} !important;
    }

    &[type='date'] {
        font-size: 1.1rem;
    }

    &[type='date'],
    focus {
        color: ${props => props.theme.input.focus.date};
    }
`;

export const StyledInputModal = styled(ChakraInput)`
    background-color: white !important;
    color: ${props => props.theme.input.color};
    margin-bottom: 15px !important;
    padding-right: 0 !important;
    height: 32px !important;
    border: none !important;
`;

export const StyledInputMyAccount = styled(ChakraInput)`
    background-color: white !important;
    color: ${props => props.theme.input.color};
    margin-bottom: 15px;
    padding-left: 35px !important;
    height: 32px !important;
`;
