import { AnimatePresence, motion, Variants } from 'framer-motion';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Title } from './Title';
import {
  SIDEBAR_CHANGE_STATUS_EVENT,
  SIDEBAR_CLOSE_EVENT,
  SIDEBAR_OPEN_EVENT,
} from '../../../../constants/events';
import { ISidebarItemProps } from '../../../../interfaces/Common';
import { verifyActiveMenu } from '../../../../utils/verifyActiveMenu';
import { SubMenuItem } from './SubMenuItem';

import { SidebarLink, Container } from './styles';

const variants: Variants = {
  open: {
    height: 'auto',
    opacity: 1,
    transition: {
      type: 'spring',
    },
  },
  closed: {
    height: 0,
    opacity: 0,
    transition: {
      duration: 0.2,
      ease: 'easeInOut',
    },
  },
};

export function WithSubmenu({
  title,
  to,
  icon: Icon,
  items,
}: ISidebarItemProps) {
  const [isOpen, setIsOpen] = useState(false);
  const isNavOpen = useRef(false);

  const handleOpen = useCallback(() => {
    setIsOpen(prev => !prev);

    if (!isNavOpen.current && !isOpen) {
      document.dispatchEvent(new Event(SIDEBAR_OPEN_EVENT));
    }
  }, [isOpen]);

  const verifyChildrenIsActive = useCallback(() => {
    items?.forEach(item => {
      if (!verifyActiveMenu(item.to)) return;
      if (!isNavOpen.current) return;

      setIsOpen(true);
    });
  }, [items, isNavOpen.current]);

  useEffect(() => {
    verifyChildrenIsActive();
  }, [isNavOpen.current]);

  useEffect(() => {
    const closeSidebar = () => {
      setIsOpen(false);
    };

    const changeStatus = (e: any) => {
      const data: CustomEvent = e as CustomEvent;
      const { isOpen } = data.detail;

      isNavOpen.current = !isOpen;
    };

    document.addEventListener(SIDEBAR_CLOSE_EVENT, closeSidebar);
    document.addEventListener(SIDEBAR_CHANGE_STATUS_EVENT, changeStatus);

    return () => {
      document.removeEventListener(SIDEBAR_CLOSE_EVENT, closeSidebar);
      document.removeEventListener(SIDEBAR_CHANGE_STATUS_EVENT, changeStatus);
    };
  }, []);

  return (
    <Container onClick={handleOpen} id="submenu-container">
      <Title icon={Icon} title={title} hasSubmenu isOpen={isOpen} />

      <AnimatePresence exitBeforeEnter>
        {isOpen &&
          items?.map(({ title: subItem, to: subTo }, index) => {
            const isActive = verifyActiveMenu(subTo);

            return (
              <motion.span
                variants={variants}
                initial="closed"
                exit="closed"
                animate="open"
              >
                <SidebarLink to={subTo} isActive={isActive} key={subItem}>
                  <SubMenuItem title={subItem} />
                </SidebarLink>
              </motion.span>
            );
          })}
      </AnimatePresence>
    </Container>
  );
}
