import styled from 'styled-components';

export const SectionTitle = styled.div`
  color: rgb(74, 85, 104);
  font-size: 22px;
  font-weight: bold;
  box-sizing: border-box;
  line-height: 22px;
  margin: 0.2rem 5%;
`;

export const TitleModifiedCenter = styled(SectionTitle)`
  margin: 0.2rem auto;
  text-align: center;
`;

interface Props {
  gridName: string;
}

export const ContainerSection = styled.div<Props>`
  grid-area: ${props => props.gridName};
  background-color: ${props => props.theme.chart.background};
  border-radius: 20px;
  padding: 10px;

  display: flex;
  flex-direction: column;
`;

export const ChartContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

export const ChartContainerModifiedHeight = styled(ChartContainer)`
  height: 150px;

  @media only screen and (max-width: 475px) {
    & > div:first-of-type {
      height: 75% !important;
    }
  }

  @media only screen and (max-width: 400px) {
    & > div:first-of-type {
      height: 65% !important;
    }
  }
`;

export const SelectGroup = styled.div`
  display: flex;
  gap: 30px;
  align-self: center;
  align-items: center;
  justify-content: center;

  select,
  .datePicker input {
    width: 80px;
    background-color: ${props => props.theme.chart.background};
    border-bottom: 2px solid #aaadc4;
    color: ${props => props.theme.chart.text};
    text-align: center;
    cursor: pointer;

    &:focus {
      border-bottom: 2px solid #656775;
      outline: none;
    }
  }

  .datePicker {
    margin-top: 13px;
  }

  .datePicker input,
  .datePicker {
    width: 200px;
  }
`;

export const ContainerStatsColumns = styled.div`
  display: grid;
  grid-template-areas:
    'miniChartDeposit miniChartWithdrawals miniChartInterTransfer depositUSS      openedTickets'
    'infoDeposit      infoWithdrawals       infoInterTransfer     withdrawalsUSS  topCrypto';
  grid-template-columns: repeat(5, minmax(100px, calc(100% / 5)));
  grid-template-rows: 1fr 1fr;
  gap: 1rem 1.5rem;
  margin: 0 5%;
  justify-items: center;
  align-items: center;

  @media only screen and (max-width: 1620px) {
    grid-template-areas:
      'miniChartDeposit miniChartWithdrawals  miniChartInterTransfer'
      'infoDeposit      infoWithdrawals       infoInterTransfer'
      'depositUSS       openedTickets         withdrawalsUSS';
    grid-template-columns: repeat(3, minmax(100px, calc(100% / 3)));
    grid-template-rows: 1fr 1fr 1fr;
  }

  @media only screen and (max-width: 1000px) {
    grid-template-areas:
      'miniChartDeposit         infoDeposit'
      'miniChartWithdrawals     infoWithdrawals'
      'miniChartInterTransfer   infoInterTransfer'
      'depositUSS               withdrawalsUSS';
    grid-template-columns: repeat(2, minmax(100px, calc(100% / 2)));
    grid-template-rows: 1fr 1fr 1fr 1fr;
    div:nth-child(10),
    div:nth-child(9) {
      display: none;
    }
  }
`;

export const ColumnStats = styled.div<Props>`
  grid-area: ${props => props.gridName};
  width: 170px;

  .column__mini-chart {
    height: 60px;

    @media only screen and (max-width: 475px) {
      width: 150px;
    }
  }

  .column__content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .column__circle {
    border-radius: 100%;
    height: 50px;
    width: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .column__info {
    display: flex;
    color: ${props => props.theme.chart.text};
    margin-left: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    flex-grow: 1;

    & > span:nth-child(1) {
      font-size: 20px;
      font-weight: bold;
    }

    & > span:nth-child(2) {
      font-size: 13px;
      display: inline-block;
      text-align: center;
    }
  }

  .deposits {
    background-color: rgb(123, 151, 120);
    & svg {
      color: rgb(177, 236, 177);
      font-size: 30px;
      font-weight: 400;
    }
  }

  .withdrawals {
    background-color: rgb(240, 140, 174);
    & svg {
      color: rgb(247, 180, 202);
      font-size: 30px;
      font-weight: 400;
    }
  }

  .internalTransfers {
    background-color: rgb(140, 150, 240);
    & svg {
      color: rgb(207, 207, 248);
      font-size: 30px;
      font-weight: 400;
    }
  }

  .others {
    background-color: rgb(170, 173, 196);
    & svg {
      color: rgb(100, 106, 144);
      font-size: 30px;
      font-weight: 400;
    }
  }

  @media only screen and (max-width: 1620px) {
    display: ${props => (props.gridName === 'topCrypto' ? 'none' : '')};
  }
`;
