export const formatNumber = (
  value: number,
  options?: Intl.NumberFormatOptions,
) => {
  if (typeof value !== 'number') return value;

  const fmtOptions = options ?? { minimumFractionDigits: 2 };

  const fmtValue = new Intl.NumberFormat('en-US', fmtOptions).format(value);

  return fmtValue;
};

export const formatDate = (value: string | number) => {
  const parsedValue = typeof value === 'number' ? value * 1000 : value;

  const fmtDate = new Date(parsedValue).toLocaleDateString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });

  return fmtDate;
};

export const formatCurrency = (value: number) => {
  if (typeof value !== 'number') return value;

  const fmtValue = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value);

  return fmtValue;
};

export const truncateString = (value: string, start = 0, end = 5) => {
  if (!value?.length || value?.length < end) return value;

  const leftSlice = value.slice(start, end);
  const rightSlice = value.slice(end * -1, value.length);

  return `${leftSlice}...${rightSlice}`;
};
