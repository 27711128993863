import {
  Flex,
  Heading,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import Container from '../../../components/Container';
import Nav from '../../../components/Navbar';
import { Content } from './styles';

const LazyUserTab = lazy(
  () => import('../../../components/Tabs/UserProfileTab'),
);

const LazySessionsTab = lazy(() => import('../../../components/Tabs/Sessions'));

const UserAccount: React.FC<any> = () => {
  const { t } = useTranslation('companyAccount');
  const theme = useTheme();

  const tabs = [
    {
      id: 'profile',
      title: t('tabs.profile.title'),
      component: <LazyUserTab />,
    },
    {
      id: 'sessions',
      title: t('tabs.sessions.title'),
      component: <LazySessionsTab />,
    },
  ];

  return (
    <Container>
      <Nav />

      <Flex w={500} h={550} m="auto">
        <Content>
          <Heading size="lg">{t('title')}</Heading>

          <Tabs mt={4}>
            <TabList>
              {tabs.map(tab => (
                <Tab
                  key={tab.id}
                  _selected={{
                    borderColor: theme.tabs.selected,
                  }}
                >
                  {tab.title}
                </Tab>
              ))}
            </TabList>

            <TabPanels>
              {tabs.map(tab => (
                <TabPanel key={tab.id}>
                  <Suspense fallback={<Spinner />}>{tab.component}</Suspense>
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Content>
      </Flex>
    </Container>
  );
};

export default UserAccount;
