export const CHAINS = [
  { id: 1, name: 'TRX' },
  { id: 2, name: 'BTC' },
  { id: 3, name: 'ETH' },
  { id: 4, name: 'XRP' },
  { id: 5, name: 'LTC' },
  { id: 6, name: 'XLM' },
  { id: 7, name: 'COSMOS' },
  { id: '7', name: 'COSMOS' },
  { id: '8', name: 'EOS' },
  { id: '9', name: 'ONT' },
  { id: '10', name: 'KLAY' },
  { id: '11', name: 'DASH' },
  { id: '12', name: 'DOGE' },
  { id: '13', name: 'IOTX' },
  { id: '14', name: 'ONE' },
  { id: '15', name: 'SYS' },
  { id: '16', name: 'DGB' },
  { id: '17', name: 'BNB' },
  { id: '18', name: 'BCH' },
  { id: '19', name: 'VET' },
  { id: '20', name: 'ADA' },
  { id: '21', name: 'DOT' },
  { id: '22', name: 'XMR' },
  { id: '23', name: 'XTZ' },
  { id: '24', name: 'EGLD' },
  { id: '25', name: 'NEO' },
  { id: '26', name: 'BSC' },
  { id: '27', name: 'KSM' },
  { id: '28', name: 'POLYGON' },
  { id: '29', name: 'REEF' },
  { id: '30', name: 'HT' },
  { id: '31', name: 'ICP' },
  { id: '32', name: 'MOVR' },
  { id: '33', name: 'TKLV' }, // 33
  { id: '34', name: 'GLMR' },
  { id: '35', name: 'SDN' }, // 35
  { id: '36', name: 'ASTR' },
  { id: '37', name: 'SYS_NEVM' },
  { id: '38', name: 'KLV' },
];
