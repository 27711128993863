import { Box, Flex, Heading, useToast, Text } from '@chakra-ui/react';
import { BaseSyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import Container from '../../components/Container';
import Nav from '../../components/Navbar';
import api, { Service } from '../../services/api';
import Button from '../../components/Button';
import BoxSection from '../../components/BoxSection';
import ErrorHandler from '../../utils/errorHandler';
import Input from '../../components/Input';
import { Loading } from '../../components/Loading';
import BillingTable from './BillingTable';
import { calculationResults } from '../../utils/calculationResults';
import BoxStyled from '../../components/BoxStyled';

interface IResponse {
  deposits: number;
  withdrawals: number;
  internalTransfers: number;
}

interface ICalculationValues {
  depositCost: number;
  withdrawCost: number;
  internalTransferCost: number;
  totalCost: number;
}

interface IInputValues {
  from: string;
  to: string;
}

const Billing: React.FC<any> = () => {
  const [inputValues, setInputValues] = useState({
    from: '',
    to: '',
    fromTime: '00:00:00',
    toTime: '23:59:59',
  });
  const [disableButton, setDisableButton] = useState(false);
  const [response, setResponse] = useState<IResponse>();
  const [loading, setLoading] = useState(false);
  const [calculationValues, setCalculationValues] =
    useState<ICalculationValues>();
  const toast = useToast();
  const { t } = useTranslation('billing');
  const theme = useTheme();
  const fee = Number(process.env.REACT_APP_KLV_FEE_COSTING);
  const percentage = Number(process.env.REACT_APP_PERCENTAGE_BILLING);
  const preCalculation = fee * (percentage / 100);

  const fiedsInputs = [
    {
      key: 'fromDate',
      label: t('labels.fromDate'),
      type: 'date',
    },
    {
      key: 'toDate',
      label: t('labels.toDate'),
      type: 'date',
    },
    {
      key: 'fromTime',
      label: t('labels.fromTime'),
      type: 'time',
      value: '00:00:00',
      step: '1',
    },
    {
      key: 'toTime',
      label: t('labels.toTime'),
      type: 'time',
      value: '23:59:59',
      step: '1',
    },
  ];

  interface IKeysAndValuesInputs {
    [key: string]: string;
  }

  async function requestAPI(query: IKeysAndValuesInputs) {
    let response = await api.get({
      route: 'statements/transactions',
      service: Service.KRYPTO_BANKING,
      apiVersion: 'v1',
      query,
    });

    if (Object.keys(response).length === 0) {
      response = { deposits: 0, withdrawals: 0, internalTransfers: 0 };
    }

    setResponse(response);
    setCalculationValues(calculationResults({ ...response, preCalculation }));
    setLoading(false);
    if (response?.message) {
      return toast({
        title: 'Error',
        description: response.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  }

  function handleInputValues({ target }: BaseSyntheticEvent) {
    setInputValues(prev => ({ ...prev, [target.name]: target.value }));
  }

  useEffect(() => {
    setDisableButton(inputValues.from === '' || inputValues.to === '');
  }, [inputValues]);

  const handleSubmit = (event: BaseSyntheticEvent) => {
    event.preventDefault();
    setLoading(true);
    requestAPI(inputValues);
  };

  const BillingTableProps = {
    dateFrom: inputValues?.from,
    dateTo: inputValues?.to,
    amountDeposits: response?.deposits,
    amountWithdrawals: response?.withdrawals,
    amountInternalTransfers: response?.internalTransfers,
    calculationValues,
    fee,
    percentage,
  };

  return (
    <Container>
      <Nav />
      <BoxStyled>
        <BoxSection>
          <Heading
            textAlign={'center'}
            position="relative"
            color={theme.common.lightText}
            mb="2rem"
          >
            {t('title')}
          </Heading>
          <Flex as="form" flexDirection={'column'} onSubmit={handleSubmit}>
            <Box
              display="grid"
              gridTemplateColumns={['1fr', '1fr', '1fr 1fr']}
              gap="0 30px"
            >
              {fiedsInputs.map(({ key, type, label, step, value }) => (
                <Box key={key}>
                  <Text color={'white'} mb="1">
                    {label}
                  </Text>
                  <Input
                    onChange={(event: BaseSyntheticEvent) =>
                      handleInputValues(event)
                    }
                    name={key}
                    type={type}
                    step={step}
                    defaultValue={value}
                  />
                </Box>
              ))}
            </Box>
            <Box
              display="grid"
              gridTemplateColumns={['1fr', '1fr', '1fr 1fr']}
              gap="0 30px"
            >
              <Button
                type="submit"
                mt="1rem"
                buttonType="primary"
                flexGrow={1}
                disabled={disableButton}
              >
                {t('buttons.show')}
              </Button>
              <Button mt="1rem" buttonType="outlined" type="reset">
                {t('buttons.clean')}
              </Button>
            </Box>
          </Flex>
        </BoxSection>
      </BoxStyled>
      <BoxStyled>
        <BoxSection>
          {loading && <Loading />}
          {!!response && <BillingTable {...BillingTableProps} />}
        </BoxSection>
      </BoxStyled>
    </Container>
  );
};

export default Billing;
