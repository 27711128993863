import { Button, Th, Tooltip } from '@chakra-ui/react';
import { CheckCircle } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

interface IViewAction {
  checkAction: Function;
  log: any;
}

export default function CheckAction({ checkAction, log }: IViewAction) {
  const { t } = useTranslation('tables');
  const theme = useTheme();

  return (
    <Th
      onClick={async () => {
        await checkAction(log);
      }}
    >
      <Tooltip label={t(`common.checkDeposit`)}>
        <Button
          color={theme.form.color}
          fontSize={14}
          variant={'ghost'}
          _hover={{ bg: 'none' }}
          p={'0'}
        >
          <CheckCircle size={21} />
        </Button>
      </Tooltip>
    </Th>
  );
}
