import { useToast } from '@chakra-ui/react';
import { parseCookies } from 'nookies';
import {
    createContext,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
    ALLOWED_ADMIN_ROUTES,
    COMPANY_ADMIN_DATA,
    USER_COMPANY_COOKIE_NAME,
} from '../constants';
import { useAuth } from '../hooks/Auth';
import { useLocalStorage } from '../hooks/LocalStorage';
import { ICompany } from '../interfaces/Company';
import api, { Service } from '../services/api';

interface CompanyProps {
    setCompany: (data: ICompany) => void;
    getUserCompany: ICompany;
    company: ICompany;
    userIsKlever: boolean;
}

export const ChangeCompanyContext = createContext({} as CompanyProps);

export function ChangeCompanyProvider({ children }: { children: ReactNode }) {
    const [company, setCompany] = useState<ICompany>({} as ICompany);
    const [, setLocalStorage] = useLocalStorage('', '');
    const { user } = useAuth();
    const toast = useToast();
    const { t } = useTranslation('companyAccount');

    const userIsKlever = useMemo(() => {
        return !(
            (company.name !== undefined &&
                company.name !== COMPANY_ADMIN_DATA.name) ||
            user.companyName !== COMPANY_ADMIN_DATA.name
        );
    }, [company, user]);

    const getUserCompany: ICompany = useMemo(() => {
        const userCompany = {
            id: company.id || user.companyId,
            name: company.name || user.companyName,
            email: company.email || '',
        };

        return userCompany;
    }, [company, user]);

    useEffect(() => {
        async function loadUserCompany() {
            if (getUserCompany.name && user.isKleverAdmin) {
                try {
                    const meResponse = await api.get({
                        route: 'companies',
                        service: Service.KRYPTO_BANKING,
                        apiVersion: 'v1',
                        query: {
                            name: getUserCompany.name,
                        },
                    });

                    if (meResponse?.data) {
                        const userCompany = meResponse.data[0] as ICompany;
                        return setUserCompany(userCompany);
                    }
                } catch (e) {
                    console.log(e);
                }
            }

            // Pick user cookie name
            const { [USER_COMPANY_COOKIE_NAME]: userProfile } = parseCookies();
            if (userProfile) {
                const userProfileData =
                    (JSON.parse(userProfile) as ICompany) ?? null;

                setUserCompany(userProfileData);
            }
        }

        loadUserCompany();
    }, [user]);

    const setUserCompany = useCallback(
        (newCompany: ICompany) => {
            setCompany(newCompany);

            setLocalStorage(
                USER_COMPANY_COOKIE_NAME,
                JSON.stringify(newCompany),
            );

            const pathname = window.location?.pathname?.split('/')[1];

            if (!ALLOWED_ADMIN_ROUTES.find(a => a.includes(pathname))) {
                window.location.href = './';
            }

            if (user.isKleverAdmin && company.id !== newCompany.id) {
                toast({
                    title: t('changedSuccess'),
                    description: `${t('changedSuccessBody')} ${
                        newCompany.name
                    }`,
                    status: 'success',
                    duration: 3600,
                    isClosable: true,
                });
            }
        },
        [user, company],
    );

    useEffect(() => {
        const { pathname } = window.location;

        if (pathname === '/statement' && company.name === 'Klever') {
            window.location.href = './';
        }
    }, [company]);

    return (
        <ChangeCompanyContext.Provider
            value={{
                setCompany: setUserCompany,
                company,
                userIsKlever,
                getUserCompany,
            }}
        >
            {children}
        </ChangeCompanyContext.Provider>
    );
}
