import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Flex, Heading, IconButton, Select, Text } from '@chakra-ui/react';

import { useCallback, useEffect, useMemo, useState } from 'react';

import { formatNumber, truncateString } from '../../utils/formatters';

import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import BoxSection from '../../components/BoxSection';
import BoxStyled from '../../components/BoxStyled';
import Container from '../../components/Container';
import Filter from '../../components/Filter';
import { Loading } from '../../components/Loading';
import Nav from '../../components/Navbar';
import { NoResults } from '../../components/NoResults';
import { Table } from '../../components/Table/Table';
import api, { Service } from '../../services/api';

type BalanceEssentials = {
  address: string;
  amount: string;
  token?: string;
  network?: string;
};

type NetworkEssentials = {
  key: number;
  title: string;
};

type TokenEssentials = {
  key: number;
  title: string;
};

type Balance = {
  id: number;
  address: string;
  amount: string;
  tokenId: number;
  networkId: number;
  createdAt: number;
  updatedAt: number;
};

type Network = {
  id: number;
  name: string;
  confirmations: number;
  createdAt: number;
  updatedAt: number;
};

type Token = {
  id: number;
  name: string;
  abbr: string;
  creator: string;
  createdAt: number;
  updatedAt: number;
};

type Filters = {
  networkId: string;
  tokenId: string;
};

type Data = {
  balances: Array<BalanceEssentials>;
  networks: Array<NetworkEssentials>;
  tokens: Array<TokenEssentials>;
};

type Page = {
  current: number;
  total: number;
  limit: number;
};

type BlockchainBalancesData = {
  data: Array<Balance>;
  page: number;
  totalPages: number;
  limit: number;
};

const TABLE_HEADERS = ['token', 'network', 'address', 'amount'];

const PAGE_INIT_STATE: Page = {
  current: 1,
  total: 1,
  limit: 10,
};

const Balances: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [page, setPage] = useState(PAGE_INIT_STATE);
  const [balances, setBalances] = useState([] as Array<Balance>);
  const [networks, setNetworks] = useState([] as Array<Network>);
  const [tokens, setTokens] = useState([] as Array<Token>);
  const [filters, setFilters] = useState({} as Filters);
  const [essentialData, setEssentialData] = useState({} as Data);

  const theme = useTheme();

  const { t } = useTranslation('blockchainBalance');

  const memoizedFilterFields = useMemo(
    () => [
      {
        key: 'networkId',
        type: 'select',
        title: t('filters.network'),
        selectValues: essentialData.networks,
      },
      {
        key: 'tokenId',
        type: 'select',
        title: t('filters.token'),
        selectValues: essentialData.tokens,
      },
    ],
    [essentialData.networks, essentialData.tokens],
  );

  const getBalances = useCallback(async () => {
    try {
      setIsFetching(true);

      const { data, ...rest }: BlockchainBalancesData = await api.get({
        route: `blockchain-balances`,
        service: Service.KRYPTO_BANKING,
        apiVersion: 'v1',
        query: {
          ...filters,
          page: page.current,
          limit: page.limit,
        },
      });

      let fmtBalanceEssentialData: Array<BalanceEssentials> = [];

      if (data?.length) {
        fmtBalanceEssentialData = data.map(b => {
          let fmtBasicItemData = {
            address: truncateString(b.address),
            amount: formatNumber(+b.amount),
          };

          const tokenExists = tokens.find(t => t.id === b.tokenId);
          const networkExists = networks.find(n => n.id === b.networkId);

          if (!tokenExists || !networkExists) return fmtBasicItemData;

          return {
            token: tokenExists.name,
            network: networkExists.name,
            ...fmtBasicItemData,
          };
        });

        setPage({
          current: rest.page,
          total: rest.totalPages,
          limit: rest.limit,
        });
        setBalances(data);
      }

      setEssentialData(prev => ({
        ...prev,
        balances: fmtBalanceEssentialData,
      }));
    } finally {
      setIsFetching(false);
    }
  }, [filters, page.current, page.limit, tokens, networks]);

  const getNetworks = useCallback(async () => {
    try {
      setIsFetching(true);

      const {
        data,
      }: Record<'data', Array<Network>> = await api.get({
        route: 'networks',
        service: Service.KRYPTO_BANKING,
        apiVersion: 'v1',
        query: { limit: 200 },
      });

      if (!data?.length) return;

      const fmtNetworksEssentialData: Array<NetworkEssentials> = data.map(
        n => ({
          key: n.id,
          title: n.name,
        }),
      );

      setEssentialData(prev => ({
        ...prev,
        networks: fmtNetworksEssentialData,
      }));
      setNetworks(data);
    } finally {
      setIsFetching(false);
    }
  }, []);

  const getTokens = useCallback(async () => {
    try {
      setIsFetching(true);

      const {
        data,
      }: Record<'data', Array<Token>> = await api.get({
        route: 'tokens',
        service: Service.KRYPTO_BANKING,
        apiVersion: 'v1',
        query: { limit: 200 },
      });

      if (!data?.length) return;

      let fmtTokensEssentialData: Array<TokenEssentials> = data.map(t => ({
        key: t.id,
        title: `${t.name} (${t.abbr})`,
      }));

      setEssentialData(prev => ({
        ...prev,
        tokens: fmtTokensEssentialData,
      }));
      setTokens(data);
    } finally {
      setIsFetching(false);
    }
  }, []);

  const getFormattedTotalBalance = useCallback(() => {
    let totalBalance = 0;

    if (balances?.length)
      totalBalance = balances.reduce((acc, b) => acc + +b.amount, totalBalance);

    return formatNumber(totalBalance);
  }, [balances]);

  const handleSubmitFilters = (payload: object) =>
    setFilters(payload as Filters);

  const handleChangeToPreviousPage = () => {
    if (page.current <= 1) return;

    setPage(prev => ({
      ...prev,
      current: prev.current - 1,
    }));
  };

  const handleChangeToNextPage = () => {
    if (page.current >= page.total) return;

    setPage(prev => ({
      ...prev,
      current: prev.current + 1,
    }));
  };

  const handleChangePageLimit = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { target } = e;

    setPage(prev => ({
      ...prev,
      limit: +target.value,
    }));
  };

  useEffect(() => {
    const getEssentialData = async () => {
      try {
        setIsLoading(true);
        await Promise.all([getTokens(), getNetworks()]);
      } finally {
        setIsLoading(false);
      }
    };

    getEssentialData();
  }, []);

  useEffect(() => {
    if (!tokens.length || !networks.length) return;

    getBalances();
  }, [getBalances]);

  return (
    <Container>
      <Nav />
      <BoxStyled>
        <BoxSection>
          <Box display="flex" flexDirection="column">
            <Heading
              textAlign="center"
              color={theme.common.lightText}
              mb="1rem"
            >
              {t('title')}
            </Heading>
          </Box>

          <Filter
            translation="blockchainBalance"
            fields={memoizedFilterFields}
            onSubmit={handleSubmitFilters}
          />
        </BoxSection>

        <BoxSection>
          {(isLoading || isFetching) && <Loading />}

          {!isLoading && !isFetching && !balances?.length && <NoResults />}

          {!isLoading && !isFetching && balances?.length > 0 && (
            <>
              <Table
                headers={TABLE_HEADERS}
                data={essentialData.balances}
                translation="blockchainBalance"
              />

              <Heading textAlign="end" size="md" mt={4}>
                Total: {getFormattedTotalBalance()}
              </Heading>

              <Flex justify="center" align="center" gap={10} mt={4}>
                <IconButton
                  aria-label="back-button"
                  disabled={page.current <= 1}
                  onClick={handleChangeToPreviousPage}
                  icon={<ChevronLeftIcon w={6} h={6} />}
                />

                <Text>
                  <b>{page.current}</b>/{page.total}
                </Text>

                <IconButton
                  aria-label="next-button"
                  disabled={page.current >= page.total}
                  onClick={handleChangeToNextPage}
                  icon={<ChevronRightIcon w={6} h={6} />}
                />

                <Flex align="center" ml={2}>
                  <Select value={page.limit} onChange={handleChangePageLimit}>
                    {[10, 20, 50, 100].map(v => (
                      <option key={v} value={v}>
                        Show {v}
                      </option>
                    ))}
                  </Select>
                </Flex>
              </Flex>
            </>
          )}
        </BoxSection>
      </BoxStyled>
    </Container>
  );
};

export default Balances;
