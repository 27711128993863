import { createContext, ReactNode, useState } from 'react';

interface IAppContext {
    reloadPageDrawerClose: boolean;
    setReloadPageDrawerClose: Function;
}

export const AppContext = createContext({} as IAppContext);

export function AppContextProvider({ children }: { children: ReactNode }) {
    const [reloadPageDrawerClose, setReloadPageDrawerClose] = useState(false);

    return (
        <AppContext.Provider
            value={{ reloadPageDrawerClose, setReloadPageDrawerClose }}
        >
            {children}
        </AppContext.Provider>
    );
}
