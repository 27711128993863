import { destroyCookie, parseCookies, setCookie } from 'nookies';
import {
    REFRESH_TOKEN_COOKIE_NAME,
    TOKEN_COOKIE_NAME,
    USER_COMPANY_COOKIE_NAME,
    USER_COOKIE_NAME,
} from '../constants';
import { ICompany } from '../interfaces/Company';
import keycloak from '../keycloak';

enum Method {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
    PATCH = 'PATCH',
}

interface IQuery {
    [key: string]: any;
}

export enum Service {
    KRYPTO_BANKING,
}

export interface IProps {
    route: string;
    query?: IQuery;
    body?: any;
    apiVersion?: string;
    service?: Service;
}

const buildUrlQuery = (query: IQuery): string =>
    Object.keys(query)
        .map(key => `${key}=${query[key]}`)
        .join('&');

const getHost = (
    route: string,
    query: IQuery,
    service: Service,
    apiVersion: string,
) => {
    const hostService = {
        [Service.KRYPTO_BANKING]:
            process.env.REACT_APP_KRYPTO_BANKING_HOST ||
            'http://localhost:8080',
    };

    let host = hostService[service];

    let urlParam = '';

    if (host.substr(host.length - 1) === '/') {
        host = host.substring(0, host.length - 1);
    }

    if (query) {
        urlParam = `?${buildUrlQuery(query)}`;
    }

    return `${host}/${apiVersion}/${route}${urlParam}`;
};

const getProps = (props: IProps) => {
    const get = (target: any, name: string) => {
        if (name in target) {
            return target[name];
        }

        return undefined;
    };

    const handler = { get };

    return new Proxy(props, handler);
};

const refreshKeycloakToken = async () => {
    try {
        const refresh = await keycloak.updateToken();

        if (refresh) {
            const { token, refreshToken } = keycloak;

            setCookie(null, TOKEN_COOKIE_NAME, token);
            setCookie(null, REFRESH_TOKEN_COOKIE_NAME, refreshToken);
        }
    } catch (e) {
        console.log('Failed to refresh the token, or the session has expired');
        destroyCookie(null, TOKEN_COOKIE_NAME);
        destroyCookie(null, REFRESH_TOKEN_COOKIE_NAME);
        destroyCookie(null, USER_COOKIE_NAME);
        destroyCookie(null, USER_COMPANY_COOKIE_NAME);

        await keycloak.logout();
    }
};

const withoutBody = async (props: IProps, method: Method): Promise<any> => {
    try {
        const { route, query, service, apiVersion } = getProps(props);
        
        let headers = {
            'Content-Type': 'application/json',
        };

        if (!keycloak.token) return;

        const {
            [TOKEN_COOKIE_NAME]: userToken,
            [USER_COMPANY_COOKIE_NAME]: userProfile,
        } = parseCookies();

        if (userProfile) {
            const userProfileData =
                (JSON.parse(userProfile) as ICompany) ?? null;

            headers = Object.assign(headers, {
                'User-Company-Profile':
                    userProfileData?.id && userProfileData.id,
            });
        }
        if (userToken) {
            headers = Object.assign(headers, {
                Authorization: `Bearer ${userToken}`,
            });
        }

        const response = await fetch(
            getHost(route, query, service, apiVersion),
            {
                method: method.toString(),
                headers,
            },
        );

        if (response.status === 401) {
            await refreshKeycloakToken();
        }

        if (!response.ok) {
            return Promise.resolve({
                data: null,
                error: response.statusText,
                code: 'internal_error',
            });
        }

        let respVerifier = response;
        let text = await respVerifier.text();
        if (text === '') {
            return '{}';
        }
        return JSON.parse(text);
    } catch (error) {
        return Promise.resolve({ data: null, error, code: 'internal_error' });
    }
};

const withBody = async (props: IProps, method: Method): Promise<any> => {
    try {
        const { route, body, query, service, apiVersion } = getProps(props);

        let headers = {
            'Content-Type': 'application/json',
        };

        if (!keycloak.token) return;

        const {
            [TOKEN_COOKIE_NAME]: userToken,
            [USER_COMPANY_COOKIE_NAME]: userProfile,
        } = parseCookies();

        if (userProfile) {
            const userProfileData =
                (JSON.parse(userProfile) as ICompany) ?? null;

            headers = Object.assign(headers, {
                'User-Company-Profile':
                    userProfileData?.id && userProfileData.id,
            });
        }

        if (userToken) {
            headers = Object.assign(headers, {
                Authorization: `Bearer ${userToken}`,
            });
        }

        const response = await fetch(
            getHost(route, query, service, apiVersion),
            {
                method: method.toString(),
                headers,
                body: JSON.stringify(body),
            },
        );

        if (response.status === 401) {
            await refreshKeycloakToken();
        }

        let respVerifier = response;
        let text = await respVerifier.text();
        if (text === '') {
            return '{}';
        }
        return JSON.parse(text);
    } catch (error) {
        return Promise.resolve({ data: null, error, code: 'internal_error ' });
    }
};

const api = {
    get: async (props: IProps): Promise<any> => withoutBody(props, Method.GET),
    post: async (props: IProps): Promise<any> => withBody(props, Method.POST),
    put: async (props: IProps): Promise<any> => withBody(props, Method.PUT),
    patch: async (props: IProps): Promise<any> => withBody(props, Method.PATCH),
    delete: async (props: IProps): Promise<any> =>
        withBody(props, Method.DELETE),
};

export default api;
