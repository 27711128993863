import { Stack } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  components,
  ControlProps,
  OptionProps,
  StylesConfig,
} from 'react-select';
import { useTheme } from 'styled-components';
import { LANGUAGE_NAME } from '../../../constants';
import { LanguageProps, languages } from '../../../i18n/languageConfig';
import { ReactSelectCustom } from '../styles';

const colourStyles: StylesConfig = {
  control: styles => ({
    ...styles,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    paddingLeft: '1.2rem',
    flexWrap: 'nowrap',
    ':active': {
      boxShadow: 'none',
    },
  }),
  placeholder: styles => ({ ...styles, color: 'white' }),
  indicatorSeparator: styles => ({ ...styles, width: '0' }),

  option: (styles, { data }) => {
    const { icon } = data as LanguageProps;
    return {
      ...styles,
      fontSize: '0',
      position: 'relative',
      width: '100%',
      height: '40px',
      cursor: 'pointer',
      ':before': {
        ...styles[':before'],
        textAlign: 'center',
        content: `"${icon}"`,
        width: '70%',
        fontSize: '17px',
        position: 'absolute',
      },
    };
  },
  singleValue: styles => ({ ...styles, color: 'white', fontSize: '0' }),
  input: styles => ({ ...styles, display: 'block' }),
};

export function ChangeLanguageSelect() {
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageProps>(
    languages[0],
  );

  const { i18n } = useTranslation();
  const theme = useTheme();

  const getStorageLanguage = useCallback(() => {
    const storageLanguage = localStorage.getItem(LANGUAGE_NAME);

    if (storageLanguage) {
      const language = languages.find(
        language => language.value === storageLanguage,
      );

      if (language) {
        setSelectedLanguage(language);
        i18n.changeLanguage(storageLanguage);
      }
    }
  }, [i18n]);

  const changeLanguage = useCallback(
    languageObj => {
      const { value } = languageObj as LanguageProps;

      i18n.changeLanguage(value);
      setSelectedLanguage(languageObj);
      localStorage.setItem(LANGUAGE_NAME, value);
    },
    [i18n],
  );

  useEffect(getStorageLanguage, [getStorageLanguage]);

  const Control = ({ children, ...props }: ControlProps) => {
    const { icon } =
      (props?.selectProps?.value as LanguageProps) ?? languages[0];

    return (
      <components.Control {...props}>
        <img src={`icons/${icon}.svg`} className="countryFlag" /> {children}
      </components.Control>
    );
  };

  const IconOption = ({ data, ...props }: OptionProps) => {
    const { icon, label } = data as any;

    return (
      <components.Option
        data={{
          icon: '',
        }}
        {...props}
      >
        <img
          src={`icons/${icon}.svg`}
          className="countryFlag"
          style={{
            margin: '0 auto',
          }}
          alt={label}
        />
      </components.Option>
    );
  };

  return (
    <Stack
      position={'relative'}
      alignItems={'flex-end'}
      justifyContent={'center'}
      width={'100px'}
      color={theme.navbar.links.primaryColor}
      data-testid="languageSelectTest"
    >
      <ReactSelectCustom
        className="language"
        data-testid="languageInputTest"
        options={languages}
        defaultValue={selectedLanguage}
        components={{ Control, Option: IconOption }}
        value={selectedLanguage}
        onChange={changeLanguage}
        styles={colourStyles}
        isSearchable={false}
      ></ReactSelectCustom>
    </Stack>
  );
}
