import styled from "styled-components";
import { Box } from "@chakra-ui/react";

export const BoxStyled = styled(Box)`
  margin-top: 8px;
  margin-left: 64px;

  
  @media only screen and (max-width: 615px) {
    margin-left: 0;
  }
`;