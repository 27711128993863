import styled from 'styled-components';
import { Spinner } from '@chakra-ui/react';

export const LoginContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
height: 100vh;
overflow-x: hidden;
background: ${props => props.theme.background}
`;

export const KleverSpinner = styled(Spinner).attrs(({theme})=>({
  thickness:"4px",
  speed:"0.65s",
  emptyColor:"gray.200",
  color:theme.colors.kleverPink.light,
  size:"xl",
}))``;