import {
  Table as ChakraTable,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { ITableProps } from '../../interfaces/Table';

export const Table: React.FC<ITableProps> = ({
  headers,
  data,
  translation,
}) => {
  const theme = useTheme();

  const { t } = useTranslation(translation);

  const renderHeaders = () =>
    headers.map(h => <Th key={h}>{t(`table.columns.${h}`)}</Th>);

  const renderData = () => {
    if (!data?.length)
      return (
        <Tr>
          <Td colSpan={headers.length}>
            <Text textAlign="center">{t('table.noResults')}</Text>
          </Td>
        </Tr>
      );

    if (data?.length)
      return data.map((r, i) => (
        <Tr key={i}>
          {Object.entries(r).map(([k, v]) => {
            if (!headers.includes(k)) return;

            return <Td key={k}>{String(v)}</Td>;
          })}
        </Tr>
      ));
  };

  return (
    <TableContainer color={theme.tables.color}>
      <ChakraTable
        variant="striped"
        colorScheme="blackAlpha"
        backgroundColor={theme.tables.background}
        color={theme.tables.color}
      >
        <Thead>
          <Tr>{renderHeaders()}</Tr>
        </Thead>

        <Tbody>{renderData()}</Tbody>
      </ChakraTable>
    </TableContainer>
  );
};
