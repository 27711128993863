import React, { BaseSyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Box, Flex, FormControl, FormLabel, Heading, HStack, Switch, Text, Tooltip, useToast } from '@chakra-ui/react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useChangeCompany } from '../../../hooks/ChangeCompany';
import BoxSection from '../../BoxSection';
import api, { Service } from '../../../services/api';

import { createTokenSchema } from '../../../schemas/CreateTokenSchema';
import { ValidationError } from 'yup';

import Button from '../../Button';
import { Info, Plus } from 'phosphor-react';
import { Fieldset, Legend } from './styles';
import InputForm from './InputForm';
import { InputFields, SelectFields, SwitchFields } from './FieldsForm'
import SelectForm from './SelectForm';
import SwitchForm from './SwithForm';

interface NetworkForm extends BaseSyntheticEvent {
  target: {
    networkId?: HTMLSelectElement
    assetId?: HTMLInputElement,
    tokenName?: HTMLInputElement,
    abbreviation?: HTMLInputElement,
    precision?: HTMLInputElement
    creator?: HTMLInputElement,

    withdrawFeeAmount?: HTMLInputElement,
    withdrawFeeTokenId?: HTMLSelectElement
    BurnFeeAmount?: HTMLInputElement,
    minWithdraw?: HTMLInputElement,
    minDeposit?: HTMLInputElement,

    minCheckBalanceAmount?: HTMLInputElement,
    minSendColdAccountAmount?: HTMLInputElement,
    sendCheckBalance?: HTMLInputElement,
    maxColdAccountsTransfer?: HTMLInputElement,

    isNFT?: HTMLInputElement,
    isMainCoin?: HTMLInputElement,
    activeDeposit?: HTMLInputElement,
    activeWithdraw?: HTMLInputElement,
  };
}

const CreateToken: React.FC<any> = () => {
  const toast = useToast();
  const { t } = useTranslation('createNetworkToken');
  const theme = useTheme();
  
  const [isLoading, setIsLoading] = useState(false);
  const [networkList, setNetworkList] = useState([])
  const [tokenList, setTokenList] = useState([])

  const [valueWithdrawFeeAmount, setValueWithdrawFeeAmount] = useState(0)
  const [valueWithdraw, setValueWithdraw] = useState(0)

  const isErrorWithdraw = valueWithdraw < valueWithdrawFeeAmount

  const handleSubmit = useCallback(async (e: BaseSyntheticEvent) => {
    e.preventDefault();
    setIsLoading(true);
    
    const { target } = e as NetworkForm;
    const {
      networkId,
      assetId,
      tokenName,
      abbreviation,
      precision,
      creator,
      withdrawFeeAmount,
      withdrawFeeTokenId,
      BurnFeeAmount,
      minWithdraw,
      minDeposit,
      minCheckBalanceAmount,
      minSendColdAccountAmount,
      sendCheckBalance,
      maxColdAccountsTransfer,
      isNFT,
      isMainCoin,
      activeDeposit,
      activeWithdraw,
    } = target;
    
    const data = createTokenSchema.cast({
      networkId: Number(networkId?.value),
      assetId: String(assetId?.value),
      name: String(tokenName?.value),
      abbr: String(abbreviation?.value),
      precision: Number(precision?.value),
      creator: String(creator?.value),
      withdrawFeeAmount: String(withdrawFeeAmount?.value),
      withdrawFeeTokenId: Number(withdrawFeeTokenId?.value),
      BurnFeeAmount: String(BurnFeeAmount?.value),
      minWithdraw: String(minWithdraw?.value),
      minDeposit: String(minDeposit?.value),
      minCheckBalanceAmountUsd: Number(minCheckBalanceAmount?.value),
      minSendColdAccountAmountUsd: Number(minSendColdAccountAmount?.value),
      sendCheckBalanceUsd: Number(sendCheckBalance?.value),
      maxColdAccountsTransfer: Number(maxColdAccountsTransfer?.value),
      isNft: isNFT?.checked,
      isMaincoin: isMainCoin?.checked,
      activeDeposit: activeDeposit?.checked,
      activeWithdraw: activeWithdraw?.checked,
    })

    
    try {
      await createTokenSchema.validate(data, {
        abortEarly: false,
      });

      const response = await api.post({
        route: 'tokens-networks',
        service: Service.KRYPTO_BANKING,
        apiVersion: 'v1',
        body: {
          ...data,
        },
      });

      if (response?.data) {
        return toast({
          title: t('common:toasts.titles.success'),
          description: t('common:toasts.body.successSent'),
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }

      return toast({
        title: t('common:toasts.titles.error'),
        description: t('token.fields.failed'),
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } catch (err: any) {
      if (err instanceof ValidationError) {
        toast({
          title: t('common:toasts.titles.error'),
          description: err.errors[0],
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    } finally {
      setIsLoading(false);
    }

  }, []);

  useEffect(() => {
    (async function () {
      const response = await api.get({
        route: 'networks',
        service: Service.KRYPTO_BANKING,
        apiVersion: 'v1',
      });
      setNetworkList(response.data)
    })();
  }, []);

  useEffect(() => {
    (async function () {
      const response = await api.get({
        route: 'tokens',
        service: Service.KRYPTO_BANKING,
        apiVersion: 'v1',
      });
      setTokenList(response.data)
    })();
  }, []);

return (
    <BoxSection>
      <Heading textAlign={'center'} color={theme.common.lightText} mb="2rem">
        {'Create Token'}
      </Heading>
      <Flex as="form" onSubmit={handleSubmit} flexDir="column" aria-required>
        <Fieldset>
          <Legend>{"Basic Info"}</Legend>
          <Flex gap={2}>
            <SelectForm props={SelectFields.network} list={networkList}/>
            <InputForm props={InputFields.assetID} />
          </Flex>
          <Flex gap={2}>
            <InputForm props={InputFields.tokenName} />
            <InputForm props={InputFields.abbreviation} />
            <InputForm props={InputFields.precision} />
            <InputForm props={InputFields.creator} />
          </Flex>
        </Fieldset>
        <Fieldset>
          <Legend>{"Fees"}</Legend>
          <Flex gap={2}>
            <InputForm 
              props={InputFields.withdrawFeeAmount} 
              value={valueWithdrawFeeAmount} 
              setValue={setValueWithdrawFeeAmount}
            />
            <SelectForm props={SelectFields.withdrawFeeTokenId} list={tokenList}/>
            <InputForm props={InputFields.burnFeeAmount} />
          </Flex>
          <Flex gap={2}>
            <InputForm 
              props={InputFields.minWithdraw} 
              value={valueWithdraw} 
              setValue={setValueWithdraw}
              isError={isErrorWithdraw}
            />
            <InputForm props={InputFields.minDeposit} />
          </Flex>
        </Fieldset>
        <Fieldset>
          <Legend>{"Amount & Balances"}</Legend>
          <Flex gap={2}>
            <InputForm props={InputFields.minCheckBalanceAmount} />
            <InputForm props={InputFields.minSendColdAccountAmount} />
            <InputForm props={InputFields.sendCheckBalance} />
            <InputForm props={InputFields.maxColdAccountsTransfer} />
          </Flex>
        </Fieldset>
        <Fieldset>
          <Legend>{"Control Panel"}</Legend>
          <Flex gap={2}>
            <SwitchForm props={SwitchFields.isNFT} />
            <SwitchForm props={SwitchFields.isMainCoin} />
            <SwitchForm props={SwitchFields.activeDeposit} />
            <SwitchForm props={SwitchFields.activeWithdraw} />
          </Flex>
        </Fieldset>
        <Button
          leftIcon={<Plus size={18} />}
          alignSelf={'streetch'}
          type="submit"
          isLoading={isLoading}
        >
          {t('network.fields.submit')}
        </Button>
      </Flex>
    </BoxSection>
  );
};

export default CreateToken;



