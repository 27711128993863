const InputFields = {
  tokenName: {
    labelName:'Token name',
    nameInput:"tokenName",
    typeInput: "text",
  },
  abbreviation: {
    labelName:'Abbreviation',
    nameInput:"abbreviation",
    typeInput: "text",
    hasTooltip: true,
    tooltipText: "abbreviation"
  },
  precision: {
    labelName:'Precision',
    nameInput:"precision",
    typeInput: "number",
    hasTooltip: true,
    tooltipText: "precision",
  },
  creator: {
    labelName:'Creator name',
    nameInput:"creator",
    typeInput: "text",
  },
  assetID: {
    labelName:'Asset ID',
    nameInput: "assetId",
    typeInput: "text",
    hasTooltip: true,
    tooltipText: "assetId"
  },
  maxColdAccountsTransfer: {
    labelName: 'Max cold accounts transfer',
    nameInput: "maxColdAccountsTransfer",
    typeInput: "number",
    hasTooltip: true,
    tooltipText: "maxColdAccountsTransfer"
  },
  withdrawFeeAmount: {
    labelName: 'Withdraw fee amount',
    nameInput: 'withdrawFeeAmount',
    typeInput: "number",
  },
  burnFeeAmount: {
    labelName: 'Burn fee amount',
    nameInput: 'BurnFeeAmount',
    typeInput: "number",
    hasTooltip: true,
    tooltipText: "burnFeeAmount",
    default: 0,
  },
  minWithdraw: {
    labelName: 'Min withdraw amount',
    nameInput: 'minWithdraw',
    typeInput: "number",
    msnError: "minWithdrawError"
  },
  minDeposit: {
    labelName: 'Min deposit amount',
    nameInput: 'minDeposit',
    typeInput: "number",
    default: 0,
  },
  minCheckBalanceAmount: {
    labelName: 'Min check balance amount',
    nameInput: 'minCheckBalanceAmount',
    typeInput: "number",
    default: 0,
  },
  minSendColdAccountAmount: {
    labelName: 'Min send cold account amount',
    nameInput: 'minSendColdAccountAmount',
    typeInput: "number",
    default: 0,
  },
  sendCheckBalance: {
    labelName: 'Send check balance',
    nameInput: 'sendCheckBalance',
    typeInput: "number",
    default: 0,
  },
}

const SelectFields = {
  network: {
    labelName:'Network',
    nameSelect: 'networkId',
    defaultValue: false,
  },
  withdrawFeeTokenId: {
    labelName:'Withdraw fee token ID',
    nameSelect: 'withdrawFeeTokenId',
    defaultValue: true,
  }
}

const SwitchFields = {
  isNFT: { 
    labelName: "Is NFT?",
    nameSwitch: "isNFT",
  },
  isMainCoin: {
    labelName: "Is main coin?",
    nameSwitch: "isMainCoin",
  },
  activeDeposit: {
    labelName: "Active deposit",
    nameSwitch: "activeDeposit",
  },
  activeWithdraw: {
    labelName: "Active withdraw",
    nameSwitch: "activeWithdraw",
  },
}

export {
  InputFields,
  SelectFields,
  SwitchFields
}