import { AccordionPanel, Box, Flex } from '@chakra-ui/react';
import { FormEvent, useCallback } from 'react';
import { IFilterContentProps } from '../../../interfaces/Filter';
import { Field } from '../Field/Field';
import Button from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

export function Content({
  submitTitle,
  fields,
  onSubmit,
  switchLogsAdmin,
}: IFilterContentProps) {
  const { t } = useTranslation('filter');
  const theme = useTheme();

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      let data = {};

      fields.map(field => {
        const { [field.key]: fieldData } = e.target as any;

        if (fieldData.value.trim().length > 0) {
          data = { ...data, [field.key]: fieldData.value };
        } else if (fieldData.checked) {
          data = { ...data, [field.key]: fieldData.checked };
        }
      });

      switchLogsAdmin?.(data.hasOwnProperty('logsAdmin'));
      onSubmit(data);
    },
    [onSubmit, t],
  );

  return (
    <AccordionPanel pb={4}>
      <Flex as="form" flexDirection={'column'} onSubmit={handleSubmit}>
        {fields
          .filter(
            fieldData =>
              fieldData.type !== 'date' && fieldData.type !== 'checkbox',
          )
          .map(fieldData => (
            <Field data={fieldData} key={fieldData.key} />
          ))}
        <Box
          display="grid"
          gridTemplateColumns={['1fr', '1fr', '1fr 1fr']}
          gap="0 30px"
        >
          {fields
            .filter(fieldData => fieldData.type === 'date')
            .map(fieldData => (
              <Field data={fieldData} key={fieldData.key} />
            ))}
        </Box>
        {fields
          .filter(fieldData => fieldData.type === 'checkbox')
          .map(fieldData => (
            <Field data={fieldData} key={fieldData.key} />
          ))}
        <Box
          display="grid"
          gridTemplateColumns={['1fr', '1fr', '1fr 1fr']}
          gap="0 30px"
        >
          <Button type="submit" mt="1rem" buttonType="primary" flexGrow={1}>
            {submitTitle}
          </Button>
          <Button mt="1rem" buttonType="outlined" type="reset">
            {t('buttons.clean')}
          </Button>
        </Box>
      </Flex>
    </AccordionPanel>
  );
}
