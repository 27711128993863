import {
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tfoot,
} from '@chakra-ui/react';

interface BillingTableProps {
  dateFrom: string;
  dateTo: string;
  amountDeposits: number;
  amountWithdrawals: number;
  amountInternalTransfers: number;
  calculationValues: {
    depositCost: number;
    withdrawCost: number;
    internalTransferCost: number;
    totalCost: number;
  };
  fee: number;
  percentage: number;
}

const BillingTable: React.FC<any> = ({
  dateFrom,
  dateTo,
  amountDeposits,
  amountWithdrawals,
  amountInternalTransfers,
  calculationValues,
  fee,
  percentage,
}: BillingTableProps) => {
  return (
    <TableContainer>
      <Table variant="simple">
        <TableCaption>
          Costs from {dateFrom} to {dateTo}
        </TableCaption>
        <Thead>
          <Tr>
            <Th>Type of Transaction</Th>
            <Th>Number of Transactions</Th>
            <Th>Fee Cost</Th>
            <Th>Percetage Billing</Th>
            <Th isNumeric>Cost</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr color={'white'}>
            <Td>Deposit</Td>
            <Td>{amountDeposits}</Td>
            <Td>{fee}</Td>
            <Td>{percentage}%</Td>
            <Td isNumeric>${calculationValues?.depositCost.toFixed(2)}</Td>
          </Tr>
          <Tr color={'white'}>
            <Td>Withdraw</Td>
            <Td>{amountWithdrawals}</Td>
            <Td>{fee}</Td>
            <Td>{percentage}%</Td>
            <Td isNumeric>${calculationValues?.withdrawCost.toFixed(2)}</Td>
          </Tr>
          <Tr color={'white'}>
            <Td>Internal transfer</Td>
            <Td>{amountInternalTransfers}</Td>
            <Td>{fee}</Td>
            <Td>{percentage}%</Td>
            <Td isNumeric>
              ${calculationValues?.internalTransferCost.toFixed(2)}
            </Td>
          </Tr>
        </Tbody>
        <Tfoot>
          <Tr>
            <Th></Th>
            <Th></Th>
            <Th></Th>
            <Th fontSize={22} color={'white'}>
              Total:
            </Th>
            <Th isNumeric fontSize={22} color={'white'}>
              ${calculationValues?.totalCost.toFixed(2)}
            </Th>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  );
};

export default BillingTable;
