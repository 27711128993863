import { Button } from '@chakra-ui/react';
import styled from 'styled-components';
import { darken } from 'polished';

export const PrimaryButton = styled(Button)`
  color: ${props => props.theme.button.color} !important;
  background: ${props => props.theme.button.background} !important;
  min-width: 100px !important;
  max-width: ${props => props.maxWidth};
  height: 40px;
  &:hover {
    background: ${props =>
      darken(0.1, props.theme.button.background)} !important;
  }
`;

export const OutlinedButton = styled(Button)`
  color: ${props => props.theme.outlinedButton.color} !important;
  background: ${props => props.theme.outlinedButton.background} !important;
  border: 1px solid ${props => props.theme.outlinedButton.borderColor} !important;
  min-width: 100px !important;
  height: 40px;

  :disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &:hover {
    background: ${props =>
      props.theme.outlinedButton.hover.background} !important;
    color: ${props => props.theme.outlinedButton.hover.color} !important;
  }
`;

export const CopyButton = styled(Button)`
  color: ${props => props.theme.copyButton.color} !important;
  background: white !important;
  height: 32px !important;
  font-size: 0.7rem !important;
  padding: 0.3 !important;
  transition: 0.3s !important;

  outline: none !important;
  outline-offset: none !important;
  border: none !important;
  box-shadow: none !important;

  &:hover {
    color: ${props => props.theme.copyButton.hover.color} !important;
  }
`;
