import styled from 'styled-components';

export const SubContainer = styled.div`
  margin-left: 64px;
  max-width: 100%;

  @media only screen and (max-width: 615px)  {
    margin-left: 0;
  }
`

export const Content = styled.div`
  max-width: 95%;
  margin: 0 auto;
  padding-top: 25px;

  display: grid;
  grid-template-areas: 
  "stats                    stats                     distribution"
  "totalValueInUSS               totalByTokenAndDate              TotalQuantityByDateTransactions"
  "thirtyDaysTransactions   thirtyDaysTransactions    thirtyDaysTransactions";
  grid-template-columns: repeat(3, minmax(0, calc(100%/3)));
  grid-template-rows: 23% 51% 23%;
  gap: 1rem;

  @media only screen and (max-width: 1300px)  {
    grid-template-areas: 
    "stats                    stats"
    "distribution             distribution"
    "totalValueInUSS               totalValueInUSS"
    "totalByTokenAndDate             totalByTokenAndDate"
    "TotalQuantityByDateTransactions        TotalQuantityByDateTransactions"
    "thirtyDaysTransactions   thirtyDaysTransactions";
  grid-template-columns: repeat(2, minmax(0, calc(100%/2)));
  grid-template-rows: 1fr 1fr 20% 20% 20% 1fr;
  }

`;

