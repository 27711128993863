import React from 'react';
import { FormControl, FormLabel, HStack, Switch, Text, Tooltip } from "@chakra-ui/react";
import { Info } from "phosphor-react";
import { useTranslation } from "react-i18next";
import { useTheme } from 'styled-components';

interface ISwitch {
  props:{
    labelName: string;
    nameSwitch: string;
    hasTooltip?: boolean;
    tooltipText?: string;
  }
}

const SwitchForm: React.FC<any> = ({props}: ISwitch) => {
  const { t } = useTranslation('createNetworkToken');
  const theme = useTheme();
  return (
    <FormControl>
      <FormLabel color={theme.common.lightText}>
        <HStack>
          <Text>{props.labelName}</Text>
          {props.hasTooltip &&
            <Tooltip label={t(`token.tooltip.${props.tooltipText}`)}>
              {<Info size={20}  weight="fill"/>}  
            </Tooltip>
          }
          <Switch name={props.nameSwitch} marginLeft="10px" size='lg'
            sx={{
              'span.chakra-switch__track': {
                backgroundColor: theme.switch.colorOn,
              },
              'span.chakra-switch__track:not([data-checked])': {
                backgroundColor: theme.switch.colorOff,
              },
            }}/>
        </HStack>
      </FormLabel>
    </FormControl>
  )
}

export default SwitchForm;