import {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Theme } from '../styles/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { THEME_NAME } from '../constants';
import { themes } from '../styles/themes';
interface ITheme {
  theme: Theme;
  toggleTheme: () => void;
}

export const ThemeContext = createContext({} as ITheme);

export function ThemeProvider({ children }: { children: ReactNode }) {
  const [userTheme, setUserTheme] = useState(() => {
    const storageValue = localStorage.getItem(THEME_NAME);

    return storageValue ?? 'light';
  });

  const getUserTheme = useMemo(
    () => themes[userTheme] ?? themes.light,
    [userTheme],
  );

  const toggleTheme = useCallback(() => {
    const newTheme = userTheme === 'light' ? 'dark' : 'light';

    localStorage.setItem(THEME_NAME, newTheme);

    setUserTheme(newTheme);
  }, [userTheme]);

  return (
    <ThemeContext.Provider
      value={{
        theme: getUserTheme,
        toggleTheme,
      }}
    >
      <StyledThemeProvider theme={getUserTheme}>
        <>{children}</>
      </StyledThemeProvider>
    </ThemeContext.Provider>
  );
}
