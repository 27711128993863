export interface LanguageProps {
  label: string;
  value: string;
  icon: string;
}

export const languages: LanguageProps[] = [
  {
    label: 'English',
    value: 'en',
    icon: 'us',
  },
  {
    label: 'Português (Brasil)',
    value: 'pt-BR',
    icon: 'br',
  },
  // {
  //   label: 'Spanish',
  //   value: 'es',
  //   icon: 'es',
  // },
  // {
  //   label: 'French',
  //   value: 'fr',
  //   icon: 'fr',
  // },
  // {
  //   label: 'Italian',
  //   value: 'it',
  //   icon: 'it',
  // },
];
