import i18n from '../i18n';

const validationMessageMapper = (tag: string) => {
    switch (tag) {
        case 'min':
            return 'minimumLengthMessage';
        case 'max':
            return 'maximumLengthMessage';
        case 'required':
            return 'requiredMessage';
    }
}

const ErrorHandler = (ns: string, message: any, isFormikErrors = false) => {
    if (typeof message === 'object') {
        let errorString = "";
        if (!isFormikErrors) {
            message.forEach((error: any) => {
                let { field, tag, value } = error;
                field = i18n.t(`common:validation.${field.toLowerCase().split('.').reverse()[0]}`);
                let messageType = validationMessageMapper(tag);
                errorString += i18n.t(`common:validation.${messageType}`, [field, value]);
            });
        } else {
            for (let k in message) {
                errorString += i18n.t(`common:formik.${k}`);
            }   
        }

        return errorString;
    }
    return i18n.t(`${ns}:errors.${message}`);
}

export default ErrorHandler;