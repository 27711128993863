import styled from 'styled-components';
import { IBarsProps } from '../../../interfaces/Common';

export const StyledBars = styled.div<IBarsProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  gap: 5px;
  cursor: pointer;
  margin-right: 1rem;
  span {
    border-radius: 2px;
    width: 20px;
    height: 2px;
    background-color: white;
    transition: all 0.3s ease;

    &:nth-child(2) {
      display: ${props => (props.isOpen ? 'none' : 'block')};
    }

    ${props =>
      props.isOpen &&
      `
           
           
            &:first-child {
                transform: rotate(45deg) translateX(5px);
            }
             &:nth-child(3){
                transform: rotate(-45deg) translateX(5px);
            }
        `}
  }
`;
