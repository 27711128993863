const light = {
  name: 'light',
  background: '#EEEEEE',
  form: {
    background: '#fff',
    color: 'rgb(34, 32, 40)',
    icon: {
      color: 'rgb(34, 32, 40)',
      secondaryColor: 'rgb(34, 32, 40)',
    },
  },
  button: {
    background: '#AA33B5',
    color: '#FFFFFF',
  },
  outlinedButton: {
    background: 'white',
    color: 'rgb(34, 32, 40)',
    borderColor: 'rgb(34, 32, 40)',
    hover: {
      background: '#131217',
      color: 'white',
    },
  },
  copyButton: {
    color: '#C4C4C4',
    hover: {
      color: '#323238',
    },
  },
  switch: {
    colorOn: '#AA33B5',
    colorOff: '#718096',
  },
  input: {
    background: '#efefef',
    color: 'rgb(34, 32, 40)',
    focus: {
      text: '#AA33B5',
      date: '#95a5a6',
    },
  },
  navbar: {
    background: '#0e0f22',
    backgroundLinks: '#04040e',
    links: {
      primaryColor: 'white',
      subTitle: '#AA33B5',
    },
  },
  tabs: {
    selected: '#AA33B5',
  },
  tables: {
    background: 'white',
    color: 'rgb(34, 32, 40)',
    button: {
      color: 'rgb(34, 32, 40)',
    },
    select: {
      color: 'rgb(34, 32, 40)',
      background: 'white',
    },
  },
  common: {
    lightText: 'rgb(34, 32, 40)',
    colors: {
      light: 'rgb(34, 32, 40)',
    },
  },
  drawer: {
    background: '#FFF',
    color: 'rgb(34, 32, 40)',
  },
  colors: {
    kleverPink: {
      light: '#AA33B5',
    },
  },
  boxShadow: {
    color1: 'rgba(16, 22, 26, 0.15)',
  },
  legend: { color: '#4A5568' },
  select: {
    focus: {
      color: '#AA33B5',
    },
  },
  status: {
    pendingColor: '#FFA500',
    inProgressColor: '#4299E1',
    refusedColor: '#E53E3E',
    errorColor: '#E53E3E',
    doneColor: '#38A169',
  },
  scrollbar: {
    background: '#0e0f22',
    thumb: {
      background: '#fff',
      hover: {
        background: 'rgb(0, 0, 0)',
      },
    },
  },
  chart: {
    background: '#fff',
    backgroundAlpha: 'rgba(255, 255, 255, 0.4)',
    text: 'rgb(0, 0, 0)',
  },
};

export default light;
