import { Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { showDifferenceProps } from '../../../interfaces/Table';

export default function ShowDifference({
    field,
    oldValue,
    newValue,
}: showDifferenceProps) {
    const { t } = useTranslation('tables');

    const isBoolean = (value: string) => {
        return ['false', 'true'].includes(value)
            ? t(`logsTable.${value}`)
            : value;
    };

    return (
        <Text as="span">
            {t(`logsTable.${field}`)}:{' '}
            <Text as="s" fontStyle="italic">
                {isBoolean(oldValue)}
            </Text>{' '}
            {isBoolean(newValue)}{' '}
        </Text>
    );
}
