import * as Yup from 'yup';

const i18MapErrors = {
    min3: 'min3Characters',
    min5: 'min5Characters',
    max60: 'max60Characters',
    invalid: 'invalidEmailFormat',
};

export async function validationInputValues(type: string, value: string) {
    const data = { [type]: value };

    let resp: string | boolean = '';
    const schemaValidationAllInputs = Yup.object().shape({
        firstName: Yup.string()
            .min(3, i18MapErrors.min3)
            .max(60, i18MapErrors.max60),
        lastName: Yup.string()
            .min(3, i18MapErrors.min3)
            .max(60, i18MapErrors.max60),
        name: Yup.string()
            .min(3, i18MapErrors.min3)
            .max(60, i18MapErrors.max60),
        email: Yup.string()
            .email(i18MapErrors.invalid)
            .max(60, i18MapErrors.max60),
        address: Yup.string().max(60, i18MapErrors.max60),
        apiUrl: Yup.string()
            .min(5, i18MapErrors.min5)
            .max(60, i18MapErrors.max60),
        companyToken: Yup.string()
            .min(5, i18MapErrors.min5)
            .max(60, i18MapErrors.max60),
        kleverToken: Yup.string()
            .min(5, i18MapErrors.min5)
            .max(60, i18MapErrors.max60),
    });

    try {
        resp = !!(await schemaValidationAllInputs.validate(data));
    } catch (err: any) {
        [resp] = err.errors;
    }
    return resp;
}
