import { useCallback, useEffect, useMemo, useState } from 'react';
import { Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { useAuth } from '../../hooks/Auth';
import { useChangeCompany } from '../../hooks/ChangeCompany';
import Container from '../../components/Container';
import Nav from '../../components/Navbar';
import PaginatedTable from '../../components/PaginatedTable';
import BoxSection from '../../components/BoxSection';
import Filter from '../../components/Filter';
import BoxStyled from '../../components/BoxStyled';

interface FiltersProps {
  type?: string;
  info?: string;
  from?: string;
  to?: string;
}

const types = [
  'recover_password',
  'forgot_password',
  'update_profile',
  'create_user',
  'edit_user',
  'delete_user',
];

const adminTypes = [
  'create_company',
  'edit_company',
  'delete_company',
  'approve_company',
];

const headerToggleFalse = ['type', 'info', 'createdAt'];

const headerToggleTrueORKleverCompany = [
  'userName',
  'companyName',
  'type',
  'info',
  'createdAt',
];

const Logs: React.FC = () => {
  const { t } = useTranslation('logs');
  const { user } = useAuth();
  const { userIsKlever } = useChangeCompany();
  const theme = useTheme();

  const [requestLoad, setRequestLoad] = useState(true);
  const [filters, setFilters] = useState<FiltersProps>();
  const [headers, setHeaders] = useState(headerToggleFalse);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    if (userIsKlever || toggle === true) {
      setHeaders(headerToggleTrueORKleverCompany);
      setRequestLoad(true);
    } else {
      setHeaders(headerToggleFalse);
      setRequestLoad(true);
    }
    setTimeout(() => {
      setRequestLoad(false);
    }, 100);
  }, [userIsKlever, filters, toggle]);

  const filterFieldsKlever = [
    {
      key: 'type',
      title: t('filters.type'),
      type: 'text',
    },
    {
      key: 'info',
      title: t('filters.info'),
      type: 'text',
    },
    {
      key: 'fromDate',
      title: t('filters.from'),
      type: 'date',
    },
    {
      key: 'toDate',
      title: t('filters.to'),
      type: 'date',
    },
  ];

  const filterFieldsNotKlever = {
    key: 'logsAdmin',
    title: t('filters.logsAdmin'),
    type: 'checkbox',
  };

  const filterFields = useMemo(
    () =>
      userIsKlever
        ? [...filterFieldsKlever]
        : [...filterFieldsKlever, { ...filterFieldsNotKlever }],
    [userIsKlever, toggle, filters],
  );

  const handleFilters = useCallback(
    (e: object) => {
      setFilters(e);
    },
    [userIsKlever],
  );

  const switchLogsAdmin = useCallback(
    toggle => {
      setToggle(toggle);
    },
    [userIsKlever],
  );

  return (
    <Container>
      <Nav />
      <BoxStyled>
        <BoxSection>
          <Heading
            textAlign={'center'}
            color={theme.common.lightText}
            mb="2rem"
          >
            {t('title')}
          </Heading>

          <Filter
            fields={filterFields}
            onSubmit={handleFilters}
            translation="logs"
            switchLogsAdmin={switchLogsAdmin}
          />
        </BoxSection>
        <PaginatedTable
          endpoint="audit-logs"
          headers={headers}
          ns="logsTable"
          filters={filters}
          requestLoad={requestLoad}
        />
      </BoxStyled>
    </Container>
  );
};

export default Logs;
