import { Accordion, AccordionItem } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { IFilterProps } from '../../interfaces/Filter';
import { Content } from './Content';
import { Title } from './Title';

export default function Filter({
  translation,
  onSubmit,
  fields,
  switchLogsAdmin,
}: IFilterProps) {
  const { t: lang } = useTranslation(translation);
  const { t } = useTranslation('filter');
  const theme = useTheme();

  return (
    <Accordion allowToggle bg={theme.form.background}>
      <AccordionItem>
        <Title value={lang('filters.title')} />
        <Content
          fields={fields}
          submitTitle={t('buttons.submit')}
          onSubmit={onSubmit}
          switchLogsAdmin={switchLogsAdmin}
        />
      </AccordionItem>
    </Accordion>
  );
}
