import { Funnel, SortAscending, SortDescending } from 'phosphor-react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortType } from '../../../interfaces/Common';
import { ISortProps, PaginationStateProps } from '../../../interfaces/Table';
import { sortByNumber } from '../../../utils/sortArray';
import { ButtonSort } from './Button';

interface ISortButton {
  translation: string;
  header: string;
  data: any;
  setPagination: (value: React.SetStateAction<PaginationStateProps>) => void;
}

const ICONS_BY_TYPE = {
  DEFAULT: Funnel,
  DESC: SortDescending,
  ASC: SortAscending,
};

export function SortButton({
  translation,
  header,
  data,
  setPagination,
}: ISortButton) {
  const { t } = useTranslation('tables');
  const nameHeader = t(`${translation}.${header}`);

  const [headersSort, setHeadersSort] = useState<ISortProps>({} as ISortProps);

  const isSortable = useMemo(() => {
    const headersAllowedToSort = ['createdAt', 'amount'];
    return (
      headersAllowedToSort.includes(header) &&
      window?.location?.pathname.includes('statement')
    );
  }, [header]);

  const alreadySorted = useMemo(() => {
    const headerIsAlreadyInSort = headersSort.value === header;
    return headerIsAlreadyInSort;
  }, [header, headersSort]);

  const sortIcon = useCallback(() => {
    const type = headersSort.type ?? 'DEFAULT';

    return ICONS_BY_TYPE[type];
  }, [headersSort, alreadySorted]);

  const setOrderAndPagination = useCallback(
    (header: string, backToNormal = false) => {
      const sortedArray = sortByNumber(
        data,
        headersSort.type ?? 'ASC',
        header,
        backToNormal,
      );

      setPagination?.(prev => ({
        ...prev,
        data: sortedArray,
      }));
    },
    [headersSort, data],
  );

  const setTableNewOrder = useCallback(
    (newType: SortType) => {
      headersSort.type = newType;

      setHeadersSort(prev => ({
        ...prev,
        type: newType,
      }));

      setOrderAndPagination(header);
    },
    [headersSort],
  );

  const setTableOrder = useCallback(() => {
    if (headersSort.value !== header || !headersSort.value) {
      setHeadersSort({
        type: 'ASC',
        value: header,
      });

      setOrderAndPagination(header);

      return;
    }

    const type = headersSort.type;
    const newType = type === 'ASC' ? 'DESC' : null;

    if (['DESC', null].includes(type)) {
      setHeadersSort({
        type: null,
        value: '',
      });

      setOrderAndPagination('', true);

      return;
    }

    setTableNewOrder(newType);
  }, [headersSort]);

  return (
    <ButtonSort
      icon={sortIcon()}
      sortable={isSortable}
      header={nameHeader}
      setOrder={() => setTableOrder()}
    />
  );
}
