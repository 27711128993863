import { translate } from '../../i18n/translate';
import { ticketStatus } from './Status';

const filter = (text: string) => translate('manageTicket', text);

export const manageTicketFilterFields = [
    {
        key: 'chain',
        title: filter('filters.chain'),
        type: 'text',
    },
    {
        key: 'transactionId',
        title: filter('filters.transactionId'),
        type: 'text',
    },
    {
        key: 'description',
        title: filter('filters.description'),
        type: 'text',
    },
    {
        key: 'status',
        title: filter('filters.status'),
        type: 'select',
        selectValues: ticketStatus.map(e => ({
            key: e,
            title: filter(`statusOptions.${e}`),
        })),
    },

    {
        key: 'from',
        title: filter('filters.from'),
        type: 'date',
    },
    {
        key: 'to',
        title: filter('filters.to'),
        type: 'date',
    },
];

export const manageTicketAdminFilterFields = [
    {
        key: 'companyName',
        title: filter('filters.companyName'),
        type: 'text',
    },
    ...manageTicketFilterFields,
];

export const ticketFields = {
    edit: [
        {
            key: 'status',
            type: 'select',
            disabled: false,
            required: true,
            selectOptions: [
                ...ticketStatus.map(e => ({
                    name: filter(`statusOptions.${e}`),
                    value: e,
                })),
            ],
        },
    ],
};
