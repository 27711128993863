import { lazy, Suspense } from 'react';
import { Spinner } from '../components/Spinner';

export const ManageTicket: React.FC = () => {
  const LazyManageTicket = lazy(() => import('../pages/ManageTicket'));

  return (
    <Suspense fallback={<Spinner />}>
      <LazyManageTicket />
    </Suspense>
  );
};

export const Logs: React.FC = () => {
  const LazyLogs = lazy(() => import('../pages/Logs'));

  return (
    <Suspense fallback={<Spinner />}>
      <LazyLogs />
    </Suspense>
  );
};

export const Statement: React.FC = () => {
  const LazyStatement = lazy(() => import('../pages/Statement'));

  return (
    <Suspense fallback={<Spinner />}>
      <LazyStatement />
    </Suspense>
  );
};

export const Companies: React.FC = () => {
  const LazyCompanies = lazy(() => import('../pages/Admin/Companies'));

  return (
    <Suspense fallback={<Spinner />}>
      <LazyCompanies />
    </Suspense>
  );
};

export const Account: React.FC = () => {
  const LazyAccount = lazy(() => import('../pages/Company/Account'));

  return (
    <Suspense fallback={<Spinner />}>
      <LazyAccount />
    </Suspense>
  );
};

export const Administrators: React.FC = () => {
  const LazyAdministrators = lazy(
    () => import('../pages/Admin/Administrators'),
  );

  return (
    <Suspense fallback={<Spinner />}>
      <LazyAdministrators />
    </Suspense>
  );
};

export const Billing: React.FC = () => {
  const LazyBilling = lazy(() => import('../pages/Billing'));

  return (
    <Suspense fallback={<Spinner />}>
      <LazyBilling />
    </Suspense>
  );
};

export const Dashboard: React.FC = () => {
  const LazyDashboard = lazy(() => import('../pages/Dashboard'));

  return (
    <Suspense fallback={<Spinner />}>
      <LazyDashboard />
    </Suspense>
  );
};

export const CreateNetworkToken: React.FC = () => {
  const LazyCreateNetworkToken = lazy(
    () => import('../pages/CreateNetworkToken'),
  );

  return (
    <Suspense fallback={<Spinner />}>
      <LazyCreateNetworkToken />
    </Suspense>
  );
};

export const Balances: React.FC = () => {
  const LazyBalances = lazy(() => import('../pages/Balances'));

  return (
    <Suspense fallback={<Spinner />}>
      <LazyBalances />
    </Suspense>
  );
};

export const Networks: React.FC = () => {
  const LazyNetworks = lazy(() => import('../pages/Networks'));

  return (
    <Suspense fallback={<Spinner />}>
      <LazyNetworks />
    </Suspense>
  );
};

export const TokensNetworks: React.FC = () => {
  const LazyTokensNetworks = lazy(() => import('../pages/TokensNetworks'));

  return (
    <Suspense fallback={<Spinner />}>
      <LazyTokensNetworks />
    </Suspense>
  );
};

export const Tokens: React.FC = () => {
  const LazyTokens = lazy(() => import('../pages/Tokens'));

  return (
    <Suspense fallback={<Spinner />}>
      <LazyTokens />
    </Suspense>
  );
};
