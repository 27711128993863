import styled, { css, DefaultTheme } from 'styled-components';
import { Link as ReactRouterLink } from 'react-router-dom';
import { darken } from 'polished';
import { Text as ChakraText } from '@chakra-ui/react';

interface ISidebarLinkProps {
  isActive: boolean;
  hasSubmenu?: boolean;
}

interface ITextProps {
  isMenuTitle: boolean;
  isSubMenuTitle?: boolean;
}

const NavbarModifiers = {
  active: (theme: DefaultTheme) => css`
    background-color: ${theme.navbar.backgroundLinks} !important;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 3px;
      height: 100%;
      background-color: ${theme.colors.kleverPink.light};
      border-radius: 0.3rem 0 0 0.3rem;
    }
  `,
  hasSubmenu: () => css`
    background-color: transparent;
    height: 0;
  `,
};

const TextModifiers = {
  isMenuTitle: () => css`
    margin-block: 1rem;

    cursor: pointer;
    padding-inline: 1rem;
  `,
  isSubMenuTitle: () => css`
    padding-left: 1.5rem;
  `,
};

export const SidebarLink = styled(ReactRouterLink)<ISidebarLinkProps>`
  ${({ theme, isActive, hasSubmenu }) => css`
    color: ${theme.navbar.links.primaryColor};
    transition: 0.2s ease;
    padding: 1rem;
    height: 50px;
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 0.3rem;
    transition: 0.1s ease;
    margin: 0 !important;
    gap: 1rem;

    &#logout {
      color: #e53e3e !important;
    }

    ${isActive && NavbarModifiers['active'](theme)}
    ${hasSubmenu && NavbarModifiers['hasSubmenu']()}

    &:hover {
      color: ${darken(0.2, theme.navbar.links.primaryColor)} !important;
      background-color: ${theme.navbar.backgroundLinks} !important;
    }
  `}
`;

export const Text = styled(ChakraText)<ITextProps>`
  ${({ isMenuTitle, isSubMenuTitle = false }) => css`
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    gap: 1rem;
    ${isMenuTitle ? TextModifiers['isMenuTitle']() : ''}
    ${isSubMenuTitle ? TextModifiers['isSubMenuTitle']() : ''}

    svg {
      transition: all 0.3s;
    }
  `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  position: relative;
  border-radius: 3px;

  p {
    width: 100%;
  }

  div > p {
    padding-left: 1rem;
  }
`;

export const Separator = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  width: 1px;
  background-color: red;
`;
