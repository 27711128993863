import i18next from 'i18next';
import { translate } from '../../../i18n/translate';

const filter = (text: string) => translate('adminAdministrators', text);

export const adminFields = {
  view: [
    { key: 'firstName', type: 'text', disabled: true },
    { key: 'lastName', type: 'text', disabled: true },
    { key: 'email', type: 'email', disabled: true },
    { key: 'invitedFrom', type: 'text', disabled: true },
    { key: 'companyId', type: 'text', disabled: true },
    { key: 'isKleverAdmin', type: 'text', disabled: true },
    { key: 'isActive', type: 'text', disabled: true },
    {
      key: 'createdAt',
      type: 'text',
      disabled: true,
      isDatetime: true,
    },
    {
      key: 'updatedAt',
      type: 'text',
      disabled: true,
      isDatetime: true,
    },
  ],
  create: [
    { key: 'firstName', type: 'text', disabled: false, required: true },
    { key: 'lastName', type: 'text', disabled: false, required: true },
    { key: 'email', type: 'email', disabled: false, required: true },
    {
      key: 'isKleverAdmin',
      type: 'checkbox',
      disabled: false,
      default: false,
      enableOnCheck: 'companyId',
      required: true,
    },
  ],
  edit: [
    { key: 'firstName', type: 'text', disabled: false, required: true },
    { key: 'lastName', type: 'text', disabled: false, required: true },
    { key: 'email', type: 'email', disabled: false, required: true },

    {
      key: 'isKleverAdmin',
      type: 'checkbox',
      disabled: false,
      default: false,
      enableOnCheck: 'companyId',
      required: true,

      selectOptions: [
        {
          title: i18next.t('common:yes'),
          value: true,
        },
        {
          title: i18next.t('common:no'),
          value: false,
        },
      ],
    },
  ],
};

export const adminFilterFields = [
  {
    key: 'firstName',
    title: filter('filters.firstName'),
    type: 'text',
  },
  {
    key: 'lastName',
    title: filter('filters.lastName'),
    type: 'text',
  },
  {
    key: 'email',
    title: filter('filters.email'),
    type: 'text',
  },

  {
    key: 'company',
    title: filter('filters.company'),
    type: 'text',
  },
  {
    key: 'isActive',
    title: filter('filters.isActive'),
    type: 'checkbox',
  },
];
