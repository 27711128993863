import { Box, Text } from '@chakra-ui/react';
import { DownloadSimple } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import Button from '../Button';

import { Fieldset, Legend } from './styles';

type MoreInfoTicketProps = {
  description: string;
  kleverResponse: string;
  downloadUrl?: string;
};

export default function MoreInfoTicket({
  description,
  kleverResponse,
  downloadUrl,
}: MoreInfoTicketProps) {
  const { t } = useTranslation('moreInfoTicket');

  return (
    <Box w={'100%'} p={'6'}>
      <Fieldset>
        <Legend>{t('description')}</Legend>
        <Text whiteSpace={'pre-wrap'} p={'4px 20px'}>
          {!!description ? description : t('noDescription')}
        </Text>
      </Fieldset>
      <Fieldset>
        <Legend>{t('response')}</Legend>
        <Text whiteSpace={'pre-wrap'} p={'4px 20px'}>
          {!!kleverResponse ? kleverResponse : t('noResponse')}
        </Text>
      </Fieldset>
      <div>
        <a href={downloadUrl} download={'ticket-file'}>
          <Button leftIcon={<DownloadSimple size={18} />}>
            {t('downloadText')}
          </Button>
        </a>
      </div>
    </Box>
  );
}
