import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Button as CButton,
  Flex,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import { PencilSimple, Trash } from 'phosphor-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import BoxSection from '../../components/BoxSection';
import BoxStyled from '../../components/BoxStyled';
import Button from '../../components/Button';
import Container from '../../components/Container';
import Nav from '../../components/Navbar';
import { CHAINS } from '../../constants/chains';
import api, { Service } from '../../services/api';

type Network = {
  id: number;
  name: string;
  chain: number;
  confirmations: number;
  createdAt: number;
  updatedAt: number;
};

type NetworkData = {
  data: Array<Network>;
  page: number;
  totalPages: number;
  limit: number;
};

type Page = {
  current: number;
  total: number;
  limit: number;
};

const PAGE_INIT_STATE: Page = {
  current: 1,
  total: 1,
  limit: 10,
};

const Networks: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [networks, setNetworks] = useState([] as Array<Network>);
  const [page, setPage] = useState(PAGE_INIT_STATE);
  const [enteredNetworkName, setEnteredNetworkName] = useState('');
  const [selectedNetwork, setSelectedNetwork] = useState('');
  const [selectedNetworkConfirmations, setSelectedNetworkConfirmations] =
    useState(1);

  const theme = useTheme();

  const toast = useToast();

  const { t } = useTranslation('networks');

  const getChainById = (chainId: string | number) =>
    CHAINS.find(c => +c.id === +chainId);

  const getNetworks = useCallback(async () => {
    try {
      setIsLoading(true);

      const { data, ...rest }: NetworkData = await api.get({
        route: 'networks',
        service: Service.KRYPTO_BANKING,
        apiVersion: 'v1',
        query: {
          page: page.current,
          limit: page.limit,
        },
      });

      if (!data?.length) return;

      setPage({
        current: rest.page,
        total: rest.totalPages,
        limit: rest.limit,
      });
      setNetworks(data);
    } finally {
      setIsLoading(false);
    }
  }, [page.current, page.limit]);

  const createNetwork = useCallback(async () => {
    try {
      setIsLoading(true);

      const payload = {
        name: enteredNetworkName,
        chain: +selectedNetwork,
        confirmations: selectedNetworkConfirmations,
      };

      const { message }: Record<'message', 'string'> = await api.post({
        route: 'networks',
        service: Service.KRYPTO_BANKING,
        apiVersion: 'v1',
        body: payload,
      });

      if (message?.length) throw 'Error creating network';

      toast({
        status: 'success',
        title: 'Network created',
        description: 'Network created successfully',
      });

      await getNetworks();
      handleToggleModal();
    } catch (e) {
      toast({
        status: 'error',
        title: 'Error',
        description: e as string,
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    enteredNetworkName,
    selectedNetwork,
    selectedNetworkConfirmations,
    getNetworks,
  ]);

  const deleteNetwork = useCallback(async (id: number) => {
    try {
      setIsLoading(true);

      const { message }: Record<'message', 'string'> = await api.delete({
        route: `networks/${id}`,
        service: Service.KRYPTO_BANKING,
        apiVersion: 'v1',
      });

      if (message?.length) throw 'Error deleting network';

      toast({
        status: 'success',
        title: 'Network deleted',
        description: 'Network deleted successfully',
      });

      await getNetworks();
    } catch (e) {
      toast({
        status: 'error',
        title: 'Error',
        description: e as string,
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleToggleModal = () => {
    setIsModalOpen(prev => !prev);
    setEnteredNetworkName('');
    setSelectedNetwork('');
    setSelectedNetworkConfirmations(1);
  };

  const handleChangeEnteredNetworkName = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => setEnteredNetworkName(e.target.value);

  const handleChangeSelectedNetwork = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => setSelectedNetwork(e.target.value);

  const handleChangeSelectedNetworkConfirmations = (value: string) =>
    setSelectedNetworkConfirmations(+value);

  const handleDeleteNetwork = async (id: number) => await deleteNetwork(id);

  const handleChangeToPreviousPage = () => {
    if (page.current <= 1) return;

    setPage(prev => ({
      ...prev,
      current: prev.current - 1,
    }));
  };

  const handleChangeToNextPage = () => {
    if (page.current >= page.total) return;

    setPage(prev => ({
      ...prev,
      current: prev.current + 1,
    }));
  };

  const handleChangePageLimit = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { target } = e;

    setPage(prev => ({
      ...prev,
      limit: +target.value,
    }));
  };

  useEffect(() => void getNetworks(), [getNetworks]);

  return (
    <>
      <Modal
        isCentered
        closeOnOverlayClick={false}
        isOpen={isModalOpen}
        onClose={handleToggleModal}
      >
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>{t('creationNetworkModal.header.title')}</ModalHeader>

          <ModalCloseButton color="red.500" />

          <ModalBody>
            <Flex direction="column">
              <Flex direction="column" gap="0.5rem">
                <Text>{t('creationNetworkModal.body.name')}</Text>

                <Input
                  placeholder={t('creationNetworkModal.body.namePlaceholder')}
                  value={enteredNetworkName}
                  onChange={handleChangeEnteredNetworkName}
                />
              </Flex>

              <Flex direction="column" gap="0.5rem" mt="1.5rem">
                <Text>{t('creationNetworkModal.body.network')}</Text>

                <Select
                  value={selectedNetwork}
                  onChange={handleChangeSelectedNetwork}
                >
                  {CHAINS.map((chain, i) => (
                    <option key={`${chain.id}-${i}`} value={+chain.id}>
                      {chain.name}
                    </option>
                  ))}
                </Select>
              </Flex>

              <Flex direction="column" gap="0.5rem" mt="1.5rem">
                <Text>{t('creationNetworkModal.body.confirmations')}</Text>

                <NumberInput
                  min={1}
                  defaultValue={selectedNetworkConfirmations}
                  value={selectedNetworkConfirmations}
                  onChange={handleChangeSelectedNetworkConfirmations}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex w="100%" justify="space-between" mt="1.25rem">
              <CButton
                variant="ghost"
                disabled={isLoading}
                onClick={handleToggleModal}
              >
                {t('creationNetworkModal.body.cancelBtn')}
              </CButton>

              <Button
                disabled={isLoading || !enteredNetworkName?.length}
                leftIcon={isLoading && <Spinner w={18} h={18} />}
                onClick={createNetwork}
              >
                {t('creationNetworkModal.body.confirmBtn')}
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Container>
        <Nav />

        <BoxStyled>
          <BoxSection>
            <Flex justify="center" align="center" position="relative">
              <Flex position="absolute">
                <Heading color={theme.common.lightText}>{t('title')}</Heading>
              </Flex>

              <Flex justify="flex-end" w="100%">
                <Button onClick={handleToggleModal}>
                  <PencilSimple size={20} />
                  <Text ml="0.5rem">
                    {t('creationNetworkModal.header.title')}
                  </Text>
                </Button>
              </Flex>
            </Flex>
          </BoxSection>

          <BoxSection>
            <Table variant="simple">
              <Thead>
                {!isLoading && (
                  <Tr>
                    <Th>{t('table.header.name')}</Th>

                    <Th>{t('table.header.chain')}</Th>

                    <Th>{t('table.header.confirmations')}</Th>

                    <Th />
                  </Tr>
                )}
              </Thead>

              <Tbody>
                {isLoading && (
                  <Tr>
                    <Td colSpan={4}>
                      <Flex justify="center">
                        <Spinner />
                      </Flex>
                    </Td>
                  </Tr>
                )}

                {!isLoading && !networks?.length && (
                  <Tr>
                    <Td colSpan={4}>
                      <Flex justify="center">
                        <Text>{t('table.body.noItems')}</Text>
                      </Flex>
                    </Td>
                  </Tr>
                )}

                {!isLoading &&
                  networks?.length > 0 &&
                  networks.map(network => (
                    <Tr key={network.id}>
                      <Td>{network.name}</Td>

                      <Td>{getChainById(network.chain)?.name ?? 'N/A'}</Td>

                      <Td>
                        <Text>
                          <b>{network.confirmations}</b>{' '}
                          {t('table.body.blocks')}
                        </Text>
                      </Td>

                      <Td>
                        <Flex justify="flex-end">
                          <CButton
                            variant="ghost"
                            color="red"
                            leftIcon={<Trash size={20} />}
                            onClick={() => handleDeleteNetwork(network.id)}
                          >
                            {t('table.body.deleteBtn')}
                          </CButton>
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>

            {!isLoading && (
              <Flex justify="center" align="center" gap={10} mt={4}>
                <IconButton
                  aria-label="back-button"
                  disabled={page.current <= 1}
                  onClick={handleChangeToPreviousPage}
                  icon={<ChevronLeftIcon w={6} h={6} />}
                />

                <Text>
                  <b>{page.current}</b>/{page.total}
                </Text>

                <IconButton
                  aria-label="next-button"
                  disabled={page.current >= page.total}
                  onClick={handleChangeToNextPage}
                  icon={<ChevronRightIcon w={6} h={6} />}
                />

                <Flex align="center" ml={2}>
                  <Select value={page.limit} onChange={handleChangePageLimit}>
                    {[10, 20, 50, 100].map(v => (
                      <option key={v} value={v}>
                        Show {v}
                      </option>
                    ))}
                  </Select>
                </Flex>
              </Flex>
            )}
          </BoxSection>
        </BoxStyled>
      </Container>
    </>
  );
};

export default Networks;
