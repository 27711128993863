import styled from 'styled-components';

import ReactSelect from 'react-select';
import { Select as ChakraSelect } from '@chakra-ui/react';

export const ReactSelectCustom = styled(ReactSelect)`
  color: ${props => props.theme.input.color};

  &:focus {
    border: 1px solid ${props => props.theme.select.focus.color}!important;
    box-shadow: 0 0 5px ${props => props.theme.select.focus.color} !important;
  }
`;

export const Select = styled(ChakraSelect)`
  background-color: white !important;
  color: ${props => props.theme.input.color};
  margin-bottom: 15px;
  height: 32px !important;

  &:focus {
    border: 1px solid ${props => props.theme.select.focus.color}!important;
    box-shadow: 0 0 5px ${props => props.theme.select.focus.color} !important;
  }

  &.language {
    height: 100% !important;
    background-color: transparent !important;

    & option {
      background-color: ${props => props.theme.navbar.background} !important;
    }
  }
`;
