import { ResponsivePie } from '@nivo/pie';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import api, { Service } from '../../../services/api';

import {
  SelectGroup,
  SectionTitle,
  TitleModifiedCenter,
  ContainerSection,
  ChartContainerModifiedHeight,
} from '../styles';

interface IData {
  id: string;
  label: string;
  value: number;
}

const startData = [{id:"error", label:"error", value: 100}]

const Distribution: React.FC<any> = () => {
  const theme = useTheme();
  const { t } = useTranslation('charts');
  const [data, setData] = useState<IData[]>(startData)

  const colors = [
    'rgba(140,212,200, .5)',
    'rgba(249,182,93, .5)',
    'rgba(246,126,109, .5)',
    'rgba(189,126,190, .5)',
    'rgba(147,123,137, .5)',
    'rgba(246,219,198, .5)',
    'rgba(255,190,190, .5)',
    'rgba(229,149,114, .5)',
    'rgba(255,194,255, .5)',
    'rgba(165,163,168, .5)',
    'rgba(194,194,255, .5)',
    'rgba(158,211,169, .5)',
  ];

  useEffect(() => {
    (async function () {
      const query = {
        n: 5
      }
      const response = await api.get({
        route: 'report/top-token-info',
        service: Service.KRYPTO_BANKING,
        apiVersion: 'v1',
        query
      });
      if ("error" in response ) {
        setData(startData)
      } else {
        setData(response)
      }
    })();
  }, []);


  return (
    <ContainerSection gridName="distribution">
      <TitleModifiedCenter>{t('titleChart.chart3')}</TitleModifiedCenter>
      <ChartContainerModifiedHeight>
        <ResponsivePie
          data={data as any}
          margin={{ top: 15, right: 5, bottom: 15, left: -100 }}
          innerRadius={0.7}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 0.2]],
          }}
          colors={colors}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="rgb(74,85,104)"
          arcLinkLabelsThickness={2}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          legends={[
            {
              anchor: 'right',
              direction: 'column',
              justify: false,
              translateX: -70,
              translateY: 4,
              itemsSpacing: 5,
              itemWidth: 30,
              itemHeight: 18,
              itemTextColor: '#999',
              itemDirection: 'left-to-right',
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: 'circle',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemTextColor: '#000',
                  },
                },
              ],
            },
          ]}
        />
      </ChartContainerModifiedHeight>
    </ContainerSection>
  );
};

export default Distribution;
