import { languages } from './languageConfig';

let lng = 'en';
let fallbackLng = 'en';

const isValidLng = (lng: string | null) => {
  return languages.some(language => language.value === lng) ?? false;
};

if (navigator) {
  lng = navigator.language;
}

if (
  localStorage.getItem('lng') !== null &&
  isValidLng(localStorage.getItem('lng'))
) {
  lng = localStorage.getItem('lng') as string;
}

export { lng, fallbackLng };
